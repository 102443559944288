import type User from 'types/User';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';

import {
  logout,
  setAppLoading,
  setAuthLoading,
  setHubspotError,
  setHubspotSuccess,
  updateSessionSecret,
  updateUser,
} from './actions';

export interface AuthState {
  user: User | null;
  sessionSecret: string;
  hubspotError: string | null;
  hubspotSuccess: boolean;
  appLoading: boolean;
  authLoading: boolean;
}

const initialState: AuthState = {
  user: null,
  sessionSecret: '',
  hubspotError: null,
  hubspotSuccess: false,
  appLoading: false,
  authLoading: false,
};

const authReducer = createReducer(initialState, {
  [updateSessionSecret.type]: (state: AuthState, action: PayloadAction<string>) => ({
    ...state,
    sessionSecret: action.payload,
  }),
  [updateUser.type]: (state: AuthState, action: PayloadAction<User>) => ({
    ...state,
    user: action.payload,
  }),
  [logout.type]: (state: AuthState) => ({
    ...state,
    user: null,
    sessionSecret: '',
  }),
  [setAppLoading.type]: (state: AuthState, action: PayloadAction<boolean>) => ({
    ...state,
    appLoading: action.payload,
  }),
  [setAuthLoading.type]: (state: AuthState, action: PayloadAction<boolean>) => ({
    ...state,
    authLoading: action.payload,
  }),
  [setHubspotError.type]: (state: AuthState, action: PayloadAction<string>) => ({
    ...state,
    hubspotError: action.payload,
  }),
  [setHubspotSuccess.type]: (state: AuthState, action: PayloadAction<boolean>) => ({
    ...state,
    hubspotSuccess: action.payload,
    hubspotError: null,
  }),
});

export default authReducer;
