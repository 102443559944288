import type { AxiosError, AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { LoginParams, LoginResponse, SignupParams } from 'api';
import type User from 'types/User';
import type { HubspotSignupParams } from 'types/Hubspot';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'api';

import * as authActions from './actions';
import { accountActions } from 'store/account';
import { kycActions } from 'store/kyc';

// Utils
import { showError } from 'utils/helpers/showToast';

function* signup(action: PayloadAction<SignupParams>) {
  try {
    yield put(authActions.setAuthLoading(true));
    yield call(api.signup, action.payload);
    const loginRes: AxiosResponse<LoginResponse> = yield call(api.login, {
      userId: action.payload.email,
      password: action.payload.password,
    });
    yield put(authActions.updateSessionSecret(loginRes.data.sessionSecret));
    yield put(authActions.updateUser(loginRes.data.session?.user));
    yield put(accountActions.getAccountProfiles(loginRes.data.session?.user?.id))
    yield put(authActions.setAuthLoading(false));
    yield put(kycActions.setRedirectToKyc(true));
  }
  catch (error) {
    yield put(authActions.setAuthLoading(false));
    showError(error as AxiosError);
  }
}

function* login(action: PayloadAction<LoginParams>) {
  try {
    yield put(authActions.setAuthLoading(true));
    const res: AxiosResponse<LoginResponse> = yield call(api.login, action.payload);
    yield put(authActions.updateSessionSecret(res.data.sessionSecret));
    yield put(authActions.updateUser(res.data.session?.user));
    yield put(accountActions.getAccountProfiles(res.data.session?.user?.id))
    yield put(authActions.setAuthLoading(false));
  }
  catch (error) {
    yield put(authActions.setAuthLoading(false));
    showError(error as AxiosError);
  }
}

function* getCurrentUser() {
  try {
    yield put(authActions.setAppLoading(true));
    const res: AxiosResponse<User> = yield call(api.getCurrentUser);
    yield put(authActions.updateUser(res.data));
    yield put(accountActions.getAccountProfiles(res.data.id))
    yield put(authActions.setAppLoading(false));
  }
  catch(error) {
    yield put(authActions.setAppLoading(false));
  }
}

function* hubspotSignup(action: PayloadAction<HubspotSignupParams>) {
  try {
    yield put(authActions.setAuthLoading(true));
    yield call(api.hubspotSignup, action.payload);
    yield put(authActions.setHubspotSuccess(true));
    yield put(authActions.setAuthLoading(false));
  }
  catch(error) {
    yield put(authActions.setHubspotError('A user with this email is already registered'));
    yield put(authActions.setAuthLoading(false));
  }
}

function* authSaga() {
  yield all([
    takeLatest(authActions.signup.type, signup),
    takeLatest(authActions.login.type, login),
    takeLatest(authActions.getCurrentUser.type, getCurrentUser),
    takeLatest(authActions.hubspotSignup.type, hubspotSignup),
  ]);
}

export default authSaga;
