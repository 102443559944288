import React from 'react';

import IndicatorImage from 'assets/svg/investIndicator.svg';
import formatToCurrencyString from 'utils/helpers/formatToCurrencyString';

interface CardProps {
    sold?: boolean;
    invested?: boolean;
    name?: string;
    featuredDescription?: string;
    raise?: number;
    interval?: number;
    featuredImage?: string;
}

const MarketCard: React.FC<CardProps> = ({
    sold,
    invested,
    name,
    featuredDescription,
    raise,
    interval,
    featuredImage,
}) => {
    return (
        <div className="w-full overflow-hidden shadow-xl font-frankRuhlLibre">
            <div className="relative flex justify-center">
                {invested && (
                    <div className="w-[130px] xl:w-[130px] absolute top-0 overflow-hidden inline-block">
                        <img src={IndicatorImage} className="w-60" />
                    </div>
                )}
                <div className="flex justify-center items-center bg-gray w-full h-[300px]">
                    {featuredImage && (
                        <img
                            className="w-full h-full object-cover object-center"
                            src={featuredImage}
                            alt="Sunset in the mountains"
                        />
                    )}
                    {!featuredImage && (
                        <div className="text-2xl font-segoe font-semibold text-light-gray">No Image</div>
                    )}
                </div>
            </div>
            <div className="p-6 xl:p-10 flex bg-black">
                <div className="w-8/12">
                    {name && (
                        <div className="font-frankRuhlLibre text-white-desc text-base font-medium xl:text-lg mb-2">
                            {name}
                        </div>
                    )}
                    {featuredDescription && (
                        <p className="text-white-desc font-medium text-xs font-segoe">{featuredDescription}</p>
                    )}
                </div>
                <div className="w-4/12">
                    <h1 className="text-primary text-lg text-right font-medium font-segoe">
                        $ {formatToCurrencyString(raise || 0)}
                    </h1>
                    <h1 className="text-gray text-sm text-right mb-2">Total Raise</h1>
                    <h1 className="text-secondary text-lg text-right font-segoe">
                        $ {formatToCurrencyString(interval || 0)}
                    </h1>
                    <h1 className="text-gray text-sm text-right mb-2">Min Interval</h1>
                    <h1 className="text-secondary text-lg text-right font-segoe">20%</h1>
                    <h1 className="text-gray text-sm text-right">Expected IRR</h1>
                </div>
            </div>
        </div>
    );
};

export default MarketCard;
