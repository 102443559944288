import type { FC, ReactNode } from 'react';
import React from 'react';
import { Tab } from '@headlessui/react';

interface TabPanelsProps {
    children: ReactNode;
}

const TabPanels: FC<TabPanelsProps> = ({ children, ...otherProps }) => (
    <Tab.Panels {...otherProps}>{children}</Tab.Panels>
);

export default TabPanels;
