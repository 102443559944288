import type { Deal, GetDealsRequest } from 'types/Market';
import type { SubscriptionsTrackerResponse, SudraniaFundDetails } from 'types/Sudrania';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'market';

export const getDeals = createAction<GetDealsRequest>(`${PREFIX}/getDeals`);
export const setDeals = createAction<Deal[]>(`${PREFIX}/setDeals`);
export const setDealsLoading = createAction<boolean>(`${PREFIX}/setDealsLoading`);
export const setDealsTotal = createAction<number>(`${PREFIX}/setDealsTotal`);
export const setDealsPage = createAction<number>(`${PREFIX}/setDealsPage`);
export const setDealsPerPage = createAction<number>(`${PREFIX}/setDealsPerPage`);
export const getDeal = createAction<string>(`${PREFIX}/getDeal`);
export const setDeal = createAction<Deal>(`${PREFIX}/setDeal`);
export const setDealLoading = createAction<boolean>(`${PREFIX}/setDealLoading`);
export const getFundDetails = createAction<string>(`${PREFIX}/getFundDetails`);
export const setFundDetails = createAction<SudraniaFundDetails>(`${PREFIX}/setFundDetails`);
export const setInvestNow = createAction<boolean>(`${PREFIX}/setInvestNow`);
export const setDealSubscription = createAction<SubscriptionsTrackerResponse>(`${PREFIX}/setDealSubscription`);
