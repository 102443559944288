import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { persistor, store } from './store';

import './styles/index.scss';
import { PersistGate } from 'redux-persist/lib/integration/react';

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
                <HistoryRouter history={createBrowserHistory({ window })}>
                    <App />
                </HistoryRouter>
            </PersistGate>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
