import { useEffect, useRef } from 'react';
import Chartist from 'chartist';
import cx from 'classnames';

import { ReactComponent as HexagonFillSrc } from 'assets/svg/hexagon-fill.svg';

interface PieItem {
    name: string;
    value: number | string;
}

interface PieChartProps {
    title?: string;
    height?: string;
    width?: string;
    containerClassName?: string;
    showLegend?: boolean;
    legendPosition?: 'right' | 'bottom';
    series: PieItem[];
}

const PieChart = ({
    title,
    height,
    width,
    containerClassName,
    showLegend,
    series,
    legendPosition = 'bottom',
}: PieChartProps) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        new Chartist.Pie(
            chartRef.current,
            {
                series: series.map((item: PieItem, index: number) => ({
                    value: +item.value,
                    name: item.name,
                    className: `chart-color-${index + 1}`,
                })),
            },
            {
                height,
                width,
                labelInterpolationFnc: () => '',
            }
        );
    }, []);

    return (
        <div className={cx('flex', 'items-center', 'flex-col', containerClassName)}>
            {title && (
                <div
                    className={cx(
                        'my-4',
                        'md:my-6',
                        'text-center',
                        'text-[18px]',
                        'text-black',
                        'font-frankRuhlLibre',
                        'font-bold'
                    )}
                >
                    {title}
                </div>
            )}
            <div
                className={cx({
                    'md:flex justify-center items-center': legendPosition === 'right',
                })}
            >
                <div ref={chartRef} />
                {showLegend && (
                    <div className="grid-cols-2 my-12 mx:auto md:grid">
                        {series.map((seria: PieItem, index: number) => (
                            <div
                                className={`chart-color-${
                                    index + 1
                                } flex items-center ml-3 my-1 justify-center md:justify-start`}
                                key={index}
                            >
                                <HexagonFillSrc width="16px" height="16px" />
                                <span className="ml-3 font-bold text-[12px] text-gray max-w-32 font-segoe uppercase">
                                    {seria.name}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

PieChart.defaultProps = {
    title: '',
    containerClassName: '',
    showLegend: true,
    height: '250px',
    width: '250px',
};

export default PieChart;
