import type { AxiosResponse } from 'axios';
import type { SubscriptionsTrackerResponse, SudraniaResponse } from 'types/Sudrania';
import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

// Api
import { fundTracker } from 'api';

// Hooks
import { useAppSelector } from 'hooks';

// Store
import { selectAccountMetadata } from 'store/account/selectors';
import { selectMarketDeal, selectMarketInvestNow } from 'store/market/selectors';
import { selectAccessToken } from 'store/plaid/selectors';

// Components
import Completed from './Completed';
import NotSubmitted from './NotSubmitted';
import Submitted from './Submitted';
import Invested from './Invested';

const DealInvest = () => {
    const [investAmount, setInvestAmount] = useState<number>(0);
    const [investmentFinished, setInvestmentFinished] = useState(false);

    const deal = useAppSelector(selectMarketDeal);
    const accessToken = useAppSelector(selectAccessToken);
    const metadata = useAppSelector(selectAccountMetadata);
    const investNow = useAppSelector(selectMarketInvestNow);

    const loadSubscriptions = useCallback(async (): Promise<SudraniaResponse<
        SubscriptionsTrackerResponse[]
    > | null> => {
        if (deal?.sudrania_id && metadata.sudrania_id) {
            const subscriptions: AxiosResponse<SudraniaResponse<SubscriptionsTrackerResponse[]>> = await fundTracker(
                metadata.sudrania_id,
                deal?.sudrania_id
            );
            return subscriptions.data;
        }
        return null;
    }, [deal?.sudrania_id, metadata.sudrania_id]);

    const checkSubscription = useCallback(async () => {
        const subscriptions = await loadSubscriptions();
        const status = subscriptions?.data?.[0]?.subscription_application.process_status;
        if (status === 'Submitted') {
            setInvestmentFinished(true);
        }
    }, [loadSubscriptions]);

    useEffect(() => {
        checkSubscription();
    }, []);

    return (
        <div
            className={cx('container mx-auto mt-20 transition-all ease duration-200', {
                'max-h-0 overflow-hidden': !investNow,
                'max-h-64': investNow,
            })}
        >
            <div className="flex justify-center items-center h-16 bg-black">
                <span className="text-white text-xl font-frankRuhlLibre">Invest</span>
            </div>
            <div className="bg-light-gray text-center py-10">
                {!accessToken && metadata.sudrania_id && (
                    <Completed investAmount={investAmount} setInvestAmount={setInvestAmount} />
                )}
                {!investmentFinished && accessToken && metadata.sudrania_id && (
                    <Submitted investAmount={investAmount} loadSubscriptions={loadSubscriptions} />
                )}
                {!metadata.sudrania_id && <NotSubmitted />}
                {investmentFinished && accessToken && metadata.sudrania_id && <Invested />}
            </div>
        </div>
    );
};

export default DealInvest;
