import { useCallback, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import cx from 'classnames';

// Components
import { Button } from 'components/common';

// Assets
import ArcaLogoWhite from 'assets/images/orca_logo_white.png';
import ArcaLogoMin from 'assets/images/orca_logo_min.png';
import { ReactComponent as HamburgerIcon } from 'assets/svg/hamburger.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close_blue.svg';

// Store
import { selectSessionUser } from 'store/auth/selectors';
import { logout } from 'store/auth/actions';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

const Header = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    const user = useAppSelector(selectSessionUser);

    const isHome = useMatch('/');

    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const openMenu = useCallback(() => {
        setMenuOpen(true);
    }, []);

    const signup = useCallback(() => {
        if (isHome) {
            document.querySelector('.home-sign-up-form')?.scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest',
            });
        } else {
            navigate('/?section=signup');
        }
    }, [isHome]);

    return (
        <div
            className={cx('w-full', {
                'h-16': !isHome,
                'md:h-[75px]': isHome,
            })}
        >
            <div className="fixed top-0 left-0 w-full z-20 hidden justify-between items-center px-12 h-[75px] bg-black md:flex">
                <div className="flex justify-start items-center">
                    <Link to="/">
                        <img src={ArcaLogoWhite} className="h-16" alt="" />
                    </Link>
                    {user && (
                        <Link to="/market">
                            <div className="ml-20 lg:ml-28 text-white">Market</div>
                        </Link>
                    )}
                </div>
                {user && (
                    <div className="flex justify-end items-center">
                        <Link to="/account" className="text-white mr-6">
                            {user.name}
                        </Link>
                        <div className="text-white cursor-pointer" onClick={() => dispatch(logout())}>
                            Logout
                        </div>
                    </div>
                )}
                {!user && (
                    <div className="flex justify-end w-48 max-w-lg">
                        <Link to="/login" className="w-full">
                            <Button className="w-full">LOG IN</Button>
                        </Link>
                    </div>
                )}
            </div>
            <div
                className={cx(
                    'fixed z-30 w-full h-16 pr-3 pl-5 flex justify-between items-center ease-in duration-100 md:hidden',
                    {
                        'bg-black': menuOpen || !isHome,
                    }
                )}
            >
                <img src={ArcaLogoMin} alt="logo" className="h-10" />
                {!menuOpen && (
                    <button className="cursor-pointer md:hidden" onClick={openMenu}>
                        <HamburgerIcon />
                    </button>
                )}
                {menuOpen && (
                    <button className="cursor-pointer md:hidden" onClick={closeMenu}>
                        <CloseIcon />
                    </button>
                )}
            </div>
            {menuOpen && (
                <div className="fixed top-0 left-0 w-full h-screen z-20 bg-black95 flex flex-col justify-end px-2.5 pb-5">
                    <Link to="/market" className="text-center">
                        <button className="mx-auto text-white w-24 py-2.5 mb-5">Market</button>
                    </Link>
                    <Link to="/login">
                        <Button className="w-full">LOGIN</Button>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default Header;
