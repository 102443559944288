import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';

import { useAppSelector } from 'hooks';

import { selectSessionUser } from 'store/auth/selectors';

interface NotLoggedRouteProps {
    children?: JSX.Element;
}

function ProtectedRoute({ children }: NotLoggedRouteProps) {
    const location = useLocation();
    const user = useAppSelector(selectSessionUser);
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children ? children : <Outlet />;
}

export default ProtectedRoute;
