import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useClickAway } from 'react-use';
import cx from 'classnames';

import { ReactComponent as ChevronDown } from 'assets/svg/chevron_down.svg';

export interface SelectOption {
    label: string;
    value: string;
}

interface SelectProps {
    label?: string;
    selectedDefault?: string;
    dropdownClassName?: string;
    options: SelectOption[];
    error?: string;
    disabled?: boolean;
    onSelect?: (option: SelectOption) => void;
}

const Select = ({
    label,
    options,
    selectedDefault,
    error,
    dropdownClassName,
    disabled,
    onSelect,
}: SelectProps) => {
    const selectRef = useRef<HTMLDivElement>(null);

    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);

    useClickAway(selectRef, () => {
        setShowDropdown(false);
    });

    const changeDropdownState = useCallback(() => {
        setShowDropdown(!showDropdown);
    }, [showDropdown]);

    const handleSelect = useCallback((option: SelectOption) => {
        setShowDropdown(false);
        setSelectedOption(option);
        if (onSelect) {
            onSelect(option);
        }
    }, []);

    useEffect(() => {
        if (selectedDefault) {
            const option = options.find((option) => option.value === selectedDefault);
            if (option) {
                setSelectedOption(option);
            }
        }
    }, [selectedDefault, options]);

    return (
        <div className="relative" ref={selectRef}>
            {label && (
                <div className="text-xs font-segoe font-bold mb-2.5 h-6 flex items-center">
                    <label>{label}</label>
                </div>
            )}
            <div
                className={cx(
                    'w-full h-10 outline-0 px-3 relative',
                    'border-3 border-gray border-solid bg-white',
                    'flex justify-start items-center',
                    {
                        'pointer-events-none opacity-50': disabled,
                    }
                )}
                onClick={changeDropdownState}
            >
                <div className="font-segoe text-base pr-9 truncate ...">{selectedOption?.label || ''}</div>
                <ChevronDown className="absolute top-1/2 right-4 -translate-y-1/2" />
            </div>
            {error && <div className="text-red-400">{error}</div>}
            {showDropdown && (
                <div className={cx('absolute top-[84px] h-60 overflow-auto bg-white shadow-md z-10', dropdownClassName)}>
                    {options.map((option: SelectOption) => (
                        <div
                            key={option.value}
                            onClick={() => handleSelect(option)}
                            className={cx(
                                'cursor-pointer transition ease-in duration-100 py-1 px-3 hover:bg-link hover:text-white',
                                {
                                    'bg-link text-white': selectedOption?.value === option.value,
                                }
                            )}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Select;
