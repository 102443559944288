import type { ElectronicDeliveryByEmail, InvestorPersonalInfo, K1ConsentElectronicDelivery } from 'types/Kyc';
import * as Yup from 'yup';
import { FIELD_REQUIRED_ERROR } from '../common';

const personalInfoSchema: Yup.SchemaOf<InvestorPersonalInfo> = Yup.object().shape(
  {
    first_name: Yup.string().required(FIELD_REQUIRED_ERROR),
    last_name: Yup.string().required(FIELD_REQUIRED_ERROR),
    address1: Yup.string().required(FIELD_REQUIRED_ERROR),
    address2: Yup.string().optional(),
    city: Yup.string().required(FIELD_REQUIRED_ERROR),
    state: Yup.string().required(FIELD_REQUIRED_ERROR),
    zipcode: Yup.string().required(FIELD_REQUIRED_ERROR),
    country: Yup.string().required(FIELD_REQUIRED_ERROR),
    is_address_same: Yup.bool(),
    dob: Yup.string(),
    mail_address: Yup.string(),
    m_address2: Yup.string(),
    mail_city: Yup.string(),
    mail_state: Yup.string(),
    mail_zipcode: Yup.string(),
    mail_country: Yup.string(),
    electronic_delivery_by_email:
      Yup.mixed<ElectronicDeliveryByEmail>()
        .required(FIELD_REQUIRED_ERROR)
        .oneOf(['Y', 'N']),
    k1_consent_electronic_delivery:
      Yup.mixed<K1ConsentElectronicDelivery>()
        .required(FIELD_REQUIRED_ERROR)
        .oneOf(['A', 'B']),
    country_of_citizenship: Yup.string().required(FIELD_REQUIRED_ERROR),
  },
);

export default personalInfoSchema;
