import { type DropzoneOptions, useDropzone } from 'react-dropzone';
import cx from 'classnames';

import { ReactComponent as UploadIcon } from 'assets/svg/uploadIcon.svg';
import Button from '../Button';

interface DropZoneProps {
    fullWidth?: boolean;
    previewUrl?: string;
    fileName?: string;
    options?: DropzoneOptions;
}

const DropZone = ({ fullWidth, previewUrl, fileName, options }: DropZoneProps) => {
    const { getRootProps, getInputProps } = useDropzone(options);

    return (
        <div
            {...getRootProps()}
            className={cx('bg-light-gray border-3 border-gray border-dashed w-[510px] mx-auto max-w-full', {
                'w-[510px]': !fullWidth,
                'w-full': fullWidth,
                'h-auto': previewUrl,
                'h-36 lg:h-52': !previewUrl,
            })}
        >
            <input {...getInputProps()} />
            <div className="pt-4 pb-7 flex justify-center items-center flex-col">
                {!previewUrl && !fileName && <UploadIcon className="hidden lg:inline-block" />}
                {previewUrl && <img src={previewUrl} className="w-5/6 mb-8" alt="" />}
                {fileName && <div className="text-center mt-7 mb-8">{fileName}</div>}
                {!previewUrl && !fileName && (
                    <div className="hidden lg:block">
                        <div className="mt-2.5 font-bold text-dark-gray">DROP FILES HERE</div>
                        <div className="mt-2.5 mb-7 font-bold text-dark-gray text-sm">OR</div>
                    </div>
                )}
                <Button variant="link" className="mt-9 w-64 md:w-auto lg:mt-0">
                    <span className="text-yellow-300 md:text-white">
                        {!previewUrl && 'SELECT A FILE'}
                        {previewUrl && 'SELECT ANOTHER FILE'}
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default DropZone;
