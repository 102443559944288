import React, { useState } from 'react';
import { Button } from 'components/common';
import ApartmentImage from 'assets/images/apartment.png';
import { BarChart } from 'components/common';
import { Carousel } from '@trendyol-js/react-carousel';
import HouseImage1 from 'assets/images/StockHouse1.png';
import HouseImage2 from 'assets/images/stockHouse.png';
import HouseImage3 from 'assets/images/buildings_1.png';
import HouseImage4 from 'assets/images/buildings_2.png';
import HouseImage5 from 'assets/images/apartment.png';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow_right.svg';
import formatToCurrencyString from 'utils/helpers/formatToCurrencyString';
interface CardProps {
  invested?: boolean;
  name: string;
  description: string;
  investedNum: string;
  marketValue: string;
}

const Card: React.FC<CardProps> = ({
  invested,
  name,
  description,
  investedNum,
  marketValue,
}) => {
  const [expand, setExpand] = useState(false);
  return (
    <div className="w-full my-4 shadow-xl">
      <div className="w-full overflow-hidden font-frankRuhlLibre grid grid-cols-1 lg:grid-cols-[350px_1fr] xl:grid-cols-[400px_1fr] min-h-[270px]">
        <div className="relative flex justify-center">
          <img className="w-full h-full" src={ApartmentImage} alt="Sunset in the mountains" />
        </div>
        <div className="flex bg-black">
          <div className="w-8/12 lg:w-10/12 flex flex-col justify-between p-6 md:p-8">
            <div>
              <div className="font-frankRuhlLibre text-white-desc text-xl pt-2 mb-2">
                {name}
              </div>
              <p className="text-white-desc font-medium text-xs font-segoe">
                {description}
              </p>
            </div>
            {invested && <Button className="uppercase w-56 text-xl py-6 mt-2">INVEST MORE</Button>}
          </div>
          <div className="w-4/12 lg:w-2/12 p-6 md:p-8 !pl-0">
            <h1 className="text-primary leading-tight text-[22px] text-right font-medium font-segoe">
              ${formatToCurrencyString(parseInt(investedNum))}
            </h1>
            <h1 className="text-gray text-sm text-right mb-3">Amt Invested</h1>
            <h1 className="text-secondary leading-tight text-base text-right font-segoe">${marketValue}</h1>
            <h1 className="text-gray text-sm text-right mb-3">Market Value</h1>
            <h1 className="text-secondary leading-tight text-base text-right font-segoe">20%</h1>
            <h1 className="text-gray text-sm text-right mb-3">Net Equity</h1>
            <h1 className="text-secondary leading-tight text-base text-right font-segoe">$124,567</h1>
            <h1 className="text-gray text-sm text-right">Total Income Paid</h1>
          </div>
          {!expand && (
            <div className="bg-primary cursor-pointer w-12" onClick={() => setExpand(!expand)}>
              <div className="w-full h-full flex justify-center items-center font-segoe text-base lg:text-lg text-black font-bold">
                <span className="-rotate-90">EXPAND</span>
              </div>
            </div>
          )}
          {expand && (
            <div className="bg-link cursor-pointer w-12" onClick={() => setExpand(!expand)}>
              <div className="w-full h-full flex justify-center items-center font-segoe text-base lg:text-lg text-primary font-bold">
                <span className="-rotate-90">COLLAPSE</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {expand && (
        <div className="w-full p-1">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-10 px-4">
            <BarChart
              title="Bar Graph Demo"
              labels={['STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT']}
              series={[
                { name: 'Bars group 1', data: [4000, 4000, 3000, 4000, 1000, 0, 7000, 2000, 6000] },
                { name: 'Bars group 2', data: [3000, 2000, 3000, 6000, 4000, 6000, 3000, 7000, 3000] },
                {
                  name: 'Bars group 3',
                  notStacked: true,
                  data: [1000, 0, 7400, 9000, 2200, 4200, 5000, 1000, 3000],
                },
              ]}
              height="250px"
            />
            <div className="p-4 flex flex-col">
              <div className="text-xs text-desc font-segoe mt-0 lg:mt-20">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
                sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
                rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet.
              </div>
              <Button className="uppercase w-56 md:w-80 my-6">See Original Deal</Button>
              <Carousel
                show={3.5}
                slide={3}
                swiping={true}
                useArrowKeys
                className="relative px-6"
                leftArrow={
                  <div className="w-8 absolute z-[1] -left-4 top-8 cursor-pointer">
                    <ArrowLeft />
                  </div>
                }
                rightArrow={
                  <div className="w-8 absolute z-[1] -right-4 top-8 cursor-pointer">
                    <ArrowRight />
                  </div>
                }
              >
                <div className="mr-2 h-24">
                  <img className="w-full h-full object-cover" src={HouseImage1} />
                </div>
                <div className="mr-2 h-24">
                  <img className="w-full h-full object-cover" src={HouseImage2} />
                </div>
                <div className="mr-2 h-24">
                  <img className="w-full h-full object-cover" src={HouseImage3} />
                </div>
                <div className="mr-2 h-24">
                  <img className="w-full h-full object-cover" src={HouseImage4} />
                </div>
                <div className="mr-2 h-24">
                  <img className="w-full h-full object-cover" src={HouseImage5} />
                </div>
              </Carousel>
            </div>
            {/* <div className="py-2 flex flex-col">
                <div className="text-black text-[18px] leading-10 font-semibold py-1 flex justify-between items-center">
                    <div>Amt Invested</div>
                    <div className="font-segoe font-bold">$500,000</div>
                </div>
                <div className="text-black text-[18px] leading-10 font-semibold py-1 flex justify-between items-center">
                    <div>Current Market Value</div>
                    <div className="font-segoe font-bold">$500,000</div>
                </div>
                <div className="text-black text-[18px] leading-10 font-semibold py-1 flex justify-between items-center">
                    <div>Net Equity</div>
                    <div className="font-segoe font-bold">$500,000</div>
                </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
