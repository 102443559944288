import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from 'hooks';

import { selectSessionUser } from 'store/auth/selectors';

interface NotLoggedRouteProps {
    children?: JSX.Element;
}

function NotLoggedRoute({ children }: NotLoggedRouteProps) {
    const user = useAppSelector(selectSessionUser);
    if (user) {
        return <Navigate to="/" replace />;
    }

    return children ? children : <Outlet />;
}

export default NotLoggedRoute;
