import type { SubmitHandler } from 'react-hook-form';
import type { SignupSubmit } from 'pages/Signup/Signup';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

// Components
import { Button, Input, Link } from 'components/common';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Redux
import { authActions } from 'store/auth';
import { selectAuthLoading } from 'store/auth/selectors';

// Validator options
import { getEmailValidationOptions, getPasswordValidationOptions } from 'utils/auth/validations';

const Login = () => {
    const dispatch = useAppDispatch();

    const authLoading = useAppSelector(selectAuthLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignupSubmit>();

    const emailInputProps = register('email', getEmailValidationOptions());
    const passwordInputProps = register('password', getPasswordValidationOptions());

    const onSubmit: SubmitHandler<SignupSubmit> = useCallback(
        async (data) => {
            // dispatch(authActions.signup(omit(['passwordConfirm'], data)));
            dispatch(
                authActions.login({
                    userId: data.email,
                    password: data.password,
                })
            );
        },
        [dispatch]
    );

    return (
        <div className="w-full flex-1 flex justify-center items-center bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-[right_0px_bottom_-50px] bg-contain sm:bg-[right_-300px_bottom_-300px]">
            <div className="container flex-1 flex justify-center items-center px-4">
                <form className="w-full sm:max-w-2xl" onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        error={errors?.email?.message}
                        label="Email"
                        {...emailInputProps}
                        fullWidth
                    />
                    <Input
                        containerClassName="mt-6"
                        type="password"
                        error={errors?.password?.message}
                        label="Password"
                        {...passwordInputProps}
                        fullWidth
                    />
                    <Button type="submit" className="mx-auto mb-8 mt-10 uppercase w-64" isLoading={authLoading}>
                        Login
                    </Button>
                    <Link className="block text-center mb-2.5" to="/forgot-password">
                        Forgot Password
                    </Link>
                    <Link className="block text-center" to="/signup">{`Don't Have An Account?`}</Link>
                </form>
            </div>
        </div>
    );
};

export default Login;
