import type { FC, ReactNode } from 'react';
import React from 'react';
import { Tab } from '@headlessui/react';
import cx from 'classnames';

interface TabListProps {
    className?: string;
    children: ReactNode;
}

const TabList: FC<TabListProps> = ({ className, children, ...otherProps }) => (
    <Tab.List className={cx('grid grid-cols-3 gap-5 items-center', className)} {...otherProps}>
        {children}
    </Tab.List>
);

TabList.defaultProps = {
    className: '',
};

export default TabList;
