import type { ChangeEvent, FormEvent } from 'react';
import type { UsState } from 'types/UserMetadata';
import { useCallback, useEffect, useMemo, useState } from 'react';

import usStates from 'utils/us_states.json';

// Components
import { Button, Input, Select } from 'components/common';

// Store
import { selectAccountMetadata, selectUpdateMetadataLoading } from 'store/account/selectors';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';
import { putAccountMetadata } from 'store/account/actions';

const Personal = () => {
    const dispatch = useAppDispatch();

    const metadata = useAppSelector(selectAccountMetadata);
    const updateMetadataLoading = useAppSelector(selectUpdateMetadataLoading);

    const [address, setAddress] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zipCode, setZipCode] = useState<string>('');

    const states = useMemo(() => {
        return usStates.map((state: UsState) => ({
            label: state.name,
            value: state.abbreviation,
        }));
    }, []);

    useEffect(() => {
        setAddress(metadata?.address || '');
        setCity(metadata?.city || '');
        setFirstName(metadata?.first_name || '');
        setLastName(metadata?.last_name || '');
        setPhone(metadata?.phone || '');
        setState(metadata?.state || '');
        setZipCode(metadata?.zip_code || '');
    }, [metadata]);

    const submit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            dispatch(
                putAccountMetadata({
                    address,
                    city,
                    first_name: firstName,
                    last_name: lastName,
                    phone,
                    zip_code: zipCode,
                    state,
                })
            );
        },
        [address, city, firstName, lastName, phone, zipCode, state]
    );

    return (
        <form onSubmit={submit} className="my-4 lg:my-8">
            <h3 className="text-base font-bold font-frankRuhlLibre bg-link text-white-desc p-4">Personal</h3>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-0 xl:gap-10 mt-5 px-5">
                <Input
                    value={firstName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                    containerClassName="mb-4"
                    label="First Name"
                    fullWidth
                />
                <Input
                    value={lastName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                    containerClassName="mb-4"
                    label="Last Name"
                    fullWidth
                />
            </div>
            <div className="grid grid-cols-1 px-5">
                <Input
                    value={address}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
                    containerClassName="mb-4"
                    label="Address"
                    fullWidth
                />
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-[250px_1fr] px-5 gap-0 xl:gap-10">
                <Input
                    value={city}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
                    containerClassName="mb-4"
                    label="City"
                    fullWidth
                />
                <div className="grid grid-cols-1 gap-0 xl:gap-10 xl:grid-cols-2">
                    {/* <Input containerClassName="mb-4" label="State" fullWidth /> */}
                    <Select
                        onSelect={(option) => setState(option.value)}
                        selectedDefault={metadata?.state}
                        label="State"
                        options={states}
                        dropdownClassName="w-56"
                    />
                    <Input
                        value={zipCode}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setZipCode(e.target.value)}
                        containerClassName="mb-4"
                        label="Zip Code"
                        fullWidth
                    />
                </div>
            </div>
            <div className="px-5">
                <Input
                    value={phone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                    containerClassName="mb-4"
                    label="Phone"
                    fullWidth
                />
            </div>
            <div className="flex justify-center mt-0 xl:mt-3 pb-3 lg:pb-10">
                <Button type="submit" className="uppercase w-64 mx-3" isLoading={updateMetadataLoading}>
                    Save
                </Button>
            </div>
        </form>
    );
};

export default Personal;
