import type { Table } from 'types/Tables';
import cx from 'classnames';

interface NormalRowProps {
    data: Table;
    isGray?: boolean;
}

const NormalRow = ({ data, isGray }: NormalRowProps) => {
    return (
        <div
            className={cx('flex justify-between px-4 items-center text-sm sm:text-base font-bold py-3', {
                'bg-[#DDE1E8]': isGray,
            })}
        >
            <div>{data.name}</div>
            <div className="font-segoe font-semibold text-sm sm:text-lg">{data.value}</div>
        </div>
    );
};

export default NormalRow;
