import { Link, useMatch } from 'react-router-dom';

// Assets
import ArcaLogoMin from 'assets/images/orca_logo_min.png';

function Footer() {
    const isHomeAlt = useMatch('/');

    return (
        <div className="flex justify-center items-center h-16 w-full bg-black sm:h-footer">
            <div className="flex justify-between items-center w-full h-full px-8 md:px-9">
                <nav className="flex justify-start items-center mt-2">
                    <a
                        href="mailto:info@0percent.com"
                        target="_top"
                        className="text-white text-sm font-segoe font-extralight hover:text-primary"
                    >
                        Contact
                    </a>
                </nav>
                <img className="h-9 md:h-16" src={ArcaLogoMin} />
            </div>
        </div>
    );
}

export default Footer;
