import type {
    AddInvestorParams,
    InvestorClassification,
    InvestorClassificationAndType,
    InvestorType,
    TrustTypeOfOwnership,
    TypeOfOwnershipEnt,
    TypeOfOwnershipInv,
    TypeOfOwnershipIra,
} from 'types/Kyc';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Utils
import {
    INVESTOR_CLASSIFICATIONS,
    INVESTOR_TYPES,
    TRUST_TYPE_OF_OWNERSHIP,
    TYPE_OF_OWNERSHIP_ENT,
    TYPE_OF_OWNERSHIP_INV,
    TYPE_OF_OWNERSHIP_IRA,
} from 'utils';
import investorClassificationAndTypeSchema from 'utils/schemas/kyc/investorClassificationAndType';

// Store
import { selectKycInvestorDetails, selectKycLoading, selectKycStep } from 'store/kyc/selectors';
import { addInvestor, setKycStep, updateInvestor } from 'store/kyc/actions';

// Components
import { FormHeader, Input, RadioGroup } from 'components/common';
import StepsPagination from '../StepsPagination';

const Step2 = () => {
    const dispatch = useAppDispatch();

    const {
        setValue,
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<InvestorClassificationAndType>({
        resolver: yupResolver(investorClassificationAndTypeSchema),
        defaultValues: {
            investor_type: 'IND',
        },
    });

    const step = useAppSelector(selectKycStep);
    const investorDetails = useAppSelector(selectKycInvestorDetails);
    const loading = useAppSelector(selectKycLoading);

    const investor_type = watch('investor_type');
    const type_of_ownership_inv = watch('type_of_ownership_inv');
    const type_of_ownership_ent = watch('type_of_ownership_ent');
    const type_of_ownership_ira = watch('type_of_ownership_ira');
    const investor_classification = watch('investor_classification');

    const showOtherInput = useMemo(() => {
        return type_of_ownership_inv === 'D' || type_of_ownership_ent === 'O' || type_of_ownership_ira === 'F';
    }, [type_of_ownership_inv, type_of_ownership_ent, type_of_ownership_ira]);

    const goBack = useCallback(() => {
        dispatch(setKycStep(step - 1));
    }, [step]);

    const submit = useCallback(
        (data: InvestorClassificationAndType) => {
            if (investorDetails?.investor_sr_no) {
                dispatch(
                    updateInvestor({
                        investor_sr_no: investorDetails.investor_sr_no,
                        data,
                    })
                );
            } else {
                dispatch(
                    addInvestor({
                        ...investorDetails,
                        ...data,
                    } as AddInvestorParams)
                );
            }
        },
        [investorDetails]
    );

    useEffect(() => {
        setValue('type_of_ownership_inv', undefined);
        setValue('type_of_ownership_ent', undefined);
        setValue('type_of_ownership_ira', undefined);
        setValue('trust_type_of_ownership', undefined);
    }, [investor_type]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormHeader title="2. Investor Classification and Type" />
            <div className="px-3 mt-7">
                <RadioGroup
                    label="Investor Classification (Please pass anyone from below list)"
                    items={INVESTOR_CLASSIFICATIONS}
                    onSelect={(item) => setValue('investor_classification', item.value as InvestorClassification)}
                />
                {investor_classification === 'M' && (
                    <div className="pl-10">
                        <Input {...register('ownership_type_oth')} fullWidth />
                    </div>
                )}
                {errors?.investor_classification && (
                    <div className="text-red-400 text-lg">{errors.investor_classification.message}</div>
                )}
            </div>
            <div className="px-3 mt-7">
                <RadioGroup
                    label="For Investor type (Please select any one)"
                    items={INVESTOR_TYPES}
                    onSelect={(item) => setValue('investor_type', item.value as InvestorType)}
                    defaultItemValue={investor_type}
                />
            </div>
            <div className="px-3 mt-7">
                {investor_type === 'IND' && (
                    <RadioGroup
                        label="For Individual (IND) Ownership type (Please select any one)"
                        items={TYPE_OF_OWNERSHIP_INV}
                        onSelect={(item) => setValue('type_of_ownership_inv', item.value as TypeOfOwnershipInv)}
                    />
                )}
                {investor_type === 'ENT' && (
                    <RadioGroup
                        label="For Entity (ENT) Ownership type (Please select any one)"
                        items={TYPE_OF_OWNERSHIP_ENT}
                        onSelect={(item) => setValue('type_of_ownership_ent', item.value as TypeOfOwnershipEnt)}
                    />
                )}
                {investor_type === 'IRA' && (
                    <RadioGroup
                        label="For IRA Ownership type (Please select any one)"
                        items={TYPE_OF_OWNERSHIP_IRA}
                        onSelect={(item) => setValue('type_of_ownership_ira', item.value as TypeOfOwnershipIra)}
                    />
                )}
                {investor_type === 'TRUST' && (
                    <RadioGroup
                        label="For Trust Ownership type (Please select any one)"
                        items={TRUST_TYPE_OF_OWNERSHIP}
                        onSelect={(item) => setValue('trust_type_of_ownership', item.value as TrustTypeOfOwnership)}
                    />
                )}
                {showOtherInput && (
                    <div className="pl-10">
                        <Input {...register('ownership_type_oth')} fullWidth />
                    </div>
                )}
            </div>
            <StepsPagination goBack={goBack} loading={loading} />
        </form>
    );
};

export default Step2;
