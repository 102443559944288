import { useCallback, useEffect, useState } from 'react';
import Checkbox from '../Checkbox';

interface RadioGroupItem {
    value: string;
    label: string;
    onSelect?: (item: RadioGroupItem) => void;
}

interface RadioGroupProps {
    label?: string;
    items: RadioGroupItem[];
    defaultItemValue?: string;
    onSelect?: (item: RadioGroupItem) => void;
}

const RadioGroup = ({ label, items, defaultItemValue, onSelect }: RadioGroupProps) => {
    const [selectedItem, setSelectedItem] = useState<RadioGroupItem | null>(null);

    const onItemSelect = useCallback((item: RadioGroupItem) => {
        setSelectedItem(item);
        if (item.onSelect) {
            item.onSelect(item);
        }
        if (onSelect) {
            onSelect(item);
        }
    }, []);

    useEffect(() => {
        if (defaultItemValue) {
            const item = items.find((i) => i.value === defaultItemValue);
            if (item) {
                setSelectedItem(item);
            }
        }
    }, [items, defaultItemValue]);

    return (
        <div>
            {label && <div className="font-frankRuhlLibre font-bold mb-5">{label}</div>}
            <div className="px-2">
                {items.map((item) => (
                    <div className="mb-4" key={item.value}>
                        <Checkbox
                            label={item.label}
                            onSelect={() => onItemSelect(item)}
                            selected={selectedItem?.value === item.value}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RadioGroup;
