import React, { useCallback, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { omit } from 'ramda';

// Components
import { Button, Input, Link } from 'components/common';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Redux
import { authActions } from 'store/auth';
import { selectAuthLoading } from 'store/auth/selectors';

// Validator options
import {
    getEmailValidationOptions,
    getNameValidationOptions,
    getPasswordConfirmValidationOptions,
    getPasswordValidationOptions,
} from 'utils/auth/validations';
import { SignupLevel } from 'api';

export interface SignupSubmit {
    email: string;
    name: string;
    password: string;
    passwordConfirm: string;
}

const Signup = () => {
    const dispatch = useAppDispatch();

    const authLoading = useAppSelector(selectAuthLoading);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<SignupSubmit>();

    const password = useRef<string>('');
    password.current = watch('password', '');

    const nameInputProps = register('name', getNameValidationOptions());
    const emailInputProps = register('email', getEmailValidationOptions());
    const passwordInputProps = register('password', getPasswordValidationOptions());
    const passwordConfirmInputProps = register(
        'passwordConfirm',
        getPasswordConfirmValidationOptions(password.current)
    );

    const onSubmit: SubmitHandler<SignupSubmit> = useCallback(
        async (data) => {
            dispatch(
                authActions.signup(
                    omit(['passwordConfirm'], {
                        ...data,
                        level: SignupLevel.USER,
                    })
                )
            );
        },
        [dispatch]
    );

    return (
        <div className="min-h-100vh pb-40 w-full flex-1 flex justify-center items-start bg-white bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-[right_0px_bottom_-50px] bg-contain sm:items-center sm:min-h-[800px] sm:pb-0 sm:bg-[right_-300px_bottom_-300px]">
            <div className="container">
                <div className="max-w-2xl m-auto px-5 sm:pt-5">
                    <div className="text-4xl sm:text-3xl text-center font-frankRuhlLibre mb-10 mt-6">
                        {"Let's Get Started!"}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            containerClassName="mb-4"
                            label="Name"
                            error={errors?.name?.message}
                            {...nameInputProps}
                            fullWidth
                        />
                        <Input
                            containerClassName="mb-4"
                            label="Email"
                            error={errors?.email?.message}
                            {...emailInputProps}
                            fullWidth
                        />
                        <Input
                            containerClassName="mb-4"
                            label="Password"
                            type="password"
                            error={errors?.password?.message}
                            {...passwordInputProps}
                            fullWidth
                        />
                        <Input
                            containerClassName="mb-4"
                            label="Password confirm"
                            type="password"
                            error={errors?.passwordConfirm?.message}
                            {...passwordConfirmInputProps}
                            fullWidth
                        />
                        <Button type="submit" className="mx-auto mt-10 mb-8 uppercase w-64" isLoading={authLoading}>
                            Create Account
                        </Button>
                        <Link to="/login" className="block text-center">
                            I Already Have An Account
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
