import type { Blocker } from 'history';
import { useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import PageLayout from 'components/common/PageLayout';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import LoadingStep from './LoadingStep';
import SuccessStep from './SuccessStep';
import LeaveWarningModal from './LeaveWarningModal';

// Hooks
import { useAppSelector, useNavigationBlocker } from 'hooks';

// Store
import { selectKycStep } from 'store/kyc/selectors';

const Kyc = () => {
    const step = useAppSelector(selectKycStep);
    const navigate = useNavigate();

    const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);
    const nextPath = useRef<string>('');

    const blocker: Blocker = useCallback(
        (tx) => {
            setShowLeaveModal(true);
            nextPath.current = tx.location.pathname || '/';
        },
        [showLeaveModal]
    );

    const onAccept = useCallback(() => {
        navigate(nextPath.current);
    }, [showLeaveModal]);

    const onCancel = useCallback(() => {
        setShowLeaveModal(false);
    }, []);

    useNavigationBlocker(blocker, !showLeaveModal && step !== 6);

    return (
        <PageLayout>
            <div className="h-full w-full bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-[right_0px_bottom_-50px] bg-contain sm:bg-[right_-300px_bottom_-300px]">
                <div className="px-0 w-full mx-auto mt-24 pb-64 lg:w-content lg:px-5">
                    <div className="text-black text-center text-3xl font-frankRuhlLibre">KYC</div>
                    <div className="my-10 text-center text-desc text-sm px-5 lg:px-0">
                        {step < 4 && 'Please complete the following forms to ensure compliance prior to investing'}
                        {step === 4 && 'Please complete the following forms to ensure compliance prior to investing'}
                        {step === 5 && 'Your submission is under review'}
                        {step === 6 && 'Submission successful!'}
                    </div>
                    {step === 0 && <Step1 />}
                    {step === 1 && <Step2 />}
                    {step === 2 && <Step3 />}
                    {step === 3 && <Step4 />}
                    {step === 4 && <Step5 />}
                    {step === 5 && <LoadingStep />}
                    {step === 6 && <SuccessStep />}
                </div>
                {showLeaveModal && <LeaveWarningModal onAccept={onAccept} onCancel={onCancel} />}
            </div>
        </PageLayout>
    );
};

export default Kyc;
