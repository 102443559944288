import { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MarketCard, Pagination, Spinner } from 'components/common';

import MarketHero from './Hero';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getDeals, setDealsPage } from 'store/market/actions';
import {
    selectMarketDeals,
    selectMarketDealsLoading,
    selectMarketDealsPage,
    selectMarketDealsPerPage,
    selectMarketDealsTotal,
} from 'store/market/selectors';

const Market = () => {
    const dispatch = useAppDispatch();

    const deals = useAppSelector(selectMarketDeals);
    const dealsPerPage = useAppSelector(selectMarketDealsPerPage);
    const dealsPage = useAppSelector(selectMarketDealsPage);
    const dealsTotal = useAppSelector(selectMarketDealsTotal);
    const dealsLoading = useAppSelector(selectMarketDealsLoading);

    const maxPage = useMemo(() => {
        return Math.ceil(dealsTotal / dealsPerPage);
    }, [dealsTotal, dealsPerPage]);

    const onPageChange = useCallback(
        (nextPage) => {
            dispatch(setDealsPage(nextPage));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(
            getDeals({
                total: dealsPerPage,
                offset: (dealsPage - 1) * dealsPerPage,
            })
        );
    }, [dispatch, dealsPerPage, dealsPage]);

    return (
        <div className="w-full mt-5 bg-[url('assets/images/slashSplash_duo.png')] bg-no-repeat bg-center bg-cover">
            <div className="container mx-auto max-w-5xl mb-6">
                <MarketHero />
                {dealsLoading && (
                    <div className="min-h-screen">
                        <div className="flex justify-center items-center h-52">
                            <Spinner className="w-20 h-20" />
                        </div>
                    </div>
                )}
                {!dealsLoading && (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 xl:gap-8 px-1 sm:px-6">
                            {deals.map((deal) => (
                                <Link key={deal.id} to={`/deal/${deal.id}`}>
                                    <MarketCard
                                        name={deal.name}
                                        featuredDescription={deal.featured_description}
                                        invested={deal.invested > 0}
                                        raise={deal.raise}
                                        interval={deal.interval}
                                        featuredImage={deal.featured_image}
                                        sold
                                    />
                                </Link>
                            ))}
                        </div>
                        <div className="px-1 md:px-4">
                            <Pagination page={dealsPage} maxPage={maxPage} onPageChange={onPageChange} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Market;
