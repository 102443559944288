export enum PlaidEnvironment {
  DEVELOPMENT = 'dev',
  SANDBOX = 'sandbox',
  PRODUCTION = 'prod',
}

export interface PlaidRequest {
  path: string;
  body?: string;
  environment: string;
  verb: string;
  params?: string;
}

export interface CreateLinkTokenResponse {
  expiration: string;
  link_token: string;
  request_id: string;
}

export interface ExchangePublicTokenResponse {
  access_token: string;
  item_id: string;
  request_id: string;
}
