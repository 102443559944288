import { type Table as ITable } from 'types/Tables';
import HeadingRow from './HeadingRow';
import HighlightRow from './HighlightRow';
import NormalRow from './NormalRow';

interface TableProps {
    table: ITable[];
}

const Table = ({ table }: TableProps) => {
    if (!table) return null;
    return (
        <div>
            {table.map((item, index) => {
                if (index === 0) {
                    return <HighlightRow data={item} key={index} />;
                } else if (!item.value) {
                    return <HeadingRow key={index}>{item.name}</HeadingRow>;
                }
                return <NormalRow data={item} key={index} isGray={index % 2 === 0} />;
            })}
        </div>
    );
};

export default Table;
