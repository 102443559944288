import type { AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { CreateLinkTokenResponse, ExchangePublicTokenResponse } from 'types/Plaid';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as api from 'api';
import * as plaidActions from './actions';
import { accountActions } from 'store/account';

function* createLinkToken(action: PayloadAction<string>) {
  try {
    yield put(plaidActions.setPlaidLoading(true));
    const res: AxiosResponse<CreateLinkTokenResponse> =
      yield call(api.createPlaidToken, action.payload);
    yield put(plaidActions.setLinkToken(res.data.link_token));
    yield put(plaidActions.setPlaidLoading(false));
  } catch (error) {
    yield put(plaidActions.setPlaidLoading(false));
  }
}

function* exchangePublicToken(action: PayloadAction<string>) {
  try {
    yield put(plaidActions.setPlaidLoading(true));
    const res: AxiosResponse<ExchangePublicTokenResponse> =
      yield call(api.exchangePlaidToken, action.payload);
    yield put(plaidActions.setAccessToken(res.data.access_token));
    yield put(accountActions.putAccountMetadata({
      plaid_id: res.data.access_token,
    }));
    yield put(plaidActions.setPlaidLoading(false));
  } catch (error) {
    yield put(plaidActions.setPlaidLoading(false));
  }
}

function* plaidSaga() {
  yield all([
    takeEvery(plaidActions.createLinkToken.type, createLinkToken),
    takeEvery(plaidActions.exchangePublicToken.type, exchangePublicToken),
  ]);
}

export default plaidSaga;
