import { ReactComponent as CheckIcon } from 'assets/svg/check-mark.svg';
import { Button } from 'components/common';
import { Link } from 'react-router-dom';

const SuccessStep = () => {
    return (
        <div className="flex justify-center items-center flex-col">
            <div className="bg-primary w-20 h-20 rounded-full flex justify-center items-center">
                <CheckIcon className="fill-white stroke-white w-11" />
            </div>
            <Link to="/market" className="mt-16">
                <Button>RETURN TO MARKETPLACE</Button>
            </Link>
        </div>
    );
};

export default SuccessStep;
