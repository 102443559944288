import { ReactNode } from 'react';

interface PageLayoutProps {
    children: ReactNode;
}

const PageLayout = ({ children }: PageLayoutProps) => (
    <div className="w-full h-full min-h-[calc(100vh-theme(space.footer))] pt-16 sm:pt-header">{children}</div>
);

export default PageLayout;
