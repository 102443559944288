import type { UpdateAccountPasswordParams } from 'api';
import type UserMetadata from 'types/UserMetadata';
import type AccountProfile from 'types/AccountProfile';
import type AccountNotifications from 'types/AccountNotifications';
import type { ResetPasswordParams } from 'types/ResetPassword';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'account';

export const setLoading = createAction<boolean>(`${PREFIX}/setLoading`);
export const setUpdateMetadataLoading = createAction<boolean>(`${PREFIX}/setUpdateMetadataLoading`);
export const setUpdateNotificationsLoading = createAction<boolean>(`${PREFIX}/setUpdateNotificationsLoading`);
export const setUpdatePasswordLoading = createAction<boolean>(`${PREFIX}/setUpdatePasswordLoading`);
export const getAccountProfiles = createAction<string>(`${PREFIX}/getAccountProfiles`);
export const getAccountMetadata = createAction(`${PREFIX}/getAccountMetadata`);
export const putAccountMetadata = createAction<UserMetadata>(`${PREFIX}/putAccountMetadata`);
export const updateAccountMetaData = createAction<UserMetadata>(`${PREFIX}/updateAccountMetaData`);
export const updateAccountProfiles = createAction<AccountProfile[]>(`${PREFIX}/updateAccountProfiles`);
export const setAccountNotifications = createAction<AccountNotifications>(`${PREFIX}/setAccountNotifications`);
export const getAccountNotifications = createAction(`${PREFIX}/getAccountNotifications`);
export const updateAccountNotifications = createAction<AccountNotifications>(`${PREFIX}/updateAccountNotifications`);
export const updateAccountPassword = createAction<UpdateAccountPasswordParams>(`${PREFIX}/updateAccountPassword`)
export const setPasswordUpdateError = createAction<string>(`${PREFIX}/setPasswordUpdateError`);
export const forgotPassword = createAction<string>(`${PREFIX}/forgotPassword`);
export const forgotPasswordSuccess = createAction(`${PREFIX}/forgotPasswordSuccess`);
export const resetPassword = createAction<ResetPasswordParams>(`${PREFIX}/resetPassword`);
export const resetPasswordSuccess = createAction(`${PREFIX}/resetPasswordSuccess`);
