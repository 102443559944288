import { RegisterOptions } from 'react-hook-form';

import { regexps } from 'utils/constants';

export const getNameValidationOptions = (): RegisterOptions => ({
    pattern: {
        value: regexps.name,
        message: 'Only latin letters and space are available',
    },
    required: {
        value: true,
        message: 'Name is required',
    }
});

export const getEmailValidationOptions = (): RegisterOptions => ({
    pattern: {
        value: regexps.email,
        message: 'Incorrect email format',
    },
    required: {
        value: true,
        message: 'Email is required',
    },
});

export const getPasswordValidationOptions = (): RegisterOptions => ({
    required: {
        value: true,
        message: 'Password is requried',
    },
    minLength: {
        value: 8,
        message: 'Password length must be greater than or equal to 8'
    },
});

export const getPasswordConfirmValidationOptions = (password: string): RegisterOptions => ({
    required: {
        value: true,
        message: 'Please confirm your password'
    },
    validate: (value: string): boolean | string =>
        value === password || 'The passwords do not match',
});
