import type { CoreError } from './actions';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setError } from './actions';

export interface CoreState {
  error: CoreError,
}

const initialState: CoreState = {
  error: null,
};

const coreReducer = createReducer(initialState, {
  [setError.type]: (state: CoreState, action: PayloadAction<CoreError>) => ({
    ...state,
    error: action.payload,
  }),
});

export default coreReducer;
