import type { SudraniaFundDetails, SudraniaFundDetailsRaw } from 'types/Sudrania';

function convertSundraniaFundFromRaw(fund: SudraniaFundDetailsRaw): SudraniaFundDetails {
  return {
    id: fund.id,
    fund_name: fund['Fund Name'],
    fund_category: fund['Fund Category'],
    class: fund.Class,
    fund_type: fund['Fund Type'],
    logo: fund.Logo,
  }
}

export default convertSundraniaFundFromRaw;
