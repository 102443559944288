import type { PayloadAction } from '@reduxjs/toolkit';
import type UserMetadata from 'types/UserMetadata';
import type AccountProfile from 'types/AccountProfile';
import type AccountNotifications from 'types/AccountNotifications';
import { createReducer } from '@reduxjs/toolkit';

import {
  forgotPasswordSuccess,
  resetPasswordSuccess,
  setAccountNotifications,
  setPasswordUpdateError,
  setUpdateMetadataLoading,
  setUpdateNotificationsLoading,
  setUpdatePasswordLoading,
  updateAccountMetaData,
  updateAccountProfiles,
} from './actions';

export interface AccountState {
  loading: boolean;
  updateMetadataLoading: boolean;
  updateNotificationsLoading: boolean;
  updatePasswordLoading: boolean;
  metadata: UserMetadata;
  notifications: AccountNotifications;
  profiles: AccountProfile[];
  passwordUpdateError: string;
  isTokenSent: boolean;
  isPasswordResetSend: boolean;
}

const initialState: AccountState = {
  loading: false,
  updateMetadataLoading: false,
  updateNotificationsLoading: false,
  updatePasswordLoading: false,
  notifications: {
    email_new_deal: false,
    email_deal_updates: false,
    email_payment_claim: false,
  },
  metadata: {
    address: '',
    city: '',
    first_name: '',
    last_name: '',
    phone: '',
    state: '',
    zip_code: '',
  },
  profiles: [],
  passwordUpdateError: '',
  isTokenSent: false,
  isPasswordResetSend: false,
};

const accountReducer = createReducer(initialState, {
  [updateAccountMetaData.type]: (state: AccountState, action: PayloadAction<UserMetadata>) => ({
    ...state,
    metadata: action.payload,
  }),
  [setUpdateMetadataLoading.type]: (state: AccountState, action: PayloadAction<boolean>) => ({
    ...state,
    updateMetadataLoading: action.payload,
  }),
  [setUpdateNotificationsLoading.type]: (state: AccountState, action: PayloadAction<boolean>) => ({
    ...state,
    updateNotificationsLoading: action.payload,
  }),
  [setUpdatePasswordLoading.type]: (state: AccountState, action: PayloadAction<boolean>) => ({
    ...state,
    updatePasswordLoading: action.payload,
  }),
  [updateAccountProfiles.type]: (state: AccountState, action: PayloadAction<AccountProfile[]>) => ({
    ...state,
    profiles: action.payload,
  }),
  [setAccountNotifications.type]: (state: AccountState, action: PayloadAction<AccountNotifications>) => ({
    ...state,
    notifications: action.payload,
  }),
  [setPasswordUpdateError.type]: (state: AccountState, action: PayloadAction<string>) => ({
    ...state,
    passwordUpdateError: action.payload,
  }),
  [forgotPasswordSuccess.type]: (state: AccountState) => ({
    ...state,
    isTokenSent: true,
  }),
  [resetPasswordSuccess.type]: (state: AccountState) => ({
    ...state,
    isPasswordResetSend: true,
  }),
})

export default accountReducer;
