import type { Deal } from 'types/Market';
import { Link } from 'react-router-dom';

import { MarketCard } from 'components/common';

import { useAppSelector } from 'hooks';
import { selectMarketDeals } from 'store/market/selectors';
import { useMemo } from 'react';

interface MoreDetailsProps {
    deal: Deal;
}

function MoreDetails({ deal }: MoreDetailsProps) {
    const deals = useAppSelector(selectMarketDeals);

    const dealsFiltered = useMemo(() => {
        return deals.filter((d) => d.id !== deal.id).slice(0, 3);
    }, [deals, deal]);

    return (
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {dealsFiltered.map((deal) => (
                <Link key={deal.id} to={`/deal/${deal.id}`}>
                    <MarketCard
                        key={deal.id}
                        name={deal.name}
                        featuredDescription={deal.featured_description}
                        invested={deal.invested > 0}
                        raise={deal.raise}
                        interval={deal.interval}
                        featuredImage={deal.featured_image}
                        sold
                    />
                </Link>
            ))}
        </div>
    );
}

export default MoreDetails;
