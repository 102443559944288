import type { IChartistLineChart } from 'chartist';
import React, { useEffect, useRef, useState } from 'react';
import Chartist from 'chartist';
import cx from 'classnames';
import { compactNumber } from 'utils';

import { ReactComponent as HexagonFillSrc } from 'assets/svg/hexagon-fill.svg';
import { TooltipPlugin, CustomVerticalLabelsPlugins, CustomVerticalLinesPlugin } from '../plugins';

interface Seria {
    name: string;
    data: number[];
}

interface LineChartProps {
    title?: string;
    height?: string;
    width?: string;
    containerClassName?: string;
    showLegend?: boolean;
    labels: string[];
    series: Seria[];
    ticks: number[];
}

function LineChart({ title, height, width, containerClassName, showLegend, labels, series, ticks }: LineChartProps) {
    const [, setChart] = useState<IChartistLineChart | null>(null);
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chartInstance = new Chartist.Line(
            chartRef.current,
            {
                labels,
                series: series.map((seria: Seria, index: number) => {
                    return seria.data.map((value: number) => ({
                        value,
                        className: `chart-color-${index} text-sm`,
                    }));
                }),
            },
            {
                fullWidth: true,
                height,
                width,
                axisY: {
                    type: Chartist.FixedScaleAxis,
                    divisor: ticks && ticks[0] && ticks[1] ? ticks[1] - ticks[0] : 1000,
                    low: Math.min(...ticks) || 0,
                    high: Math.max(...ticks) || 10000,
                    ticks,
                    labelInterpolationFnc: compactNumber,
                },
                chartPadding: {
                    right: 40,
                },
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0,
                }),
                plugins: [TooltipPlugin(), CustomVerticalLabelsPlugins(), CustomVerticalLinesPlugin()],
            }
        );
        setChart(chartInstance);
    }, [labels]);

    return (
        <div className={containerClassName}>
            {title && (
                <div
                    className={cx(
                        'my-4',
                        'md:my-6',
                        'text-center',
                        'text-[18px]',
                        'text-black',
                        'font-frankRuhlLibre',
                        'font-bold'
                    )}
                >
                    {title}
                </div>
            )}
            <div className="relative" ref={chartRef} />
            {showLegend && (
                <div className="flex items-center my-8 md:my-16">
                    {series.map((seria: Seria, index: number) => (
                        <div className={`chart-color-${index + 1} flex items-center ml-3`} key={index}>
                            <HexagonFillSrc width="16px" height="16px" />
                            <span className="ml-3 font-bold text-[12px] text-gray font-segoe uppercase">
                                {seria.name}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

LineChart.defaultProps = {
    title: '',
    containerClassName: '',
    height: '250px',
    width: '100%',
    showLegend: true,
    ticks: [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
};

export default LineChart;
