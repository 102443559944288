import { RootState } from 'store';

export const selectMarketDeals = (state: RootState) => state.market.deals;
export const selectMarketDealsLoading = (state: RootState) => state.market.dealsLoading;
export const selectMarketDealsTotal = (state: RootState) => state.market.dealsTotal;
export const selectMarketDealsPerPage = (state: RootState) => state.market.dealsPerPage;
export const selectMarketDealsPage = (state: RootState) => state.market.dealsPage;
export const selectMarketDeal = (state: RootState) => state.market.deal;
export const selectMarketDealLoading = (state: RootState) => state.market.dealLoading;
export const selectMarketFundDetails = (state: RootState) => state.market.fundDetail;
export const selectMarketInvestNow = (state: RootState) => state.market.investNow;
export const selectMarketDealSubscription = (state: RootState) => state.market.dealSubscription;
