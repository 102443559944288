import React from 'react';
import cx from 'classnames';

interface ButtonProps {
    className?: string;
    polygonBg?: string;
    onClick?: () => void;
}

const PolyButton = ({ className, polygonBg, onClick }: ButtonProps) => (
    <div className={cx('cursor-pointer w-20 h-20 relative', className)} onClick={onClick}>
        <img src={polygonBg} />
    </div>
);

PolyButton.defaultProps = {
    className: '',
    polygonBg: '',
    onClick: () => {
        //
    },
};

export default PolyButton;
