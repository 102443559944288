import type { InvestorPersonalInfo } from 'types/Kyc';
import type { SelectOption } from 'components/common/Select';
import { useMemo } from 'react';
import { Country, State } from 'country-state-city';
import { useFormContext } from 'react-hook-form';

import { Input, Select } from 'components/common';

const MailAddressInfo = () => {
    const { register, setValue, watch } = useFormContext<InvestorPersonalInfo>();

    const country = watch('country');
    const state = watch('state');

    const countries = useMemo(() => {
        return Country.getAllCountries().map((c) => ({
            label: c.name,
            value: c.isoCode,
        }));
    }, []);

    const states = useMemo((): SelectOption[] => {
        if (!country) return [];
        else {
            return State.getStatesOfCountry(country).map((state) => ({
                label: state.name,
                value: state.isoCode,
            }));
        }
    }, [country]);

    return (
        <div>
            <div className="mt-10">
                <Input label="Address Line 1" {...register('mail_address')} fullWidth />
            </div>
            <div className="mt-10">
                <Input label="Address Line 2" {...register('m_address2')} fullWidth />
            </div>
            <div className="grid grid-cols-[1fr_0.6fr_0.7fr] gap-10 mt-10">
                <Input label="City" {...register('mail_city')} fullWidth />
                <Select
                    label="State"
                    options={states}
                    selectedDefault={state}
                    onSelect={(option: SelectOption) => setValue('mail_state', option.value)}
                />
                <Input label="Zip Code" {...register('mail_zipcode')} fullWidth />
            </div>
            <div className="mt-10">
                <Select
                    label="Country"
                    options={countries}
                    onSelect={(option: SelectOption) => setValue('mail_country', option.value)}
                    dropdownClassName="w-full"
                />
            </div>
        </div>
    );
};

export default MailAddressInfo;
