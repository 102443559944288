import type { SubmitHandler } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';

import { Button, Input } from 'components/common';
import { getPasswordConfirmValidationOptions, getPasswordValidationOptions } from 'utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
    selectIsPasswordResetSend,
    selectPasswordUpdateError,
    selectUpdatePasswordLoading,
} from 'store/account/selectors';
import { resetPassword } from 'store/account/actions';

interface ResetPasswordFormSchema {
    password: string;
    passwordConfirm: string;
}

const ResetPassword = () => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordFormSchema>();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [token, setToken] = useState('');

    const updatePasswordLoading = useAppSelector(selectUpdatePasswordLoading);
    const isPasswordResetSend = useAppSelector(selectIsPasswordResetSend);
    const passwordUpdateError = useAppSelector(selectPasswordUpdateError);

    const password = useRef<string>('');
    password.current = watch('password', '');

    const passwordInputProps = register('password', getPasswordValidationOptions());
    const passwordConfirmInputProps = register(
        'passwordConfirm',
        getPasswordConfirmValidationOptions(password.current)
    );

    const onSubmit: SubmitHandler<ResetPasswordFormSchema> = useCallback(
        async (data) => {
            dispatch(
                resetPassword({
                    password: data.password,
                    token,
                })
            );
        },
        [dispatch, token]
    );

    useEffect(() => {
        const searchParsed = queryString.parse(location?.search);
        if (searchParsed?.token) {
            setToken(searchParsed.token.toString());
            navigate(location.pathname);
        }
    }, [location]);

    useEffect(() => {
        if (isPasswordResetSend) {
            navigate('/login');
        }
    }, [isPasswordResetSend]);

    return (
        <div className="w-full flex-1 flex justify-center items-center bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-[right_0px_bottom_-50px] bg-contain sm:bg-[right_-300px_bottom_-300px]">
            <div className="container flex-1 flex justify-center items-center px-4">
                <form className="w-full sm:max-w-2xl" onSubmit={handleSubmit(onSubmit)}>
                    {passwordUpdateError && (
                        <div className="text-red-600 text-center mb-4 text-lg">{passwordUpdateError}</div>
                    )}
                    <Input
                        containerClassName="mb-4"
                        label="Password"
                        type="password"
                        error={errors?.password?.message}
                        {...passwordInputProps}
                        fullWidth
                    />
                    <Input
                        containerClassName="mb-4"
                        label="Password confirm"
                        type="password"
                        error={errors?.passwordConfirm?.message}
                        {...passwordConfirmInputProps}
                        fullWidth
                    />
                    <Button
                        type="submit"
                        className="mx-auto mb-8 mt-10 uppercase w-64"
                        isLoading={updatePasswordLoading}
                        disabled={updatePasswordLoading}
                    >
                        Update Password
                    </Button>
                    {isPasswordResetSend && (
                        <div className="text-xl text-center text-green-700">Your password has been updated</div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
