import type { Table } from 'types/Tables';
import { useCallback } from 'react';

// Assets
import { ReactComponent as BucketIcon } from 'assets/svg/bucket.svg';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import { setInvestNow } from 'store/market/actions';
import { selectMarketInvestNow } from 'store/market/selectors';

// Utils
import formatToCurrencyString from 'utils/helpers/formatToCurrencyString';

interface DealInformationProps {
    name: string;
    invested: number;
    description: string;
    featuredImage?: string;
    headerTable: Table[];
}

const DealInformation = ({ name, invested, description, featuredImage, headerTable }: DealInformationProps) => {
    const dispatch = useAppDispatch();

    const investNow = useAppSelector(selectMarketInvestNow);

    const changeInvestNow = useCallback(() => {
        dispatch(setInvestNow(!investNow));
    }, [dispatch, investNow]);

    return (
        <div className="container grid grid-cols-1 lg:grid-cols-[1fr_450px] xl:grid-cols-[1fr_580px] mt-16 lg:mt-24 mx-auto">
            <div className="h-full">
                <div className="w-full h-full max-h-[505px] flex justify-center items-center bg-gray">
                    {featuredImage && (
                        <img className="mr-0 w-full h-full object-cover object-center" src={featuredImage} alt="" />
                    )}
                    {!featuredImage && (
                        <div className="mb-10 text-2xl font-segoe font-semibold text-light-gray">No Image</div>
                    )}
                </div>
            </div>
            <div className="h-full flex flex-col justify-between font-frankRuhlLibre">
                <div>
                    <div className="bg-black text-white text-base xl:text-xl font-frankRuhlLibre px-4 py-3 xl:py-5">
                        {name}
                    </div>
                    <div className="bg-light-gray flex flex-col">
                        {headerTable.map((item, index) => {
                            if (index === 0) {
                                return (
                                    <div
                                        className="bg-primary text-black text-4xl font-frankRuhlLibre font-medium px-4 py-3 flex justify-between items-center"
                                        key={index}
                                    >
                                        <div className="text-base xl:text-lg mt-1">{item.name}</div>
                                        <div className="text-base sm:text-lg xl:text-xl leading-none font-bold font-segoe">
                                            {item.value}
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className="text-black text-base leading-8 font-semibold py-2 xl:py-3 flex justify-between items-center px-5"
                                    key={index}
                                >
                                    <div>{item.name}</div>
                                    <div className="text-lg font-segoe font-semibold">{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="p-5 text-sm font-segoe">{description}</div>
                </div>
                <div className="flex justify-center items-center px-3 mt-2">
                    <div className="w-full xl:w-9/12 max-w-xs flex justify-between items-center">
                        <div className="w-full relative cursor-pointer" onClick={changeInvestNow}>
                            <div className="hexagon absolute">
                                <div className="float-left border-solid border-r-[20px] md:border-r-[22px] border-r-secondary border-y-[30px] md:border-y-[35px] border-y-transparent"></div>
                                <div className="float-left w-[34px] md:w-[42px] h-[60px] md:h-[70px] bg-secondary flex items-center">
                                    <BucketIcon className="h-6 md:h-7" />
                                </div>
                                <div className="float-left border-solid border-l-[20px] md:border-l-[22px] border-l-secondary border-y-[30px] md:border-y-[35px] border-y-transparent"></div>
                            </div>
                            <div className="flex pl-[60px] md:pl-[70px]">
                                <div className="border-solid border-[30px] md:border-[35px] border-primary h-[60px] md:h-[70px] border-l-transparent border-l-[20px] md:border-l-[22px]"></div>
                                <div className="w-full h-[60px] md:h-[70px] bg-primary text-center text-md sm:text-base md:text-lg font-bold font-segoe flex justify-center items-center pr-[50px]">
                                    INVEST NOW
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DealInformation;
