import { useMemo } from 'react';
import cx from 'classnames';

import { Button } from 'components/common';
import { useAppSelector } from 'hooks';
import { selectKycStep, selectKycStepsLength } from 'store/kyc/selectors';

interface StepsPaginationProps {
    loading?: boolean;
    disabled?: boolean;
    goBack?: () => void;
    onSubmit?: () => void;
}

const StepsPagination = ({ loading, disabled, goBack, onSubmit }: StepsPaginationProps) => {
    const step = useAppSelector(selectKycStep);
    const stepsLength = useAppSelector(selectKycStepsLength);

    const steps = useMemo(() => {
        const steps = [];
        for (let i = 0; i < stepsLength; i++) {
            steps.push(i <= step);
        }
        return steps;
    }, [step, stepsLength]);

    return (
        <div className="mt-20 px-2.5">
            <div className="flex w-full justify-between mb-5 lg:hidden">
                <Button
                    type="button"
                    variant="link"
                    onClick={goBack}
                    className="bg-gray hover:bg-gray"
                    disabled={loading}
                >
                    <span className="text-black">PREVIOUS</span>
                </Button>
                <Button type="submit" onClick={onSubmit} isLoading={loading} disabled={disabled}>
                    {step < stepsLength - 1 && 'NEXT'}
                    {step >= stepsLength - 1 && 'SUBMIT'}
                </Button>
            </div>
            <div className="block lg:grid grid-cols-[max-content_1fr_max-content] gap-16 items-center">
                <Button
                    type="button"
                    variant="link"
                    onClick={goBack}
                    className="bg-gray hover:bg-gray hidden lg:block"
                    disabled={loading}
                >
                    <span className="text-black">PREVIOUS</span>
                </Button>
                <div className="grid grid-cols-5 gap-2 w-full">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={cx('w-full h-2.5', {
                                'bg-gray': !step,
                                'bg-primary': step,
                            })}
                        />
                    ))}
                </div>
                <Button
                    type="submit"
                    onClick={onSubmit}
                    isLoading={loading}
                    disabled={disabled}
                    className="hidden lg:block"
                >
                    {step < stepsLength - 1 && 'NEXT'}
                    {step >= stepsLength - 1 && 'SUBMIT'}
                </Button>
            </div>
        </div>
    );
};

export default StepsPagination;
