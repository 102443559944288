import type { HTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';

interface FormHeaderProps extends HTMLAttributes<HTMLHeadElement> {
    title?: string;
    className?: string;
}

const FormHeader = ({ title, children, className, ...otherProps }: PropsWithChildren<FormHeaderProps>) => (
    <h3
        className={cx('text-base font-bold font-frankRuhlLibre bg-link text-white-desc p-4', className)}
        {...otherProps}
    >
        {title || children}
    </h3>
);

export default FormHeader;
