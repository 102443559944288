import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { ERRORS } from 'utils/constants';

export const showErrorToast = (text: string) => {
  toast.error(text, {
    position: toast.POSITION.TOP_CENTER,
    className: 'text-red-700',
    autoClose: false,
  });
}

export const showError = (error: AxiosError) => {
  if (error.response?.data.code === 'DUPLICATE') {
    showErrorToast('User with this name or email already exist');
  } else if (error.response?.data.message) {
    showErrorToast(error.response?.data.message);
  } else {
    showErrorToast(ERRORS.DEFAULT);
  }
}

export const removeAllToast = () => {
  toast.dismiss();
}
