import type { AxiosError, AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { UpdateAccountPasswordParams } from 'api';
import type UserMetadata from 'types/UserMetadata';
import type AccountProfile from 'types/AccountProfile';
import type AccountNotifications from 'types/AccountNotifications';
import type { ResetPasswordParams } from 'types/ResetPassword';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'api';
import * as accountActions from './actions';
import { kycActions } from 'store/kyc';
import { logout } from 'store/auth/actions';
import { showError } from 'utils/helpers/showToast';
import { plaidActions } from 'store/plaid';
import { store } from 'store';

function* getAccountProfiles(action: PayloadAction<string>) {
  try {
    yield put(accountActions.setLoading(true));
    const res: AxiosResponse<{ objects: AccountProfile[] }> = yield call(api.getAccountProfiles, action.payload);
    yield put(accountActions.updateAccountProfiles(res.data?.objects));
    yield put(accountActions.getAccountMetadata());
    yield put(accountActions.getAccountNotifications());
    yield put(accountActions.setLoading(false));
  }
  catch(error) {
    yield put(accountActions.setLoading(false));
  }
}

function* getAccountMetadata() {
  try {
    yield put(accountActions.setLoading(true));
    const res: AxiosResponse<UserMetadata> =
      yield call(api.getAccountMetadata);
    if (res.data.sudrania_id) {
      yield put(kycActions.getInvestor(res.data.sudrania_id));
    }
    if (res.data.plaid_id) {
      yield put(plaidActions.setAccessToken(res.data.plaid_id));
    }
    yield put(accountActions.updateAccountMetaData(res.data));
    yield put(accountActions.setLoading(false));
    // yield put(accountActions.putAccountMetadata({
    //   sudrania_id: '',
    //   plaid_id: '',
    // }));
  }
  catch(error) {
    yield put(accountActions.setLoading(false));
  }
}

function* putAccountMetadata(action: PayloadAction<UserMetadata>) {
  try {
    const state = store.getState();
    const metadata = {
      ...state.account.metadata,
      ...action.payload,
    };
    yield put(accountActions.setUpdateMetadataLoading(true));
    yield call(api.updateAccountMetadata, metadata);
    yield put(accountActions.updateAccountMetaData(metadata));
    yield put(accountActions.setUpdateMetadataLoading(false));
  }
  catch (error) {
    yield put(accountActions.setUpdateMetadataLoading(false));
    showError(error as AxiosError);
  }
}

function* getAccountNotifications() {
  try {
    yield put(accountActions.setLoading(true));
    const res: AxiosResponse<AccountNotifications> = yield call(api.getAccountNotifications);
    yield put(accountActions.setAccountNotifications(res.data));
    yield put(accountActions.setLoading(false));
  }
  catch (error) {
    yield put(accountActions.setLoading(false));
  }
}

function* updateAccountNotifications(action: PayloadAction<AccountNotifications>) {
  try {
    yield put(accountActions.setUpdateNotificationsLoading(true));
    yield call(api.updateAccountNotifications, action.payload);
    yield put(accountActions.setAccountNotifications(action.payload));
    yield put(accountActions.setUpdateNotificationsLoading(false));
  }
  catch (error) {
    yield put(accountActions.setUpdateNotificationsLoading(false));
    showError(error as AxiosError);
  }
}

function* updateAccountPassword(action: PayloadAction<UpdateAccountPasswordParams>) {
  try {
    yield put(accountActions.setUpdatePasswordLoading(true));
    yield call(api.updateAccountPassword, action.payload);
    yield put(accountActions.setUpdatePasswordLoading(false));
    yield put(logout());
  }
  catch (error) {
    yield put(accountActions.setPasswordUpdateError('There was an error updating the password'));
    yield put(accountActions.setUpdatePasswordLoading(false));
    showError(error as AxiosError);
  }
}

function* forgotPassword(action: PayloadAction<string>) {
  try {
    yield put(accountActions.setUpdatePasswordLoading(true));
    yield call(api.forgotPassword, action.payload);
    yield put(accountActions.forgotPasswordSuccess());
    yield put(accountActions.setUpdatePasswordLoading(false));
  }
  catch (error) {
    yield put(accountActions.setUpdatePasswordLoading(false));
    showError(error as AxiosError);
  }
}

function* resetPassword(action: PayloadAction<ResetPasswordParams>) {
  try {
    yield put(accountActions.setUpdatePasswordLoading(true));
    yield call(api.resetPassword, action.payload);
    yield put(accountActions.resetPasswordSuccess());
    yield put(accountActions.setUpdatePasswordLoading(false));
  }
  catch (error) {
    yield put(accountActions.setPasswordUpdateError('There was an error updating the password'));
    yield put(accountActions.setUpdatePasswordLoading(false));
    showError(error as AxiosError);
  }
}

function* accountSaga() {
  yield all([
    takeLatest(accountActions.getAccountMetadata.type, getAccountMetadata),
    takeLatest(accountActions.getAccountProfiles.type, getAccountProfiles),
    takeLatest(accountActions.putAccountMetadata.type, putAccountMetadata),
    takeLatest(accountActions.getAccountNotifications.type, getAccountNotifications),
    takeLatest(accountActions.updateAccountNotifications.type, updateAccountNotifications),
    takeLatest(accountActions.updateAccountPassword.type, updateAccountPassword),
    takeLatest(accountActions.forgotPassword.type, forgotPassword),
    takeLatest(accountActions.resetPassword.type, resetPassword),
  ]);
}

export default accountSaga;
