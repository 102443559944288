import { SVGAttributes } from 'react';

const Spinner = (props: SVGAttributes<SVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={35}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{
            display: 'block',
            backgroundColor: 'transparent',
            animationPlayState: 'paused',
        }}
        {...props}
    >
        <circle
            cx={50}
            cy={50}
            r={32}
            strokeWidth={8}
            strokeDasharray="50.26548245743669 50.26548245743669"
            className="stroke-gray"
            fill="none"
            strokeLinecap="round"
            style={{
                transform: 'matrix(1,0,0,1,0,0)',
                animationPlayState: 'paused',
            }}
        />
    </svg>
);

export default Spinner;
