import type { FC, ReactNode } from 'react';
import React from 'react';
import { Tab } from '@headlessui/react';

interface TabPanelProps {
    key: string | number;
    children?: ReactNode;
    label?: string;
}

const TabPanel: FC<TabPanelProps> = ({ key, label, children, ...otherProps }) => (
    <Tab.Panel key={key} {...otherProps}>
        {label || children}
    </Tab.Panel>
);

TabPanel.defaultProps = {
    label: '',
    children: '',
};

export default TabPanel;
