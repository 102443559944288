import type { AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Document } from 'types/Documents';
import type { SudraniaResponse } from 'types/Sudrania';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as api from 'api';
import * as documentsActions from './actions';

function* getDocuments(action: PayloadAction<string>) {
  try {
    yield put(documentsActions.setDocumentsLoading(true));
    const res: AxiosResponse<SudraniaResponse<Document[]>> =
      yield call(api.getInvestorDocuments, action.payload);
    yield put(documentsActions.setDocuments(res.data.data || []));
    yield put(documentsActions.setDocumentsLoading(false));
  } catch (error) {
    yield put(documentsActions.setDocumentsLoading(false));
  }
}

function* documentsSaga() {
  yield all([
    takeEvery(documentsActions.getDocuments.type, getDocuments),
  ]);
}

export default documentsSaga;
