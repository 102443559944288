import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import { getDeal } from 'store/market/actions';
import { getPortfolio } from 'store/portfolio/actions';
import { selectMarketDeal, selectMarketDealLoading } from 'store/market/selectors';
import { selectAccountLoading, selectAccountMetadata } from 'store/account/selectors';
import { selectPortfolioLoading } from 'store/portfolio/selectors';

// Components
import { Spinner } from 'components/common';
import DealInformation from './Information';
import TabSections from './TabSections';
import MoreDetails from './MoreDetails';
import DealInvest from './Invest';

const Deal = () => {
    const dispatch = useAppDispatch();
    const params = useParams();

    const deal = useAppSelector(selectMarketDeal);
    const dealLoading = useAppSelector(selectMarketDealLoading);
    const metadata = useAppSelector(selectAccountMetadata);
    const metadataLoading = useAppSelector(selectAccountLoading);
    const portfolioLoading = useAppSelector(selectPortfolioLoading);

    useEffect(() => {
        if (params.dealId) {
            dispatch(getDeal(params.dealId));
        }
    }, [params, metadata?.sudrania_id]);

    useEffect(() => {
        if (metadata?.sudrania_id) {
            dispatch(getPortfolio(metadata?.sudrania_id));
        }
    }, [metadata]);

    return (
        <div className="w-full h-full px-0 lg:px-6 xl:px-12 py-0 pb-32 lg:py-12 bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-[right_0px_bottom_-300px] bg-contain">
            {(dealLoading || metadataLoading || portfolioLoading) && (
                <div className="min-h-screen">
                    <div className="flex justify-center items-center mt-20">
                        <Spinner className="w-20 h-20" />
                    </div>
                </div>
            )}
            {!dealLoading && !metadataLoading && !portfolioLoading && deal && (
                <>
                    <DealInformation
                        name={deal.name}
                        description={deal.short_description}
                        invested={deal.invested}
                        featuredImage={deal.featured_image}
                        headerTable={deal.header_table}
                    />
                    <DealInvest />
                    <TabSections
                        dealGallery={deal?.gallery || []}
                        summary={deal.summary}
                        terms={deal?.terms || []}
                        financials={deal?.financials || []}
                        pieChart={deal?.pie_chart || []}
                    />
                    <MoreDetails deal={deal} />
                </>
            )}
        </div>
    );
};

export default Deal;
