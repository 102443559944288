import { Routes, Route } from 'react-router-dom';

import {
    Home,
    Deal,
    Market,
    Portfolio,
    Signup,
    Login,
    Account,
    ForgotPassword,
    ResetPassword,
    HowItWorks,
} from 'pages';
import NotLoggedRoute from './NotLoggedRoute';
import ProtectedRoute from './ProtectedRoute';
import Kyc from 'pages/Kyc';
import Document from 'pages/Documents';
import HomeAlt from 'pages/HomeAlt';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomeAlt />} />
            <Route path="/home-alt" element={<Home />} />
            <Route path="/deal/:dealId" element={<Deal />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/documents" element={<Document />} />
            <Route
                path="/market"
                element={
                    <ProtectedRoute>
                        <Market />
                    </ProtectedRoute>
                }
            />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
                path="/account"
                element={
                    <ProtectedRoute>
                        <Account />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/kyc"
                element={
                    <ProtectedRoute>
                        <Kyc />
                    </ProtectedRoute>
                }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
                path="/signup"
                element={
                    <NotLoggedRoute>
                        <Signup />
                    </NotLoggedRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <NotLoggedRoute>
                        <Login />
                    </NotLoggedRoute>
                }
            />
        </Routes>
    );
}

export default AppRoutes;
