import { useCallback, useState, useMemo } from 'react';
import { selectMarketDeal } from 'store/market/selectors';
import { useNavigate } from 'react-router-dom';

// Api
import { addSubscription, sendSubscriptionAgreement } from 'api';

// Hooks
import { useAppSelector } from 'hooks';

// Constants and Enums
import {
    AddSubscriptionResponse,
    SubscriptionsTrackerResponse,
    SubscriptionType,
    SudraniaResponse,
} from 'types/Sudrania';

// Store
import { selectKycInvestorDetails } from 'store/kyc/selectors';

// Components
import { Button } from 'components/common';
import { selectAccountMetadata } from 'store/account/selectors';
import { AxiosResponse } from 'axios';

interface SubmittedProps {
    investAmount: number;
    loadSubscriptions: () => Promise<SudraniaResponse<SubscriptionsTrackerResponse[]> | null>;
}

const Submitted = ({ investAmount, loadSubscriptions }: SubmittedProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const investorDetails = useAppSelector(selectKycInvestorDetails);
    const deal = useAppSelector(selectMarketDeal);
    const metadata = useAppSelector(selectAccountMetadata);

    const minimumIntervalInvestment = useMemo(() => deal?.minimum_interval || 0, [deal]);

    const onSuccess = useCallback(() => {
        navigate('/documents');
        setLoading(false);
    }, []);

    const sendAgreement = useCallback(async () => {
        try {
            if (deal?.sudrania_id && metadata.sudrania_id) {
                setLoading(true);
                let subscription: string | undefined;
                const subscriptions = await loadSubscriptions();
                const status = subscriptions?.data?.[0]?.subscription_application.process_status;
                if (status === 'Submitted') {
                    onSuccess();
                    return;
                }
                subscription = subscriptions?.data?.[0]?.subscription_sr_no;
                if (!subscription) {
                    const subscriptionRes: AxiosResponse<SudraniaResponse<AddSubscriptionResponse>> =
                        await addSubscription({
                            investor_profile: metadata.sudrania_id,
                            fund: deal.sudrania_id,
                            asset_type: 'A',
                            subscription_type: SubscriptionType.INITIAL,
                            amount: `${investAmount * minimumIntervalInvestment}`,
                        });
                    subscription = subscriptionRes.data.data.id;
                }
                if (subscription) {
                    const agreementRes = await sendSubscriptionAgreement(subscription);
                    const signWindow = window.open(agreementRes.data.document_url, '_blank', 'left=-1000,top=-1000');
                    const timeoutFn = async () => {
                        if (signWindow?.closed) {
                            const subscriptions = await loadSubscriptions();
                            const status =
                                subscriptions?.data?.[0]?.subscription_agreement_signed_investor?.process_status;
                            if (status === 'Signed' || status === 'Resigned') {
                                onSuccess();
                            } else {
                                setLoading(false);
                            }
                        } else {
                            setTimeout(timeoutFn, 1000);
                        }
                    };
                    setTimeout(timeoutFn, 1000);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    }, [investorDetails, deal, minimumIntervalInvestment]);

    return (
        <>
            <div className="mb-8 text-desc">To complete your investment, you must review and sign documents.</div>
            <Button onClick={sendAgreement} className="mx-auto" isLoading={loading}>
                Open Documents
            </Button>
        </>
    );
};

export default Submitted;
