import HomeHero from './Hero';
import Invest from './Invest';
import Ad from './Ad';

function Home() {
    return (
        <div className="w-full">
            <HomeHero />
            <Invest />
            <Ad />
        </div>
    );
}

export default Home;
