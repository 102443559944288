import { ForwardedRef, forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import cx from 'classnames';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    containerClassName?: string;
    forwardRef?: ForwardedRef<HTMLInputElement>;
    fullWidth?: boolean;
    label?: string;
    error?: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (props, ref) => {
  const {
    id,
    className,
    containerClassName,
    fullWidth,
    label,
    error,
    ...otherProps
  } = props;
  return (
    <div
      className={cx(
        {
          'w-72': !fullWidth,
          'w-full': fullWidth,
        },
        containerClassName
      )}
    >
      {label && (
        <div className="mb-2.5 h-6">
          <label htmlFor={id} className="text-xs font-segoe font-bold">
            {label}
          </label>
        </div>
      )}
      <input
        id={id}
        className={cx('w-full border-3 border-gray border-solid bg-white h-10 outline-0 px-3', className)}
        ref={ref}
        {...otherProps}
      />
      {error && <div className="text-red-400">{error}</div>}
    </div>
  );
}

export default forwardRef<HTMLInputElement, InputProps>(Input);
