import React from 'react';

import hexNet from 'assets/images/hex-net.png';

const Invest = () => (
    <div className="relative bg-black h-44 lg:h-60 overflow-hidden p-2.5 sm:p-12">
        <img className="absolute -left-28 md:-left-0 top-0 h-full" src={hexNet} alt="" />
        <div className="container h-full flex justify-end items-center">
            <div className="w-4/6">
                <div className="text-primary text-xs md:text-lg lg:text-xl text-center font-frankRuhlLibre px-0 xl:px-20">
                    We make investing in functional real estate easier than ever. That means you get access to the best
                    properties and mining facilities that have tax benefits, income and appreciation.
                </div>
                <div className="mt-5 text-white-desc text-center text-xxs font-segoe font-extralight sm:text-base lg:mt-10">
                    Invest today in as little as 5 minutes
                </div>
            </div>
        </div>
    </div>
);

export default Invest;
