import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AccreditedInvestorRepresentation, QqIiiAEnt, QqIiiAInv, QqIiiAIra, QqIiiATrust } from 'types/Kyc';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Utils
import { QQ_III_A_ENT, QQ_III_A_INV, QQ_III_A_IRA, QQ_III_A_TRUST } from 'utils';
import accreditedInvestorRepresentationSchema from 'utils/schemas/kyc/accreditedInvestorRepresentation';

// Store
import { selectKycInvestorDetails, selectKycLoading, selectKycStep } from 'store/kyc/selectors';
import { setKycStep, updateInvestor } from 'store/kyc/actions';

// Components
import { FormHeader, RadioGroup } from 'components/common';
import StepsPagination from '../StepsPagination';
import { selectAccountMetadata } from 'store/account/selectors';

const Step3 = () => {
    const dispatch = useAppDispatch();

    const { setValue, getFieldState, handleSubmit } = useForm<AccreditedInvestorRepresentation>({
        resolver: yupResolver(accreditedInvestorRepresentationSchema),
    });

    const investorDetails = useAppSelector(selectKycInvestorDetails);
    const metadata = useAppSelector(selectAccountMetadata);
    const step = useAppSelector(selectKycStep);
    const loading = useAppSelector(selectKycLoading);

    const goBack = useCallback(() => {
        dispatch(setKycStep(step - 1));
    }, [step]);

    const submit = useCallback(
        (data: AccreditedInvestorRepresentation) => {
            if (metadata.sudrania_id) {
                dispatch(
                    updateInvestor({
                        investor_sr_no: metadata.sudrania_id,
                        data,
                    })
                );
            }
        },
        [metadata]
    );

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormHeader title="3. Accredited Investor Representation" />
            <div className="px-3 mt-7">
                {(investorDetails?.investor_type === 'IND' ||
                    (investorDetails?.investor_type !== 'IRA' &&
                        investorDetails?.investor_type !== 'TRUST' &&
                        investorDetails?.investor_type !== 'ENT')) && (
                    <RadioGroup
                        label="Accredited Investor Representation for IND"
                        items={QQ_III_A_INV}
                        // eslint-disable-next-line
                        // @ts-ignore
                        onSelect={(item) => setValue('qq_iii_a_inv', [item.value] as QqIiiAInv[])}
                    />
                )}
                {investorDetails?.investor_type === 'IRA' && (
                    <RadioGroup
                        label="Accredited Investor Representation for IRA"
                        items={QQ_III_A_IRA}
                        onSelect={(item) => setValue('qq_iii_a_ira', [item.value] as QqIiiAIra[])}
                    />
                )}
                {investorDetails?.investor_type === 'ENT' && (
                    <RadioGroup
                        label="Accredited Investor Representation for ENT"
                        items={QQ_III_A_ENT}
                        onSelect={(item) => setValue('qq_iii_a_ent', [item.value] as QqIiiAEnt[])}
                    />
                )}
                {investorDetails?.investor_type === 'TRUST' && (
                    <RadioGroup
                        label="Accredited Investor Representation for TRUST"
                        items={QQ_III_A_TRUST}
                        onSelect={(item) => setValue('qq_iii_a_trust', [item.value] as QqIiiATrust[])}
                    />
                )}
            </div>
            <StepsPagination goBack={goBack} loading={loading} />
        </form>
    );
};

export default Step3;
