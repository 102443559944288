import type { DealGallery } from 'types/Market';
import type { Table } from 'types/Tables';
import { Tabs } from 'components/common';
import Summary from './Summary';
import Financials from './Financials';
import Terms from './Terms';

interface TabSections {
    dealGallery: DealGallery[];
}

interface TabSectionsProps {
    dealGallery: DealGallery[];
    summary: string;
    financials: Table[];
    terms: Table[];
    pieChart: Table[];
}

const TabSections = ({ summary, financials, terms, pieChart }: TabSectionsProps) => {
    return (
        <div className="container mx-auto mt-10 pb-8">
            <Tabs>
                <Tabs.List className="px-1 gap-2">
                    <Tabs.Tab key="1">
                        <div className="text-base truncate font-segoe font-semibold text-xs flex justify-center items-center h-8 md:h-auto">
                            SUMMARY
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab key="2">
                        <div className="text-base font-segoe font-semibold text-xs flex justify-center items-center h-8 md:h-auto">
                            PERFORMANCE <br className="md:hidden" />
                            HISTORY
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab key="3">
                        <div className="text-base truncate font-segoe font-semibold text-xs flex justify-center items-center h-8 md:h-auto">
                            TERMS
                        </div>
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panels>
                    <Tabs.Panel key="1">
                        <Summary summary={summary} />
                    </Tabs.Panel>
                    <Tabs.Panel key="2">
                        <Financials tables={financials} pieChart={pieChart} />
                    </Tabs.Panel>
                    <Tabs.Panel key="3">
                        <Terms tables={terms} />
                    </Tabs.Panel>
                </Tabs.Panels>
            </Tabs>
        </div>
    );
};

export default TabSections;
