import type { LoginParams, SignupParams } from 'api';
import type { HubspotSignupParams } from 'types/Hubspot';
import type User from 'types/User';
import type UserMetadata from 'types/UserMetadata';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'auth';

// Session
export const updateSessionSecret = createAction<string>(`${PREFIX}/updateSessionSecret`);

// User
export const signup = createAction<SignupParams>(`${PREFIX}/signup`);
export const login = createAction<LoginParams>(`${PREFIX}/login`);
export const logout = createAction(`${PREFIX}/logout`);
export const setAppLoading = createAction<boolean>(`${PREFIX}/setAppLoading`);
export const getCurrentUser = createAction(`${PREFIX}/getCurrentUser`);
export const updateUser = createAction<User>(`${PREFIX}/updateUser`);
export const getUserMetadata = createAction(`${PREFIX}/getUserMetadata`);
export const updateUserMetaData = createAction<UserMetadata>(`${PREFIX}/updateUserMetaData`);
export const setAuthLoading = createAction<boolean>(`${PREFIX}/setAuthLoading`);
export const hubspotSignup = createAction<HubspotSignupParams>(`${PREFIX}/hubspotSignup`);
export const setHubspotError = createAction<string | null>(`${PREFIX}/setHubspotError`);
export const setHubspotSuccess = createAction<boolean>(`${PREFIX}/setHubspotSuccess`);
