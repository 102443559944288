import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';

import { authPersistConfig, authReducer } from './auth';
import { corePersistConfig, coreReducer } from './core';
import { accountPersistConfig, accountReducer } from './account';
import { marketPersistConfig, marketReducer } from './market';
import { kycPersistConfig, kycReducer } from './kyc';
import { plaidPersistConfig, plaidReducer } from './plaid';
import { documentsPersistConfig, documentsReducer } from './documents';
import { portfolioPersistConfig, portfolioReducer } from './portfolio';

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    core: persistReducer(corePersistConfig, coreReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    account: persistReducer(accountPersistConfig, accountReducer),
    market: persistReducer(marketPersistConfig, marketReducer),
    kyc: persistReducer(kycPersistConfig, kycReducer),
    plaid: persistReducer(plaidPersistConfig, plaidReducer),
    documents: persistReducer(documentsPersistConfig, documentsReducer),
    portfolio: persistReducer(portfolioPersistConfig, portfolioReducer),
  },
  devTools: true,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
