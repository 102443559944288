import type { Deal } from 'types/Market';
import type { SubscriptionsTrackerResponse, SudraniaFundDetails } from 'types/Sudrania';
import { createReducer, type PayloadAction } from '@reduxjs/toolkit';

import {
  setDeal,
  setDealLoading,
  setDeals,
  setDealsLoading,
  setDealsPage,
  setDealsPerPage,
  setDealsTotal,
  setDealSubscription,
  setFundDetails,
  setInvestNow,
} from './actions';

export interface MarketState {
  deals: Deal[];
  deal: Deal | null;
  dealSubscription: SubscriptionsTrackerResponse | null,
  investNow: boolean;
  fundDetail: SudraniaFundDetails | null;
  dealLoading: boolean;
  dealsLoading: boolean;
  dealsPage: number;
  dealsPerPage: number;
  dealsTotal: number;
}

const initialState: MarketState = {
  deals: [],
  deal: null,
  dealSubscription: null,
  investNow: false,
  fundDetail: null,
  dealLoading: false,
  dealsLoading: false,
  dealsPage: 1,
  dealsPerPage: 4,
  dealsTotal: 0,
};

const marketReducer = createReducer(initialState, {
  [setDeals.type]: (state: MarketState, action: PayloadAction<Deal[]>) => ({
    ...state,
    deals: action.payload,
  }),
  [setDealsLoading.type]: (state: MarketState, action: PayloadAction<boolean>) => ({
    ...state,
    dealsLoading: action.payload,
  }),
  [setDealsTotal.type]: (state: MarketState, action: PayloadAction<number>) => ({
    ...state,
    dealsTotal: action.payload,
  }),
  [setDealsPage.type]: (state: MarketState, action: PayloadAction<number>) => ({
    ...state,
    dealsPage: action.payload,
  }),
  [setDealsPerPage.type]: (state: MarketState, action: PayloadAction<number>) => ({
    ...state,
    dealsPerPage: action.payload,
  }),
  [setDeal.type]: (state: MarketState, action: PayloadAction<Deal>) => ({
    ...state,
    deal: action.payload,
  }),
  [setFundDetails.type]: (state: MarketState, action: PayloadAction<SudraniaFundDetails>) => ({
    ...state,
    fundDetail: action.payload,
  }),
  [setDealLoading.type]: (state: MarketState, action: PayloadAction<boolean>) => ({
    ...state,
    dealLoading: action.payload,
  }),
  [setInvestNow.type]: (state: MarketState, action: PayloadAction<boolean>) => ({
    ...state,
    investNow: action.payload,
  }),
  [setDealSubscription.type]: (state: MarketState, action: PayloadAction<SubscriptionsTrackerResponse>) => ({
    ...state,
    dealSubscription: action.payload,
  }),
});

export default marketReducer;
