import SpinnerImage from 'assets/images/spinner.gif';

const LoadingStep = () => {
    return (
        <div className="flex justify-center items-center">
            <img width="75px" src={SpinnerImage} />
        </div>
    );
};

export default LoadingStep;
