import { Button } from 'components/common';

interface LeaveWarningModalProps {
    onCancel?: () => void;
    onAccept?: () => void;
}

const LeaveWarningModal = ({ onCancel, onAccept }: LeaveWarningModalProps) => {
    return (
        <div className="fixed top-1/4 left-1/2 px-3 max-w-full w-full -translate-x-1/2 lg:w-[600px] lg:max-w-[600px] lg:px-0">
            <div className="bg-white shadow-[0px_10px_10px_rgba(0,0,0,0.3)]">
                <div className="bg-secondary text-black text-center p-5 flex justify-center items-center font-frankRuhlLibre font-bold px-14">
                    You must submit the information requested in these forms for approval prior to being able to invest.
                </div>
                <div className="pb-11">
                    <div className="text-center mt-5 mb-8">Do you wish to leave the page?</div>
                    <div className="flex justify-center items-center">
                        <Button variant="link" className="bg-gray hover:bg-gray mr-5" onClick={onCancel}>
                            <span className="text-black">NO</span>
                        </Button>
                        <Button onClick={onAccept}>YES</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeaveWarningModal;
