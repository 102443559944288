import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { usePlaidLink } from 'react-plaid-link';

// Store
import { selectSessionUser } from 'store/auth/selectors';
import { selectLinkToken, selectPlaidLoading } from 'store/plaid/selectors';
import { selectMarketDeal } from 'store/market/selectors';
import { createLinkToken, exchangePublicToken } from 'store/plaid/actions';

// Utils
import formatToCurrencyString from 'utils/helpers/formatToCurrencyString';
import { showErrorToast } from 'utils/helpers/showToast';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Components
import { Button, Input } from 'components/common';

interface CompletedProps {
    investAmount: number;
    setInvestAmount: (value: number) => void;
}

const Completed = ({ investAmount, setInvestAmount }: CompletedProps) => {
    const dispatch = useAppDispatch();

    const deal = useAppSelector(selectMarketDeal);
    const user = useAppSelector(selectSessionUser);
    const linkToken = useAppSelector(selectLinkToken);
    const plaidLoading = useAppSelector(selectPlaidLoading);

    const { open, ready } = usePlaidLink({
        onSuccess: (public_token) => {
            dispatch(exchangePublicToken(public_token));
        },
        onExit: (err) => {
            if (err) {
                showErrorToast(err.error_message);
            }
        },
        token: linkToken,
    });

    const minimumIntervalInvestment = useMemo(() => deal?.minimum_interval || 0, [deal]);

    const totalAmount = formatToCurrencyString(minimumIntervalInvestment * investAmount);

    const proceed = useCallback(() => {
        if (user?.id) {
            dispatch(createLinkToken(user.id));
        }
    }, [user]);

    useEffect(() => {
        if (ready && open) {
            open();
        }
    }, [ready, open]);

    return (
        <div className="text-left px-5 flex justify-between items-center font-frankRuhlLibre">
            <Input
                type="number"
                label="Number"
                min={0}
                className="border-0"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setInvestAmount(+e.target.value)}
            />
            <div className="mt-7 text-xl">x</div>
            <div className="text-center text-black">
                <div className="text-xs font-bold font-segoe">Minimum Interval Investment</div>
                <div className="mt-4 text-xl">${formatToCurrencyString(minimumIntervalInvestment)}</div>
            </div>
            <div className="mt-7 text-xl">=</div>
            <div className="text-center text-black w-24">
                <div className="text-xs font-bold font-segoe">Total</div>
                <div className="mt-4 text-xl">${totalAmount}</div>
            </div>
            <div>
                <div className="bg-primary h-12 mb-2.5 px-2.5 flex justify-between items-center font-bold">
                    <div className="text-lg">Total</div>
                    <div className="font-segoe text-xl">${totalAmount}</div>
                </div>
                <div className="grid grid-cols-2 gap-2.5">
                    <Button onClick={proceed} disabled={investAmount <= 0} isLoading={plaidLoading}>
                        PROCEED
                    </Button>
                    <Button className="bg-gray hover:bg-gray hover:opacity-80">CANCEL</Button>
                </div>
            </div>
        </div>
    );
};

export default Completed;
