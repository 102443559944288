import type { InvestorClassificationAndType } from 'types/Kyc';
import * as Yup from 'yup';

const INVESTOR_CLASSIFICATIONS_ERROR = 'Please select Investor Classification';

const investorClassificationAndTypeSchema: Yup.SchemaOf<InvestorClassificationAndType> = Yup.object().shape(
  {
    investor_type: Yup.mixed().oneOf(['IND', 'IRA', 'ENT', 'TRUST']).required(),
    investor_classification: Yup.mixed().oneOf(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']).required(INVESTOR_CLASSIFICATIONS_ERROR),
    type_of_ownership_inv: Yup.mixed().oneOf(['A', 'B', 'C', 'D', 'E']),
    type_of_ownership_ent: Yup.mixed().oneOf(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'O']),
    type_of_ownership_ira: Yup.mixed().oneOf(['A', 'B', 'C', 'F']),
    trust_type_of_ownership: Yup.mixed().oneOf(['A', 'B']),
    ownership_type_oth: Yup.string(),
  },
);

export default investorClassificationAndTypeSchema;
