import type { Document } from 'types/Documents';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setDocuments, setDocumentsLoading } from './actions';

export interface DocumentsState {
  documents: Document[];
  documentsLoading: boolean;
}

const initialState: DocumentsState = {
  documents: [],
  documentsLoading: false,
};

const documentsReducer = createReducer(initialState, {
  [setDocuments.type]: (state: DocumentsState, action: PayloadAction<Document[]>) => ({
    ...state,
    documents: action.payload,
  }),
  [setDocumentsLoading.type]: (state: DocumentsState, action: PayloadAction<boolean>) => ({
    ...state,
    documentsLoading: action.payload,
  }),
});

export default documentsReducer;
