const convertObjectToFormData = (obj: any): FormData => {
  const form_data = new FormData();
  for ( const key in obj ) {
    if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        form_data.append(`${key}[]`, obj[key][i]);
      }
    } else if (obj[key]) {
      form_data.append(key, obj[key]);
    }
  }
  return form_data;
}


export default convertObjectToFormData;
