import type { IChartistBase, IChartOptions } from 'chartist';

function CustomVerticalLinesPlugin() {
  return function CustomVerticalLinesPlugin(chart: IChartistBase<IChartOptions>) {
    chart.on('created', () => {
        const horizontalLines = Array.from((chart.container as HTMLDivElement).querySelectorAll<HTMLElement>('.ct-grid.ct-horizontal') || []);
        const element: HTMLElement = horizontalLines[horizontalLines.length - 1];
        const bBox = (element.parentNode as SVGGraphicsElement)?.getBBox();
        if (element && bBox?.width) {
            element.style.display = 'block';
            element.setAttribute('x1', (bBox.width + 50).toString());
            element.setAttribute('x2', (bBox.width + 50).toString());
        }
    });
  }
}

export default CustomVerticalLinesPlugin;