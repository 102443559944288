import React, { useEffect, useRef } from 'react';
import Chartist, { IChartistSvg } from 'chartist';
import cx from 'classnames';
import { compactNumber } from 'utils';

import { ReactComponent as HexagonFillSrc } from 'assets/svg/hexagon-fill.svg';
import { CustomVerticalLabelsPlugins, CustomVerticalLinesPlugin } from '../plugins';

const notStackedClassName = 'not-stacked';

interface Seria {
    name: string;
    notStacked?: boolean;
    data: number[];
}

interface BarChartProps {
    title?: string;
    height?: string;
    width?: string;
    containerClassName?: string;
    showLegend?: boolean;
    labels: string[];
    series: Seria[];
}

interface IChartDrawLabelData {
    type: 'label' | 'point' | 'bar';
    element: IChartistSvg;
    group: IChartistSvg;
}

const BarChart = ({ title, width, height, containerClassName, showLegend, labels, series }: BarChartProps) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chartInstance = new Chartist.Bar(
            chartRef.current,
            {
                labels,
                series: series.map((seria: Seria) => ({
                    name: seria.name,
                    data: seria.data,
                    className: seria.notStacked ? notStackedClassName : '',
                })),
            },
            {
                stackBars: true,
                stackMode: 'overlap',
                seriesBarDistance: 20,
                width,
                height,
                ticks: [0, 1000, 2000],
                axisY: {
                    scaleMinSpace: 22,
                    labelInterpolationFnc: (value: number) => {
                        return compactNumber(value);
                    },
                },
                plugins: [CustomVerticalLabelsPlugins(), CustomVerticalLinesPlugin()],
            }
        );
        chartInstance.on('draw', (data: IChartDrawLabelData) => {
            if (data.type === 'bar') {
                const isTest = (data.element._node.parentNode as SVGGraphicsElement).classList.contains(
                    notStackedClassName
                );
                const x1Attr = data.element._node.getAttribute('x1');
                const x2Attr = data.element._node.getAttribute('x2');
                const x1 = x1Attr ? parseInt(x1Attr) : 0;
                const x2 = x2Attr ? parseInt(x2Attr) : 0;
                data.element.attr({
                    style: 'stroke-width: 20px',
                    x1: isTest ? (x1 + 20).toString() : x1.toString(),
                    x2: isTest ? (x2 + 20).toString() : x2.toString(),
                });
            }
        });
    }, []);

    return (
        <div>
            {title && (
                <div
                    className={cx(
                        'my-4',
                        'md:my-6',
                        'text-center',
                        'text-[18px]',
                        'text-black',
                        'font-frankRuhlLibre',
                        'font-bold'
                    )}
                >
                    {title}
                </div>
            )}
            <div className={containerClassName} ref={chartRef} />
            {showLegend && (
                <div className="flex items-center my-8 md:my-16">
                    {series.map((seria: Seria, index: number) => (
                        <div className={`chart-color-${index + 1} flex items-center ml-3`} key={index}>
                            <HexagonFillSrc width="16px" height="16px" />
                            <span className="ml-3 font-bold text-[12px] text-gray font-segoe uppercase">
                                {seria.name}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

BarChart.defaultProps = {
    title: '',
    containerClassName: '',
    height: '400px',
    width: '100%',
    showLegend: true,
};

export default BarChart;
