import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setAccessToken, setLinkToken, setPlaidLoading } from './actions';

export interface PlaidState {
  linkToken: string;
  access_token: string;
  plaidLoading: boolean;
}

const initialState: PlaidState = {
  linkToken: '',
  access_token: '',
  plaidLoading: false,
};

const plaidReducer = createReducer(initialState, {
  [setLinkToken.type]: (state: PlaidState, action: PayloadAction<string>) => ({
    ...state,
    linkToken: action.payload,
  }),
  [setAccessToken.type]: (state: PlaidState, action: PayloadAction<string>) => ({
    ...state,
    access_token: action.payload,
  }),
  [setPlaidLoading.type]: (state: PlaidState, action: PayloadAction<boolean>) => ({
    ...state,
    plaidLoading: action.payload,
  }),
});

export default plaidReducer;
