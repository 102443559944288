import type { AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RequestWithPagination } from 'types/Common';
import type { Deal, GetDealsRequest } from 'types/Market';
import type { SubscriptionsTrackerResponse, SudraniaFundDetailsRaw, SudraniaResponse } from 'types/Sudrania';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as api from 'api';
import * as marketActions from './actions';
import convertSundraniaFundFromRaw from 'utils/helpers/convertSundraniaFundFromRaw';
import { store } from 'store';
import { fundTracker, getSubscription } from 'api';

function* getDeals(action: PayloadAction<GetDealsRequest>) {
  try {
    yield put(marketActions.setDealsLoading(true));
    const res: AxiosResponse<RequestWithPagination<Deal[]>> =
      yield call(api.getDeals, action.payload);
    yield put(marketActions.setDeals(res.data.objects));
    yield put(marketActions.setDealsTotal(res.data.total));
    yield put(marketActions.setDealsLoading(false));
  } catch (error) {
    yield put(marketActions.setDealsLoading(false));
  }
}

function* getDeal(action: PayloadAction<string>) {
  try {
    const state = store.getState();
    yield put(marketActions.setDealLoading(true));
    const res: AxiosResponse<Deal> =
      yield call(api.getDeal, action.payload);
    if (res.data.sudrania_id) {
      const fundDetailsRes: AxiosResponse<SudraniaResponse<SudraniaFundDetailsRaw[]>> =
        yield call(api.getFundDetails, res.data.sudrania_id);

      const fundDetails = convertSundraniaFundFromRaw(fundDetailsRes.data.data?.[0]);
      yield put(marketActions.setFundDetails(fundDetails));
      if (state?.account?.metadata?.sudrania_id) {
        const subscriptions: AxiosResponse<SudraniaResponse<SubscriptionsTrackerResponse[]>> =
          yield call(fundTracker, state?.account?.metadata?.sudrania_id, res.data.sudrania_id,);
        if (subscriptions.data.data?.[0]) {
          yield put(marketActions.setDealSubscription(subscriptions.data.data?.[0]));
        }
      }
    }
    yield put(marketActions.setDeal(res.data));
    yield put(marketActions.setInvestNow(false));
    yield put(marketActions.setDealLoading(false));
  } catch (error) {
    yield put(marketActions.setDealLoading(false));
  }
}

function* marketSaga() {
  yield all([
    takeEvery(marketActions.getDeals.type, getDeals),
    takeEvery(marketActions.getDeal.type, getDeal),
  ]);
}

export default marketSaga;