import type { Table as ITable } from 'types/Tables';

// Components
import Table from './Table';

interface TermsProps {
    tables: ITable[];
}

const Terms = ({ tables }: TermsProps) => {
    return (
        <div className="my-6 bg-[#F5F2EF] font-frankRuhlLibre">
            <Table table={tables} />
        </div>
    );
};

export default Terms;
