import { useCallback, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useMatch, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'routes';

// Components
import Footer from 'components/Footer';
import Header from 'components/Header';

// Hooks
import { useAppDispatch, useAppSelector, useOnLocationChange } from 'hooks';

// Store
import { authActions } from 'store/auth';
import { selectAppLoading, selectSessionSecret } from 'store/auth/selectors';
import { selectRedirectToKyc } from 'store/kyc/selectors';
import { setRedirectToKyc } from 'store/kyc/actions';

// Utils
import { removeAllToast } from 'utils/helpers/showToast';

function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const appLoading = useAppSelector(selectAppLoading);
    const sessionSecret = useAppSelector(selectSessionSecret);
    const redirectToKyc = useAppSelector(selectRedirectToKyc);

    const isHomeAlt = useMatch('/');

    useOnLocationChange(() => {
        removeAllToast();
    });

    const fetchCurrentUser = useCallback(async () => {
        // Fetch current user only if session secret is available
        if (sessionSecret) {
            dispatch(authActions.getCurrentUser());
        }
    }, [sessionSecret]);

    useEffect(() => {
        // Fetch current user on app init
        fetchCurrentUser();
    }, [dispatch]);

    useEffect(() => {
        if (redirectToKyc) {
            dispatch(setRedirectToKyc(false));
            navigate('/kyc');
        }
    }, [redirectToKyc]);

    if (appLoading) {
        return <div className="h-screen w-screen flex justify-center items-center">Loading...</div>;
    }

    return (
        <div className="flex flex-col items-center min-h-screen">
            {!isHomeAlt && <Header />}
            <AppRoutes />
            <Footer />
            <ToastContainer className="w-auto" />
        </div>
    );
}

export default App;
