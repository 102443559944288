import React from 'react';

import { Button } from 'components/common';
import hexCube from 'assets/images/hex-cube.png';
import hexStock from 'assets/images/hex-stock.png';

const Ad = () => (
    // <div className="w-full bg-[url('assets/images/r2d_img_slashSplash.png')] bg-no-repeat bg-[right_150px] bg-cover">
    <div className="w-full">
        <div className="container p-8 md:p-12 mx-auto h-[550px] sm:h-[500px] flex justify-start items-center flex-col-reverse sm:flex-row">
            <div className="w-full md:w-6/12">
                <div className="text-2xl lg:text-3xl font-frankRuhlLibre">
                    We source deals that lucrative tax benefits, great income and exceptional appreciation.
                </div>
                <div className="mt-10 mb-12 text-xs lg:text-base text-desc">
                    Invest today to enhance your investment portfolio with the use of blockchain technology!
                </div>
                <div className="flex justify-center sm:justify-start w-full mt-6 lg:mt-10">
                    <Button className="uppercase w-48 xl:w-56">INVEST NOW</Button>
                </div>
            </div>
            <div className="relative w-full md:w-6/12 h-full flex">
                <div className="relative w-4/12">
                    <img className="absolute top-2 right-0 w-[240px]" src={hexCube} alt="" />
                </div>
                <div className="relative h-full w-8/12">
                    <img
                        className="absolute -top-6 sm:top-2 right-0 w-full scale-100 sm:scale-125 max-h-80 max-w-xs"
                        src={hexStock}
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
);

export default Ad;
