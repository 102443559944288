import type { ReactNode } from 'react';
import React from 'react';
import { Tab as TabUI } from '@headlessui/react';

import Tab from './Tab';
import TabList from './TabList';
import TabPanel from './TabPanel';
import TabPanels from './TabPanels';

interface TabsProps {
    children: ReactNode;
}

function Tabs({ children, ...otherProps }: TabsProps) {
    return <TabUI.Group {...otherProps}>{children}</TabUI.Group>;
}

Tabs.Tab = Tab;
Tabs.List = TabList;
Tabs.Panel = TabPanel;
Tabs.Panels = TabPanels;

export default Tabs;
