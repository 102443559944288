import type { InvestorPersonalInfo } from 'types/Kyc';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Country } from 'country-state-city';
import { yupResolver } from '@hookform/resolvers/yup';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Utils
import personalInfoSchema from 'utils/schemas/kyc/personalInfo';

// Components
import { FormHeader, Input, Select, RadioGroup } from 'components/common';
import MailAddressInfo from './AddressInfo/MailAddressInfo';
import { AddressInfo } from './AddressInfo';
import StepsPagination from '../StepsPagination';

// Store
import { setInvestorDetails, setKycStep, updateInvestor } from 'store/kyc/actions';
import { selectKycInvestorDetails, selectKycLoading, selectKycStep } from 'store/kyc/selectors';
import { selectSessionUser } from 'store/auth/selectors';
import { selectAccountMetadata } from 'store/account/selectors';

const Step1 = () => {
    const dispatch = useAppDispatch();

    const methods = useForm<InvestorPersonalInfo>({
        resolver: yupResolver(personalInfoSchema),
        defaultValues: {
            is_address_same: true,
            electronic_delivery_by_email: 'Y',
            k1_consent_electronic_delivery: 'A',
        },
    });

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = methods;

    const user = useAppSelector(selectSessionUser);
    const step = useAppSelector(selectKycStep);
    const loading = useAppSelector(selectKycLoading);
    const investorDetails = useAppSelector(selectKycInvestorDetails);
    const accountMetadata = useAppSelector(selectAccountMetadata);

    const is_address_same = watch('is_address_same');
    const electronic_delivery_by_email = watch('electronic_delivery_by_email');
    const k1_consent_electronic_delivery = watch('k1_consent_electronic_delivery');

    const countries = useMemo(() => {
        return Country.getAllCountries().map((country) => ({
            label: country.name,
            value: country.isoCode,
        }));
    }, []);

    const submit = useCallback(
        (data: InvestorPersonalInfo) => {
            if (user?.email) {
                if (investorDetails?.investor_sr_no) {
                    dispatch(
                        updateInvestor({
                            investor_sr_no: investorDetails.investor_sr_no,
                            data,
                        })
                    );
                } else {
                    dispatch(
                        setInvestorDetails({
                            ...data,
                            full_name: `${data.first_name} ${data.last_name}`,
                            email: user?.email,
                        })
                    );
                    dispatch(setKycStep(step + 1));
                }
            }
        },
        [dispatch, user, investorDetails, step]
    );

    useEffect(() => {
        if (investorDetails) {
            reset({
                ...investorDetails,
                state: investorDetails.state || accountMetadata.state,
                is_address_same: true,
                electronic_delivery_by_email: 'Y',
                k1_consent_electronic_delivery: 'A',
            });
        }
    }, [reset, investorDetails, accountMetadata]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
                <FormHeader title="1. Personal Information" />
                <div className="px-5 mt-5">
                    <div className="grid grid-cols-2 gap-5 lg:gap-10">
                        <Input
                            label="First Name"
                            {...register('first_name')}
                            error={errors?.first_name?.message}
                            fullWidth
                        />
                        <Input
                            label="Last Name"
                            {...register('last_name')}
                            error={errors?.last_name?.message}
                            fullWidth
                        />
                    </div>
                    <AddressInfo />
                </div>
                <div className="px-3 mt-7">
                    <RadioGroup
                        label="Is your Mailing Address the same as Registered Address?"
                        items={[
                            { label: 'Yes', value: 'yes', onSelect: () => setValue('is_address_same', true) },
                            { label: 'No', value: 'no', onSelect: () => setValue('is_address_same', false) },
                        ]}
                        defaultItemValue={is_address_same ? 'yes' : 'no'}
                    />
                </div>
                {!is_address_same && (
                    <div className="px-5 mt-5">
                        <MailAddressInfo />
                    </div>
                )}
                <div className="px-3 mt-7">
                    <RadioGroup
                        label="For Electronic Delivery (Please Select One)"
                        items={[
                            {
                                label: 'I want to receive communications by email',
                                value: 'communicationsByEmail',
                                onSelect: () => setValue('electronic_delivery_by_email', 'Y'),
                            },
                            {
                                label: 'I want to receive the hard copy by mail',
                                value: 'hardCopyByEmail',
                                onSelect: () => setValue('electronic_delivery_by_email', 'N'),
                            },
                        ]}
                        defaultItemValue={
                            electronic_delivery_by_email === 'Y' ? 'communicationsByEmail' : 'hardCopyByEmail'
                        }
                    />
                </div>
                <div className="px-3 mt-7">
                    <RadioGroup
                        label="Consent to Electronic Delivery of K1"
                        items={[
                            {
                                label: 'Yes',
                                value: 'yes',
                                onSelect: () => setValue('k1_consent_electronic_delivery', 'A'),
                            },
                            {
                                label: 'No',
                                value: 'no',
                                onSelect: () => setValue('k1_consent_electronic_delivery', 'B'),
                            },
                        ]}
                        defaultItemValue={k1_consent_electronic_delivery === 'A' ? 'yes' : 'no'}
                    />
                </div>
                <div className="px-5 block grid-cols-2 gap-10 lg:grid">
                    <Select
                        label="Country Of Citizenship"
                        options={countries}
                        onSelect={(option) => setValue('country_of_citizenship', option.value)}
                        dropdownClassName="w-full"
                        error={errors?.country_of_citizenship?.message}
                    />
                    <Input
                        label="Date Of Birth (DD-MM-YYYY)"
                        {...register('dob')}
                        error={errors?.dob?.message}
                        containerClassName="mt-7 lg:mt-0"
                        fullWidth
                    />
                </div>
                <StepsPagination loading={loading} />
            </form>
        </FormProvider>
    );
};

export default Step1;
