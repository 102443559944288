import type { Investor } from 'types/Kyc';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setKycStep, setKycLoading, setInvestorDetails, clearInvestorDetails, setRedirectToKyc } from './actions';

export interface KycState {
  stepsLength: number;
  step: number;
  investorDetails: Investor | null;
  loading: boolean;
  redirectToKyc: boolean;
}

const initialState: KycState = {
  stepsLength: 5,
  step: 0,
  investorDetails: null,
  loading: false,
  redirectToKyc: false,
};

const coreReducer = createReducer(initialState, {
  [setKycStep.type]: (state: KycState, action: PayloadAction<number>) => ({
    ...state,
    step: action.payload,
  }),
  [setKycLoading.type]: (state: KycState, action: PayloadAction<boolean>) => ({
    ...state,
    loading: action.payload,
  }),
  [setInvestorDetails.type]: (state: KycState, action: PayloadAction<Investor>) => ({
    ...state,
    investorDetails: {
      ...state.investorDetails,
      ...action.payload,
    },
  }),
  [clearInvestorDetails.type]: (state: KycState) => ({
    ...state,
    investorDetails: null,
  }),
  [setRedirectToKyc.type]: (state: KycState, action: PayloadAction<boolean>) => ({
    ...state,
    redirectToKyc: action.payload,
  }),
});

export default coreReducer;
