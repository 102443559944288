import type { Banking } from 'types/Kyc';
import { useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Country } from 'country-state-city';
import { yupResolver } from '@hookform/resolvers/yup';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import { setKycStep, updateInvestor } from 'store/kyc/actions';
import { selectKycInvestorDetails, selectKycLoading, selectKycStep } from 'store/kyc/selectors';
import { selectAccountMetadata } from 'store/account/selectors';

// Utils
import bankingSchema from 'utils/schemas/kyc/banking';

// Components
import { FormHeader, Input, Select } from 'components/common';
import StepsPagination from '../StepsPagination';

const Step4 = () => {
    const dispatch = useAppDispatch();

    const { watch, setValue, register, handleSubmit, reset } = useForm<Banking>({
        resolver: yupResolver(bankingSchema),
    });

    const bank_country = watch('bank_country');

    const investorDetails = useAppSelector(selectKycInvestorDetails);
    const step = useAppSelector(selectKycStep);
    const metadata = useAppSelector(selectAccountMetadata);
    const loading = useAppSelector(selectKycLoading);

    const countries = useMemo(() => {
        return Country.getAllCountries().map((country) => ({
            label: country.name,
            value: country.isoCode,
        }));
    }, []);

    const submit = useCallback(
        (data: Banking) => {
            if (metadata.sudrania_id) {
                dispatch(
                    updateInvestor({
                        investor_sr_no: metadata.sudrania_id,
                        data,
                    })
                );
            }
        },
        [metadata]
    );

    const goBack = useCallback(() => {
        dispatch(setKycStep(step - 1));
    }, [step]);

    useEffect(() => {
        if (investorDetails) {
            reset({
                bank_name: investorDetails.bank_name,
                bank_country: investorDetails.bank_country,
                account_name: investorDetails.account_name,
                account_number: investorDetails.account_number,
                aba_number: investorDetails.aba_number,
                ib_aba_swift: investorDetails.ib_aba_swift,
            });
        }
    }, [reset, investorDetails]);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormHeader title="4. Banking" />
            <div className="mt-5 mb-7 px-3 font-bold text-black">
                When you make an investment, you will submit your banking information via Plaid, and the account used
                must match the account you submit here
            </div>
            <div className="px-5">
                <Input label="Your Bank Name" {...register('bank_name')} fullWidth />
                <div className="mt-10">
                    <Select
                        label="Your Bank Country"
                        options={countries}
                        selectedDefault={bank_country}
                        onSelect={(country) => setValue('bank_country', country.value)}
                        dropdownClassName="w-full"
                    />
                </div>
                <div className="block lg:grid grid-cols-2 gap-10 mt-10">
                    <Input label="Your Account Name" {...register('account_name')} fullWidth />
                    <Input
                        label="Your Account Number"
                        containerClassName="mt-10 lg:mt-0"
                        {...register('account_number')}
                        fullWidth
                    />
                </div>
                <div className="block lg:grid grid-cols-2 gap-10 mt-10">
                    <Input label="Your ABA Number" {...register('aba_number')} fullWidth />
                    <Input
                        label="Your Intermediary Bank ABA/SWIFT"
                        containerClassName="mt-10 lg:mt-0"
                        {...register('ib_aba_swift')}
                        fullWidth
                    />
                </div>
            </div>
            <StepsPagination goBack={goBack} loading={loading} />
        </form>
    );
};

export default Step4;
