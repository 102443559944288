export enum SubscriptionType {
  ADDITIONAL = 'A',
  INITIAL = 'I',
}

export enum FundingStatus {
  FULLY_FUNDED = 'F',
  NOT_FUNDED = 'FN',
  PARTIALLY_FUNDED = 'PF',
  NOT_APPLICABLE = 'NA',
}

export enum InvestorType {
  IND = 'Individual',
  IRA = 'IRA Account',
  ENT = 'Entity',
  TRUST = 'Trust',
}

export enum AsOfTransaction {
  YES = 'Y',
  NO = 'N',
}

export interface SudraniaFundRaw {
  id: string;
  "Fund Name": string;
}

export interface SudraniaFund {
  id: string;
  fund_name: string;
}

export interface SudraniaFundDetailsClass {
  fee_class: string;
  preferred_rate_of_return: string;
  hurdle_rate: string;
  management_fees: string;
  management_fee_frequency_g: string;
  performance_allocations: string;
  performance_fee_frequency_g: string
}

export interface SudraniaFundDetailsRaw {
  "id": string;
  "Fund Name": string;
  "Fund Category": string;
  "Class": SudraniaFundDetailsClass[];
  "Fund Type": string;
  "Logo": string;
}

export interface SudraniaFundDetails {
  id: string;
  fund_name: string;
  fund_category: string;
  class: SudraniaFundDetailsClass[];
  fund_type: string;
  logo: string;
}

export interface SudraniaResponse<T> {
  message: string;
  status: string;
  data: T;
}

export interface SudraniaRequest {
  user_agent: string,
  form_data?: FormData,
  verb: string,
  path: string,
  params?: string
}

export interface AddSubscriptionRequest {
  investor_profile: string;
  fund: string;
  asset_type: string;
  subscription_type: SubscriptionType;
  amount: string;
  fund_currency?: string;
  entry_date?: string;
  funding_status?: FundingStatus;
  management_fees?: string;
  performance_fees?: string;
  series?: string;
  fund_duration?: string;
  investment_duration?: string;
  dis_rev_plan?: string;
  reinvest?: string;
  periodic_investment_plan?: string;
  periodic_investment_amount?: string;
  number_of_unit?: string;
  as_of_transaction?: AsOfTransaction;
}

export interface AddSubscriptionResponse {
  id: string;
}

export interface SubscriptionsTrackerResponseStatus {
  process_date: string;
  process_status: string;
}

export interface SubscriptionsTrackerResponse {
  account_funded: SubscriptionsTrackerResponseStatus;
  aml_kyc: SubscriptionsTrackerResponseStatus;
  subscription_agreement_signed_investor: SubscriptionsTrackerResponseStatus;
  subscription_application: SubscriptionsTrackerResponseStatus;
  subscription_form_signed_manager: SubscriptionsTrackerResponseStatus;
  supporting_documents: string;
  subscription_sr_no: string;
}
