import type { AxiosResponse } from 'axios';
import type {
    SignupParams,
    LoginParams,
    LoginResponse,
    CreateUserSessionParams,
    CreateUserSessionResponse,
} from './interfaces/auth';
import type { UpdateAccountPasswordParams } from './interfaces/account';
import type User from 'types/User';
import type UserMetadata from 'types/UserMetadata';
import type AccountNotifications from 'types/AccountNotifications';
import type { ResetPasswordParams } from 'types/ResetPassword';
import type { RequestWithPagination } from 'types/Common';
import type { Deal, GetDealsRequest } from 'types/Market';
import type {
  AddSubscriptionRequest,
  SudraniaFundDetailsRaw,
  SudraniaFundRaw,
  SudraniaResponse,
} from 'types/Sudrania';
import type { HubspotSignupParams } from 'types/Hubspot';
import { PlaidEnvironment, PlaidRequest } from 'types/Plaid';
import type { AddInvestorParams, UpdateInvestorParams, UploadInvestorDocumentParams } from 'types/Kyc';

import { api } from './api-base';
import { apiR2D } from './api-r2d-base';
import convertObjectToFormData from 'utils/helpers/convertObjectToFormData';
import { apiSudrania } from './api-sudrania-base';

// Auth
export const signup = (params: SignupParams):
    Promise<AxiosResponse<User>> =>
        api.post('/signup', {
            ...params,
            name: params.email,
            profiles: [{
                applicationId: 'R2D',
                displayName: params.name,
            }],
        });

export const login = (params: LoginParams): Promise<AxiosResponse<LoginResponse>> =>
    api.post('/session', params);

export const createUserSession =
    (params: CreateUserSessionParams):
        Promise<AxiosResponse<CreateUserSessionResponse>> =>
            api.post('/session', params);

export const getCurrentUser = (sessionSecret = ''):
    Promise<AxiosResponse<User>> => api.get('/user/me', {
        headers: {
            'Elements-SessionSecret': sessionSecret,
        },
    });

// Profile
export const getCurrentProfile = (): Promise<AxiosResponse> => api.get('/profile/current');

export const getAccountProfiles = (userId: string): Promise<AxiosResponse> => api.get(`/profile?user=${userId}`);

// Account
export const getAccountMetadata = (): Promise<AxiosResponse<UserMetadata>> => apiR2D.get('/account/metadata');

export const updateAccountMetadata = (data: UserMetadata): Promise<AxiosResponse> =>
  apiR2D.put(`/account/metadata?userId=6373e1d90ef4ba3ddd1a608a`, data);

export const updateAccountPassword = (data: UpdateAccountPasswordParams): Promise<AxiosResponse> =>
  apiR2D.put('/account/password', data);

export const getAccountNotifications = (): Promise<AxiosResponse<AccountNotifications>> =>
  apiR2D.get('/account/notifications');

export const updateAccountNotifications = (data: AccountNotifications): Promise<AxiosResponse> =>
  apiR2D.put('/account/notifications', data);

export const forgotPassword = (email: string): Promise<AxiosResponse> =>
  apiR2D.post('/account/forgot_password', {
    email,
    domain: process.env.NODE_ENV === 'development'
      ? `${window.location.hostname}:4200`
      : window.location.hostname,
  });

export const resetPassword = (params: ResetPasswordParams): Promise<AxiosResponse> =>
    apiR2D.post('/account/reset_password', params);

// Deals
export const getDeals  = (params: GetDealsRequest): Promise<AxiosResponse<RequestWithPagination<Deal[]>>> =>
    apiR2D.get('/deals', {
        params,
    });

export const getDeal = (dealId: string): Promise<AxiosResponse<Deal>> => apiR2D.get(`/deals/${dealId}`);

// Sudrania
export const getFundList = (): Promise<AxiosResponse<SudraniaFundRaw[]>> =>
  apiSudrania.get('csdapi/v1/csd_fund_api');

export const getFundDetails = (fundId: string): Promise<AxiosResponse<SudraniaResponse<SudraniaFundDetailsRaw>>> =>
  apiSudrania.get('csdapi/v1/csd_fund_details_api', {
    params: {
      id: fundId,
    }
  })

export const addInvestor = (data: AddInvestorParams): Promise<AxiosResponse> =>
  apiSudrania.post('/csdapi/v1/csd_investor_api', convertObjectToFormData(data));


export const getInvestor = (investor_sr_no: string): Promise<AxiosResponse> =>
  apiSudrania.get('/csdapi/v1/csd_investor_api', {
    params: {
      investor_sr_no,
    }
  });

export const updateInvestor = ({ investor_sr_no, data }: UpdateInvestorParams): Promise<AxiosResponse> => {
  return apiSudrania.put(`csdapi/v1/csd_investor_api?investor_sr_no=${investor_sr_no}`, convertObjectToFormData(data));
}

export const uploadInvestorDocument = (data: UploadInvestorDocumentParams): Promise<AxiosResponse> =>
  apiSudrania.post('api/upload/investor/document', convertObjectToFormData(data))

export const addSubscription = (data: AddSubscriptionRequest) =>
  apiSudrania.post('csdapi/v1/csd_subscription_api', convertObjectToFormData(data));
export const getSubscription = (subscription_sr_no: string) =>
  apiSudrania.get(`csdapi/v1/csd_subscription_api?subscription_sr_no=${subscription_sr_no}`);

export const getKycStatus = (subscription_id: string): Promise<AxiosResponse> =>
  apiSudrania.get('csdapi/v1/csd_amlkyc_api', {
    params: {
      subscription_id,
    }
  });

export const sendSubscriptionAgreement = (subscription_id: string) =>
  apiSudrania.post('api/send/subscription/agreement', convertObjectToFormData({
    subscription_id
  }));


export const getInvestorDocuments = (investor_id: string): Promise<AxiosResponse> =>
  apiSudrania.get('get/investor/document', {
    params: {
      investor_id,
    }
  });

export const getSubscriptionStatus = (subscription_id: string): Promise<AxiosResponse> =>
  apiSudrania.get('csdapi/v1/csd_subscription_status_api', {
    params: {
      subscription_sr_no: subscription_id,
    }
  });

export const fundTracker = (investor_id: string, fund_id: string): Promise<AxiosResponse> =>
  apiSudrania.post('/api/fund/tracker', convertObjectToFormData({
    investor_id,
    fund_id,
  }));

export const getPortfolio = (investor_sr_no: string): Promise<AxiosResponse> =>
  apiSudrania.get('get/portfolio', {
    params: {
      investor_sr_no,
    },
  });


// Plaid
export const sendPlaidRequest = (data: PlaidRequest): Promise<AxiosResponse> =>
  apiR2D.post('/plaid', data);

export const createPlaidToken = (userId: string): Promise<AxiosResponse> =>
  sendPlaidRequest({
    path: '/link/token/create',
    verb: 'POST',
    body: JSON.stringify({
      client_name: 'Real 2 Digital',
      products: ['auth', 'transactions'],
      country_codes: ['US'],
      language: 'en',
      user: {
        client_user_id: userId,
      }
    }),
    environment: PlaidEnvironment.SANDBOX,
  });

export const exchangePlaidToken = (public_token: string): Promise<AxiosResponse> =>
  sendPlaidRequest({
    path: '/item/public_token/exchange',
    verb: 'POST',
    body: JSON.stringify({
      public_token,
    }),
    environment: PlaidEnvironment.SANDBOX,
  });

// Hubspot
export const hubspotSignup = (data: HubspotSignupParams): Promise<AxiosResponse> =>
  apiR2D.post('/hubspot/contact', data);
