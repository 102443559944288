import type { Banking } from 'types/Kyc';
import * as Yup from 'yup';

import { FIELD_REQUIRED_ERROR } from '../common';

const bankingSchema: Yup.SchemaOf<Banking> = Yup.object().shape(
  {
    bank_name: Yup.string().required(FIELD_REQUIRED_ERROR),
    bank_country: Yup.string().required(FIELD_REQUIRED_ERROR),
    account_name: Yup.string().required(FIELD_REQUIRED_ERROR),
    account_number: Yup.string().required(FIELD_REQUIRED_ERROR),
    aba_number: Yup.string().required(FIELD_REQUIRED_ERROR),
    ib_aba_swift: Yup.string().required(FIELD_REQUIRED_ERROR),
  },
);

export default bankingSchema;
