import hexCube from 'assets/images/hex-cube.png';
import hexGray from 'assets/images/hex-gray.png';

const Ad = () => {
    return (
        <div className="w-full">
            <div className="container p-5 pt-24 md:p-12 mx-auto flex justify-start items-start flex-col-reverse sm:flex-row">
                <div className="w-full mt-52 md:mt-0 md:w-6/12 md:max-w-[455px] md:mr-12">
                    <div className="text-lg md:text-2xl mt-5 md:mt-0 lg:text-[25px] font-frankRuhlLibre tracking-normal">
                        <div className="mb-10">Why Orca?</div>
                        <div className="text-base">
                            At Orca Capital, our goal is to help investors capitalize on the fastest growing asset class
                            while minimizing the large risks that come with this young market. The fund was born as a
                            vehicle for ourselves to invest into and after proving success, we decided to open it up to
                            accredited investors worldwide.
                        </div>
                        <ul className="text-base mt-7 pl-6">
                            <li className="text-orange list-disc pb-5">
                                <span className="text-black">
                                    Orca Capital is dedicated to outperforming Bitcoin and delivering returns that
                                    exceed a simple buy-and-hold strategy.
                                </span>
                            </li>
                            <li className="text-orange list-disc pb-5">
                                <span className="text-black">
                                    Our turnkey solution provides a comprehensive approach to managing your exposure in
                                    the cryptocurrency markets.
                                </span>
                            </li>
                            <li className="text-orange list-disc pb-5">
                                <span className="text-black">
                                    We provide an actively managed long-short strategy that has outperformed our
                                    benchmark since inception.
                                </span>
                            </li>
                            <li className="text-orange list-disc">
                                <span className="text-black">
                                    Our team of experienced attorneys, tax experts, custodians, and fund administrators
                                    proactively work with industry authorities to navigate the evolving regulatory
                                    landscape and ensure our clients are always ahead of the curve.
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="relative w-full md:w-6/12 h-full flex">
                    <div className="relative w-4/12">
                        <img className="absolute top-2 right-0 w-[240px]" src={hexCube} alt="" />
                    </div>
                    <div className="relative h-full w-8/12">
                        <img
                            className="absolute right-4 scale-100 w-36 md:right-0 sm:top-2 md:w-96"
                            src={hexGray}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ad;
