import type { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Button, Spinner } from 'components/common';

interface InvestorInformationProps {
    kycCompleted?: boolean;
    loading?: boolean;
}

const InvestorInformation: FC<InvestorInformationProps> = ({
    kycCompleted,
    loading,
}) => {
    return (
        <div className="mt-5">
            <h3 className="text-base font-bold font-frankRuhlLibre bg-link text-white-desc p-4 mb-3 xl:mb-5">
                Investor Information
            </h3>
            {loading && (
                <div className="flex justify-center items-center py-4">
                    <Spinner className="w-16 h-16" />
                </div>
            )}
            {!loading && kycCompleted && (
                <div className="mt-5 text-center text-lg">
                    Investor information forms have been completed
                </div>
            )}
            {!loading && !kycCompleted && (
                <div className="flex justify-center mt-5 pb-3 lg:pb-10">
                    <Link to="/kyc">
                        <Button
                            type="submit"
                            className="uppercase w-full mx-3 md:max-w-sm"
                        >
                            COMPLETE INVESTOR INFORMATION FORMS
                        </Button>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default InvestorInformation;
