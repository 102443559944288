import type { AddInvestorParams, Investor, UploadInvestorDocumentParams, UpdateInvestorParams } from 'types/Kyc';
import { createAction } from '@reduxjs/toolkit';

const PREFIX = 'kyc';

export const setKycStep = createAction<number>(`${PREFIX}/setKycStep`);
export const setKycLoading = createAction<boolean>(`${PREFIX}/setLoading`);
export const addInvestor = createAction<AddInvestorParams>(`${PREFIX}/addInvestor`);
export const getInvestor = createAction<string>(`${PREFIX}/getInvestor`);
export const setInvestorDetails = createAction<Partial<Investor>>(`${PREFIX}/setInvestorDetails`);
export const clearInvestorDetails = createAction(`${PREFIX}/clearInvestorDetails`);
export const updateInvestor = createAction<UpdateInvestorParams>(`${PREFIX}/updateInvestor`);
export const setRedirectToKyc = createAction<boolean>(`${PREFIX}/setRedirectToKyc`);
export const uploadInvestorDocument = createAction<UploadInvestorDocumentParams>(`${PREFIX}/uploadInvestorDocument`);
