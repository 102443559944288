import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/saga';
import accountSaga from './account/saga';
import marketSaga from './market/saga';
import kycSaga from './kyc/saga';
import plaidSaga from './plaid/saga';
import documentsSaga from './documents/saga';
import portfolioSaga from './portfolio/saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(accountSaga),
    fork(marketSaga),
    fork(kycSaga),
    fork(plaidSaga),
    fork(documentsSaga),
    fork(portfolioSaga),
  ]);
}
