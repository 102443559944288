import type { IChartAxis, IChartistBase, IChartistSvg, IChartOptions } from 'chartist';
import Chartist from 'chartist';
import hexagonSrc from 'assets/svg/hexagon.svg';

interface formatTooltipValueProp {
  x: string | number;
  y: string | number;
}

interface IChartDrawLabelData {
	type: 'label' | 'point';
	axis: IChartAxis;
	element: IChartistSvg;
	group: IChartistSvg;
	height: number;
	index: number;
	text: number;
	width: number;
	x: number;
	y: number;
	value: formatTooltipValueProp;
}

interface TooltipPluginOptions {
  formatTooltipValue?: (prop: formatTooltipValueProp) => string;
}

function TooltipPlugin(options?: TooltipPluginOptions) {
    return function TooltipPlugin(chart: IChartistBase<IChartOptions>) {
        const $chart = chart.container;
        const $hoverImg = document.createElement('img');
        const $toolTip = document.createElement('div');
        const $toolTipArrow = document.createElement('div');
        const $toolTipContent = document.createElement('div');
        $toolTip.className = 'chart-tooltip absolute bg-black transition-all ease-in opacity-1 opacity-0 top-0 left-0';
        $toolTipArrow.className = 'chart-tooltip__arrow';
        $toolTipContent.className = 'chart-tooltip__content text-primary text-xs font-semibold';
        $hoverImg.src = hexagonSrc;
        $toolTip.appendChild($toolTipArrow);
        $toolTip.appendChild($toolTipContent);
        // $toolTip.appendChild($hoverImg);
        $chart.appendChild($toolTip);
        chart.on('draw', (data: IChartDrawLabelData) => {
            if (data.type === 'point') {
                const { x, y } = data;
                let el: IChartistSvg | null = null;
                data.element._node.addEventListener('mouseover', () => {
                    $toolTip.classList.remove('opacity-0');
                    $toolTip.style.top = `${data.y - 8}px`;
                    $toolTip.style.left = `${data.x + 20}px`;
                    const value = options?.formatTooltipValue ? options.formatTooltipValue(data.value) : data.value.y;
                    $toolTipContent.innerHTML = value.toString();
                    el = new Chartist.Svg('polygon', {
                        points: `${x} ${y - 8}, ${x + 7} ${y - 4}, ${x + 7} ${y + 4}, ${x} ${y + 8}, ${x - 7} ${y + 4}, ${x - 7} ${y - 4}`
                    }, 'stroke-black ct-point__hexagon');
                    data.element._node.parentNode?.insertBefore(el._node, data.element._node);
                });
                data.element._node.addEventListener('mouseout', () => {
                    $toolTip.classList.add('opacity-0');
                    if (el) {
                        el.remove();
                    }
                });
            }
        });
    }
}

export default TooltipPlugin;
