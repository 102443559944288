import React from 'react';

const PortfolioHero = () => (
    <div className="my-8 lg:my-16 px-8">
        <h1 className="text-2xl lg:text-4xl font-light font-frankRuhlLibre text-desc mb-4 lg:mb-8">My Portfolio</h1>
        <p className="text-sm lg:text-base leading-4 lg:leading-6 text-desc font-segoe">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et.
        </p>
    </div>
);

export default PortfolioHero;
