import { Spinner } from 'components/common';
import PageLayout from 'components/common/PageLayout';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { selectAccountMetadata } from 'store/account/selectors';
import { getDocuments } from 'store/documents/actions';
import { selectDocuments, selectDocumentsLoading } from 'store/documents/selectors';

const Document = () => {
    const dispatch = useAppDispatch();

    const metadata = useAppSelector(selectAccountMetadata);
    const documents = useAppSelector(selectDocuments);
    const documentsLoading = useAppSelector(selectDocumentsLoading);

    useEffect(() => {
        if (metadata?.sudrania_id) {
            dispatch(getDocuments(metadata?.sudrania_id));
        }
    }, [metadata]);

    return (
        <PageLayout>
            <div className="text-2xl px-10 mt-20 container mx-auto">
                {documentsLoading && (
                    <div className="flex justify-center items-center">
                        <Spinner className="w-20 h-20" />
                    </div>
                )}
                {!documentsLoading && (
                    <>
                        <h2 className="text-center text-3xl mb-10">Documents</h2>
                        {documents.map((document, index) => (
                            <div key={index} className="flex justify-start items-center w-full mb-4 text-base">
                                <div>{document.file_type_text}: </div>
                                <div className="ml-10">{document.filename}</div>
                            </div>
                        ))}
                        {documents?.length === 0 && (
                            <div className="text-center text-gray mt-10">Documents not found</div>
                        )}
                    </>
                )}
            </div>
        </PageLayout>
    );
};

export default Document;
