import type { InvestorPersonalInfo } from 'types/Kyc';
import type { SelectOption } from 'components/common/Select';
import { useMemo } from 'react';
import { Country, State } from 'country-state-city';

import { Input, Select } from 'components/common';
import { useFormContext } from 'react-hook-form';

const AddressInfo = () => {
    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<InvestorPersonalInfo>();

    const country = watch('country');
    const state = watch('state');

    const countries = useMemo(() => {
        return Country.getAllCountries().map((c) => ({
            label: c.name,
            value: c.isoCode,
        }));
    }, []);

    const states = useMemo((): SelectOption[] => {
        if (!country) return [];
        else {
            return State.getStatesOfCountry(country).map((state) => ({
                label: state.name,
                value: state.isoCode,
            }));
        }
    }, [country]);

    return (
        <div>
            <div className="mt-10">
                <Input label="Address Line 1" {...register('address1')} error={errors?.address1?.message} fullWidth />
            </div>
            <div className="mt-10">
                <Input label="Address Line 2" {...register('address2')} error={errors?.address2?.message} fullWidth />
            </div>
            <div className="grid grid-cols-[1fr_1.3] gap-5 mt-10 lg:gap-10">
                <Input label="City" {...register('city')} error={errors?.city?.message} fullWidth />
                <div className="grid grid-cols-2 gap-5 lg:gap-10 lg:grid-cols-[0.6fr_0.7fr]">
                    <Select
                        label="State"
                        options={states}
                        selectedDefault={state}
                        onSelect={(option: SelectOption) => setValue('state', option.value)}
                        error={errors?.state?.message}
                        disabled={!country}
                    />
                    <Input label="Zip Code" {...register('zipcode')} error={errors?.zipcode?.message} fullWidth />
                </div>
            </div>
            <div className="mt-10">
                <Select
                    label="Country"
                    options={countries}
                    onSelect={(option: SelectOption) => setValue('country', option.value)}
                    dropdownClassName="w-full"
                    error={errors?.country?.message}
                />
            </div>
        </div>
    );
};

export default AddressInfo;
