import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Invest from './Invest';
import HomeHero from './Hero';
import Ad from './Ad';
import Header from 'components/Header';

const HomeAlt = () => {
    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const scrollToSignUp = useCallback(() => {
        document.querySelector('.home-sign-up-form')?.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest',
        });
    }, []);

    useEffect(() => {
        if (query.get('section')) {
            setTimeout(scrollToSignUp, 300);
        }
    }, [query]);

    return (
        <div className="w-full">
            <Header />
            <HomeHero />
            <Invest />
            <Ad />
            {/* <SignUpForm /> */}
        </div>
    );
};

export default HomeAlt;
