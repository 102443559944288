import type { IChartistBase, IChartOptions } from 'chartist';
import { divide } from 'ramda';

function CustomVerticalLabelsPlugins() {
  return function CustomVerticalLabelsPlugins(chart: IChartistBase<IChartOptions>) {
    chart.on('created', () => {
        const horizontalLabels = Array.from((chart.container as HTMLDivElement).querySelectorAll<HTMLElement>('.ct-label.ct-vertical') || []);
        const div2 = divide(horizontalLabels.length, 2);
        const middleItemIndex = Math.floor(div2);
        const middleElement: HTMLElement = horizontalLabels[middleItemIndex];
        const lastElement: HTMLElement = horizontalLabels[horizontalLabels.length - 1];
        if (middleElement && lastElement) {
            middleElement.style.fontWeight = '700';
            middleElement.style.color = '#8D8C89';
            lastElement.style.fontWeight = '700';
            lastElement.style.color = '#8D8C89';
        }
    });
  }
}

export default CustomVerticalLabelsPlugins;
