import hexNet from 'assets/images/hex-net.png';

const Invest = () => (
    <div className="relative bg-black h-64 lg:h-60 overflow-hidden p-2.5 sm:p-12">
        <img className="absolute -left-48 md:-left-0 top-0 h-full" src={hexNet} alt="" />
        <div className="pl-28 h-full flex justify-center items-center">
            <div className="w-full pr-6 md:w-4/6 xl:w-[47rem] mx-auto text-xs">
                <div className="text-primary text-xs text-center font-frankRuhlLibre px-0 tracking-normal md:text-xl">
                    How is a prudent investor supposed to know which coins to buy, which to sell, when to buy, when to
                    sell, how much of each to hold, and for how long?
                </div>
                <div className="text-white text-center mt-5 md:mt-10">
                    Our diversified investment vehicle is built on timeless investment principles and applied to the
                    cryptocurrency markets. We provide long-term investors with a reliable vehicle to get exposure
                    without all of the headaches that come along with this young asset class.
                </div>
            </div>
        </div>
    </div>
);

export default Invest;
