import type { AxiosError, AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AddInvestorParams, UpdateInvestorParams, UploadInvestorDocumentParams } from 'types/Kyc';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'api';

import * as kycActions from './actions';
import { accountActions } from 'store/account';

// Utils
import { showError } from 'utils/helpers/showToast';
import { store } from 'store';

function* addInvestor(action: PayloadAction<AddInvestorParams>) {
  try {
    yield put(kycActions.setKycLoading(true));
    const state = store.getState();
    const res: AxiosResponse = yield call(api.addInvestor, action.payload);

    if (res.data.status === 'OK') {
      yield put(accountActions.putAccountMetadata({
        sudrania_id: res.data.data.id,
      }));
      yield put(kycActions.setInvestorDetails(action.payload));
      yield put(kycActions.getInvestor(res.data.data.id));
      yield put(kycActions.setKycLoading(false));
      yield put(kycActions.setKycStep(state.kyc.step + 1));
    }
  }
  catch (error) {
    yield put(kycActions.setKycLoading(false));
    showError(error as AxiosError);
  }
}

function* getInvestor(action: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(api.getInvestor, action.payload);

    if (res.data.data?.length > 0) {
      yield put(kycActions.setInvestorDetails(res.data.data[0]));
    }
  }
  catch (error) {
    showError(error as AxiosError);
  }
}

function* updateInvestor(action: PayloadAction<UpdateInvestorParams>) {
  try {
    yield put(kycActions.setKycLoading(true));
    const state = store.getState();
    const res: AxiosResponse = yield call(api.updateInvestor, action.payload);
    if (res.status === 200) {
      yield put(kycActions.getInvestor(action.payload.investor_sr_no));
      yield put(kycActions.setKycStep(state.kyc.step + 1));
      yield put(kycActions.setKycLoading(false));
    }
  }
  catch (error) {
    yield put(kycActions.setKycLoading(false));
    showError(error as AxiosError);
  }
}

function* uploadInvestorDocument(action: PayloadAction<UploadInvestorDocumentParams>) {
  try {
    yield put(kycActions.setKycStep(5));
    yield put(kycActions.setKycLoading(true));
    yield call(api.uploadInvestorDocument, action.payload);
    yield put(kycActions.setKycLoading(false));
    yield put(kycActions.setKycStep(6));
  } catch (error) {
    yield put(kycActions.setKycLoading(false));
    showError(error as AxiosError);
  }
}

function* kycSaga() {
  yield all([
    takeLatest(kycActions.addInvestor.type, addInvestor),
    takeLatest(kycActions.getInvestor.type, getInvestor),
    takeLatest(kycActions.updateInvestor.type, updateInvestor),
    takeLatest(kycActions.uploadInvestorDocument.type, uploadInvestorDocument),
  ]);
}

export default kycSaga;
