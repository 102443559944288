import type { ChangeEvent, FormEvent } from 'react';
import React, { useCallback, useState } from 'react';

// Components
import { Button, Input } from 'components/common';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import { updateAccountPassword } from 'store/account/actions';
import { selectPasswordUpdateError, selectUpdatePasswordLoading } from 'store/account/selectors';

const Password = () => {
    const dispatch = useAppDispatch();

    const updatePasswordLoading = useAppSelector(selectUpdatePasswordLoading);
    const passwordUpdateError = useAppSelector(selectPasswordUpdateError);

    const [error, setError] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const submit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (!oldPassword || !newPassword || !confirmPassword) {
                setError('All fields required');
            } else if (newPassword !== confirmPassword) {
                setError("Passwords doesn't match");
            } else {
                setError('');
                dispatch(
                    updateAccountPassword({
                        new_password: newPassword,
                        current_password: oldPassword,
                    })
                );
            }
        },
        [oldPassword, newPassword, confirmPassword]
    );

    return (
        <form onSubmit={submit} className="mt-0">
            <h3 className="text-base font-bold font-frankRuhlLibre bg-link text-white-desc p-4 mb-3 xl:mb-5">
                Change Password
            </h3>
            {error && <div className="text-red-600 font-sego px-5">{error}</div>}
            {passwordUpdateError && <div className="text-red-600 font-sego px-5">{passwordUpdateError}</div>}
            <Input
                value={oldPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)}
                type="password"
                containerClassName="mb-4 px-5"
                label="Old Password"
                fullWidth
            />
            <Input
                value={newPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                type="password"
                containerClassName="mb-4 px-5"
                label="New Password"
                fullWidth
            />
            <Input
                value={confirmPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                type="password"
                containerClassName="mb-4 px-5"
                label="Confirm Password"
                fullWidth
            />
            <div className="flex justify-center mt-5 pb-3 lg:pb-10">
                <Button type="submit" className="uppercase w-64 mx-3" isLoading={updatePasswordLoading}>
                    Change Password
                </Button>
            </div>
        </form>
    );
};

export default Password;
