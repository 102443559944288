import type { Table } from 'types/Tables';

interface HighlightRowProps {
    data: Table;
}

const HighlightRow = ({ data }: HighlightRowProps) => {
    return (
        <div className="flex bg-primary justify-between px-4 items-center text-sm sm:text-base font-bold py-4">
            <div>{data.name}</div>
            <div className="font-segoe font-bold text-sm sm:text-lg">{data.value}</div>
        </div>
    );
};

export default HighlightRow;
