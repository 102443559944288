import type { Table as ITable } from 'types/Tables';
import Table from './Table';
import { PieChart } from 'components/common';
import HeadingRow from './Table/HeadingRow';

interface FinancialsProps {
    tables: ITable[];
    pieChart: ITable[];
}

const Financials = ({ tables, pieChart }: FinancialsProps) => (
    <div className="my-6 bg-[#F5F2EF] font-frankRuhlLibre">
        <Table table={tables} />
        {pieChart?.length && (
            <>
                <HeadingRow>Allocation</HeadingRow>
                <div className="bg-[#F1F3F6] py-12">
                    <PieChart series={pieChart} width="230px" height="230px" legendPosition="right" />
                </div>
            </>
        )}
    </div>
);

export default Financials;
