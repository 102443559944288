import type { FC } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';

interface LinkProps extends RouterLinkProps {
    className?: string;
}

const Link: FC<LinkProps> = ({ className, ...otherProps }) => (
    <RouterLink
        className={cx(
            'transition-all ease duration-200',
            'font-bold text-link no-underline hover:text-blue-900',
            className
        )}
        {...otherProps}
    />
);

Link.defaultProps = {
    className: '',
};

export default Link;
