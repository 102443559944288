import { useEffect, useMemo } from 'react';

// Components
import Personal from './Personal';
import Password from './Password';
import NotificationPreferences from './NotificationPreferences';
import InvestorInformation from './InvestorInformation';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import { getDocuments } from 'store/documents/actions';
import {
    selectDocuments,
    selectDocumentsLoading,
} from 'store/documents/selectors';
import { selectKycInvestorDetails } from 'store/kyc/selectors';

// Utils
import {
    DRIVER_LICENSE_FILE_TYPE,
    GOVERNMENT_ID_FILE_TYPE,
    OTHER_FILE_TYPE,
    PASSPORT_FILE_TYPE,
} from 'utils';

const file_types = [
    GOVERNMENT_ID_FILE_TYPE,
    PASSPORT_FILE_TYPE,
    DRIVER_LICENSE_FILE_TYPE,
    OTHER_FILE_TYPE
];

const Account = () => {
    const dispatch = useAppDispatch();

    const documents = useAppSelector(selectDocuments);
    const documentsLoading = useAppSelector(selectDocumentsLoading);
    const investorDetails = useAppSelector(selectKycInvestorDetails);

    const kycCompleted = useMemo(() => {
        return !!documents.find((document) =>
            file_types.includes(document.file_type));
    }, [documents]);

    useEffect(() => {
        if (investorDetails?.investor_sr_no) {
            dispatch(getDocuments(investorDetails?.investor_sr_no));
        }
    }, [investorDetails?.investor_sr_no]);

    return (
        <div className="bg-white w-full flex justify-center items-center mt-16 sm:mt-24 font-frankRuhlLibre bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-contain bg-[right_0px_bottom_-50px] sm:bg-[right_-300px_bottom_-300px]">
            <div className="w-[800px] max-w-5xl pb-64">
                <div className="mt-6 lg:mt-14 px-8">
                    <h1 className="text-3xl lg:text-5xl font-light text-black text-center">
                        My Account
                    </h1>
                </div>
                <Personal />
                <Password />
                <NotificationPreferences />
                <InvestorInformation
                    loading={documentsLoading}
                    kycCompleted={kycCompleted}
                />
            </div>
        </div>
    );
};

export default Account;
