import type { FormEvent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

// Components
import { Button, Checkbox } from 'components/common';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Store
import { selectAccountNotifications, selectUpdateNotificationsLoading } from 'store/account/selectors';
import { updateAccountNotifications } from 'store/account/actions';

const NotificationPreferences = () => {
    const dispatch = useAppDispatch();

    const notifications = useAppSelector(selectAccountNotifications);
    const updateNotificationsLoading = useAppSelector(selectUpdateNotificationsLoading);

    const [emailNewDeal, setEmailNewDeal] = useState<boolean>(false);
    const [emailDealUpdates, setEmailDealUpdates] = useState<boolean>(false);
    const [emailPaymentClaim, setEmailPaymentClaim] = useState<boolean>(false);

    useEffect(() => {
        setEmailNewDeal(notifications.email_new_deal);
        setEmailDealUpdates(notifications.email_deal_updates);
        setEmailPaymentClaim(notifications.email_payment_claim);
    }, [notifications]);

    const submit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            dispatch(
                updateAccountNotifications({
                    email_new_deal: emailNewDeal,
                    email_deal_updates: emailDealUpdates,
                    email_payment_claim: emailPaymentClaim,
                })
            );
        },
        [emailNewDeal, emailDealUpdates, emailPaymentClaim]
    );

    return (
        <form onSubmit={submit} className="mt-5">
            <h3 className="text-base font-bold font-frankRuhlLibre bg-link text-white-desc p-4 mb-3 xl:mb-5">
                Notification Preferences
            </h3>
            <div className="mt-5 px-4">
                <Checkbox label="Email me about new deals" selected={emailNewDeal} onSelect={setEmailNewDeal} />
            </div>
            <div className="mt-5 px-4">
                <Checkbox
                    label="Email me when I have a payment to claim"
                    selected={emailPaymentClaim}
                    onSelect={setEmailPaymentClaim}
                />
            </div>
            <div className="mt-5 px-4">
                <Checkbox
                    label="Email me with updates about my deals"
                    selected={emailDealUpdates}
                    onSelect={setEmailDealUpdates}
                />
            </div>
            <div className="flex justify-center mt-5 pb-3 lg:pb-10">
                <Button type="submit" className="uppercase w-64 mx-3" isLoading={updateNotificationsLoading}>
                    Save
                </Button>
            </div>
        </form>
    );
};

export default NotificationPreferences;
