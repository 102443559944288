import type { SubmitHandler } from 'react-hook-form';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

// Components
import { Button, Input } from 'components/common';

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// Validator options
import { getEmailValidationOptions } from 'utils/auth/validations';

// Store
import { forgotPassword } from 'store/account/actions';
import { selectIsTokenSent, selectUpdatePasswordLoading } from 'store/account/selectors';

interface ForgotPasswordFormSchema {
    email: string;
}

const ForgotPassword = () => {
    const dispatch = useAppDispatch();

    const updatePasswordLoading = useAppSelector(selectUpdatePasswordLoading);
    const isTokenSent = useAppSelector(selectIsTokenSent);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordFormSchema>();

    const emailInputProps = register('email', getEmailValidationOptions());

    const onSubmit: SubmitHandler<ForgotPasswordFormSchema> = useCallback(
        async (data) => {
            dispatch(forgotPassword(data.email));
        },
        [dispatch]
    );

    return (
        <div className="w-full flex-1 flex justify-center items-center bg-[url('assets/images/r2d_img_slashSplash@2x.png')] bg-no-repeat bg-[right_0px_bottom_-50px] bg-contain sm:bg-[right_-300px_bottom_-300px]">
            <div className="container flex-1 flex justify-center items-center px-4">
                <form className="w-full sm:max-w-2xl" onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        error={errors?.email?.message}
                        label="Email"
                        {...emailInputProps}
                        fullWidth
                    />
                    <Button
                        type="submit"
                        className="mx-auto mb-8 mt-10 uppercase w-64"
                        isLoading={updatePasswordLoading}
                        disabled={isTokenSent}
                    >
                        Reset Password
                    </Button>
                    {isTokenSent && (
                        <div className="text-xl text-center text-green-700">
                            Token has been sent, please check your email
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
