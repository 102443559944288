import { FC, ReactNode } from 'react';
import React, { Fragment } from 'react';
import { Tab as TabUI } from '@headlessui/react';
import cx from 'classnames';

interface TabProps {
    key: string | number;
    onClick?: () => void;
    label?: string;
    children?: ReactNode | null;
}

const Tab: FC<TabProps> = ({ key, label, children, onClick, ...otherProps }) => (
    <TabUI key={key} as={Fragment} {...otherProps}>
        {({ selected }) => (
            <button
                onClick={onClick}
                className={cx('rounded-none text-white font-segoe font-bold p-3 md:p-5', {
                    'bg-black': !selected,
                    'bg-secondary text-black': selected,
                    'hover:opacity-100': selected,
                })}
            >
                {label || children}
            </button>
        )}
    </TabUI>
);

Tab.defaultProps = {
    label: '',
    children: '',
    onClick: () => {
        //
    },
};

export default Tab;
