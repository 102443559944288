import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'components/common';

const HomeHero = () => (
    <div className="h-screen w-full pt-header bg-contain bg-[left_4rem] bg-no-repeat bg-[url('assets/images/home-bg.png')] lg:bg-right-top">
        <div className="w-full h-full p-5 sm:p-12 bg-[url('assets/images/homeLeftDecor@2x.png')] bg-no-repeat bg-contain bg-left-bottom">
            <div className="container h-full mx-auto">
                <div className="flex justify-center flex-col h-full w-full align-left items-start pb-0 sm:w-[620px] lg:pb-32 lg:justify-end">
                    <div className="text-black font-light font-frankRuhlLibre text-xl w-7/12 mb-5 lg:text-4xl lg:w-full lg:mb-10">
                        Turning real assets into <br className="hidden lg:block" /> digital assets
                    </div>
                    <div className="text-desc text-xs font-segoe lg:text-lg">
                        R2D is a revolutionary platform that allows investors to seamlessly invest into lucrative mining
                        operations and real estate properties through the use of blockchain technology.
                    </div>
                    <div className="flex justify-center sm:justify-start w-full mt-8 lg:mt-10">
                        <Link to="/signup">
                            <Button className="uppercase w-64 xl:w-64">INVEST NOW</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default HomeHero;
