import { useCallback } from 'react';

import Arrow from 'assets/svg/arrow.svg';
import DoubleArrow from 'assets/svg/double-arrow.svg';

interface PaginationProps {
    page: number;
    maxPage: number;
    onPageChange: (page: number) => void;
}

const Pagination = ({ page, maxPage, onPageChange }: PaginationProps) => {
    const goBack = useCallback(() => {
        if (page > 1) {
            onPageChange(page - 1);
        }
    }, [page, onPageChange]);

    const goNext = useCallback(() => {
        if (page < maxPage) {
            onPageChange(page + 1);
        }
    }, [page, maxPage, onPageChange]);

    const goFirst = useCallback(() => {
        if (page !== 1) {
            onPageChange(1);
        }
    }, [page, onPageChange]);

    const goLast = useCallback(() => {
        if (page < maxPage) {
            onPageChange(maxPage);
        }
    }, [page, maxPage, onPageChange]);

    return (
        <div className="w-full flex justify-center items-center my-5 lg:my-10">
            <span
                onClick={goFirst}
                className="w-14 lg:w-24 h-7 lg:h-12 bg-link mx-1 sm:mx-2 flex justify-center items-center cursor-pointer"
            >
                <img src={DoubleArrow} className="w-4 lg:w-8 rotate-180" />
            </span>
            <span
                onClick={goBack}
                className="w-14 lg:w-24 h-7 lg:h-12 bg-primary mx-1 sm:mx-2 flex justify-center items-center cursor-pointer"
            >
                <img src={Arrow} className="w-2 lg:w-4 rotate-180" />
            </span>
            <span className="text-sm sm:text-base lg:text-lg font-bold font-segoe text-gray px-0 sm:px-4 lg:px-14">
                PG {page} OF {maxPage}
            </span>
            <span
                onClick={goNext}
                className="w-14 lg:w-24 h-7 lg:h-12 bg-primary mx-1 sm:mx-2 flex justify-center items-center cursor-pointer"
            >
                <img src={Arrow} className="w-2 lg:w-4" />
            </span>
            <span
                onClick={goLast}
                className="w-14 lg:w-24 h-7 lg:h-12 bg-link mx-1 sm:mx-2 flex justify-center items-center cursor-pointer"
            >
                <img src={DoubleArrow} className="w-4 lg:w-8" />
            </span>
        </div>
    );
};

export default Pagination;
