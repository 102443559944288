import type { Portfolio } from 'types/Portfolio';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setPortfolioData, setPortfolioLoading } from './actions';

export interface PortfolioState {
  portfolio: Portfolio | null;
  portfolioLoading: boolean;
}

const initialState: PortfolioState = {
  portfolio: null,
  portfolioLoading: false,
};

const plaidReducer = createReducer(initialState, {
  [setPortfolioData.type]: (state: PortfolioState, action: PayloadAction<Portfolio>) => ({
    ...state,
    portfolio: action.payload,
  }),
  [setPortfolioLoading.type]: (state: PortfolioState, action: PayloadAction<boolean>) => ({
    ...state,
    portfolioLoading: action.payload,
  }),
});

export default plaidReducer;
