import { Button } from 'components/common';
import { useNavigate } from 'react-router-dom';

const NotSubmitted = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="mb-8 text-desc">
                In order to proceed with an investment, you must submit additional information.
            </div>
            <Button onClick={() => navigate('/kyc')} className="mx-auto">
                COMPLETE KYC FORMS
            </Button>
        </>
    );
};

export default NotSubmitted;
