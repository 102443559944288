import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { ReactComponent as Hexagon } from 'assets/svg/hexagon2.svg';
import { ReactComponent as HexagonFill } from 'assets/svg/hexagon-fill2.svg';

interface CheckboxProps {
    label?: string;
    selected?: boolean;
    defaultSelected?: boolean;
    onSelect?: (selected: boolean) => void;
}

const Checkbox = ({ label, selected, defaultSelected, onSelect }: CheckboxProps) => {
    const [selectedLocal, setSelectedLocal] = useState<boolean>(!!(defaultSelected || selected));

    const handleSelect = useCallback(() => {
        if (onSelect) {
            onSelect(!selected);
        } else {
            setSelectedLocal(!selectedLocal);
        }
    }, [onSelect, selectedLocal]);

    useEffect(() => {
        setSelectedLocal(!!selected);
    }, [selected]);

    return (
        <div className="flex justify-start items-start cursor-pointer" onClick={handleSelect}>
            <div className="relative mt-0.5">
                <Hexagon width="20px" height="20px" />
                <HexagonFill
                    className={cx(
                        'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 fill-secondary',
                        'text-secondary',
                        'transition duration-100 ease-in',
                        {
                            'opacity-0 scale-50': onSelect ? !selected : !selectedLocal,
                            'opacity-1 scale-100': onSelect ? selected : selectedLocal,
                        }
                    )}
                    width="10px"
                    height="10px"
                />
            </div>
            {label && <div className="ml-3 text-black text-sm md:text-base font-bold font-segoe">{label}</div>}
        </div>
    );
};

export default Checkbox;
