import { RootState } from 'store';

export const selectAccountLoading = (state: RootState) => state.account.loading;
export const selectUpdateMetadataLoading = (state: RootState) => state.account.updateMetadataLoading;
export const selectUpdateNotificationsLoading = (state: RootState) => state.account.updateNotificationsLoading;
export const selectUpdatePasswordLoading = (state: RootState) => state.account.updatePasswordLoading;
export const selectAccountMetadata = (state: RootState) => state.account.metadata;
export const selectAccountProfiles = (state: RootState) => state.account.profiles;
export const selectAccountNotifications = (state: RootState) => state.account.notifications;
export const selectPasswordUpdateError = (state: RootState) => state.account.passwordUpdateError;
export const selectIsTokenSent = (state: RootState) => state.account.isTokenSent;
export const selectIsPasswordResetSend = (state: RootState) => state.account.isPasswordResetSend;
