import type { AxiosResponse } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SudraniaResponse } from 'types/Sudrania';
import type { Portfolio } from 'types/Portfolio';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as api from 'api';
import * as portfolioActions from './actions';

function* getPortfolio(action: PayloadAction<string>) {
  try {
    yield put(portfolioActions.setPortfolioLoading(true));
    const res: AxiosResponse<SudraniaResponse<Portfolio>> = yield call(api.getPortfolio, action.payload);
    yield put(portfolioActions.setPortfolioData(res.data.data));
    yield put(portfolioActions.setPortfolioLoading(false));
  } catch (error) {
    yield put(portfolioActions.setPortfolioLoading(false));
  }
}

function* portfolioSaga() {
  yield all([
    takeEvery(portfolioActions.getPortfolio.type, getPortfolio),
  ]);
}

export default portfolioSaga;
