import { useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import PortfolioHero from './Hero';
import Card from './Card';
import { LineChart, PieChart, Spinner } from 'components/common';
import { ReactComponent as BucketIcon } from 'assets/svg/bucket.svg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getPortfolio } from 'store/portfolio/actions';
import { selectPortfolio, selectPortfolioLoading } from 'store/portfolio/selectors';
import PageLayout from 'components/common/PageLayout';
import generateNumbersRange from 'utils/helpers/generateNumbersRange';
import { selectAccountMetadata } from 'store/account/selectors';

const Portfolio = () => {
    const dispatch = useAppDispatch();

    const portfolio = useAppSelector(selectPortfolio);
    const portfolioLoading = useAppSelector(selectPortfolioLoading);
    const metadata = useAppSelector(selectAccountMetadata);

    const incomeArr = useMemo(() => {
        return portfolio?.investmentDataList.map((item) => parseInt(item.total_invested)).sort();
    }, []);

    useEffect(() => {
        if (metadata?.sudrania_id) {
            dispatch(getPortfolio(metadata?.sudrania_id));
        }
    }, [metadata]);

    return (
        <PageLayout>
            <div className="w-full h-full min-h-[80vh] pt-24 bg-[url('assets/images/slashSplash_duo.png')] bg-no-repeat bg-[right_0_bottom_-700px] bg-cover px-3">
                <div className="container mx-auto mb-6 h-full md:px-28 lg:px-0">
                    <PortfolioHero />
                    {portfolioLoading && (
                        <div className="min-h-screen">
                            <div className="flex justify-center items-center mt-32">
                                <Spinner className="w-20 h-20" />
                            </div>
                        </div>
                    )}
                    {!portfolioLoading && !portfolio && (
                        <div className="text-center text-2xl mt-20">Portfolio not found</div>
                    )}
                    {!portfolioLoading && portfolio && (
                        <>
                            <div className="grid grid-cols-1 lg:grid-cols-2 mb-5">
                                <LineChart
                                    title="Net Income"
                                    labels={['STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT']}
                                    series={[
                                        {
                                            name: 'Line 1',
                                            data: portfolio.investmentDataList.map((item) =>
                                                parseInt(item.total_invested)
                                            ),
                                        },
                                    ]}
                                    ticks={
                                        incomeArr
                                            ? generateNumbersRange(0, incomeArr[incomeArr?.length - 1], 10)
                                            : undefined
                                    }
                                    width="100%"
                                    height="250px"
                                />
                                <div className="grid grid-cols-1 md:grid-cols-[1fr_310px] px-5 sm:px-6">
                                    <PieChart
                                        title="Net Equity"
                                        series={portfolio.investmentDataList.map((item) => ({
                                            name: item.fund_name,
                                            value: parseInt(item.withdrawal),
                                        }))}
                                        width="230px"
                                        height="230px"
                                    />
                                    <div className="h-full flex flex-col font-frankRuhlLibre">
                                        <div>
                                            <div className="bg-primary text-black font-frankRuhlLibre font-medium px-5 py-5 flex justify-between items-center">
                                                <div className="text-sm mt-1">Total Income Paid</div>
                                                <div className="text-base leading-none font-bold font-segoe">
                                                    ${portfolio.total_withdrawals_formatted}
                                                </div>
                                            </div>
                                            <div className="bg-black text-white font-frankRuhlLibre font-medium px-5 py-4 flex justify-between items-center">
                                                <div className="text-sm mt-1">Net Income per Month</div>
                                                <div className="text-base leading-none font-semibold font-segoe">
                                                    $2,000
                                                </div>
                                            </div>
                                            <div className="bg-light-gray px-5 py-2 flex flex-col">
                                                <div className="text-black text-sm leading-10 font-semibold py-1 flex justify-between items-center">
                                                    <div>Amt Invested</div>
                                                    <div className="font-segoe font-bold">
                                                        ${portfolio.total_invested_formatted}
                                                    </div>
                                                </div>
                                                <div className="text-black text-sm leading-10 font-semibold py-1 flex justify-between items-center">
                                                    <div>Current Market Value</div>
                                                    <div className="font-segoe font-bold">$500,000</div>
                                                </div>
                                                <div className="text-black text-sm leading-10 font-semibold py-1 flex justify-between items-center">
                                                    <div>Net Equity</div>
                                                    <div className="font-segoe font-bold">
                                                        ${portfolio.total_profit_formatted}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-5 text-base font-segoe text-center text-dark-gray">
                                                Your $1,000 payment is pending
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <div
                                                className="w-full max-w-md relative cursor-pointer"
                                                data-tip="Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum."
                                            >
                                                <ReactTooltip
                                                    delayHide={500}
                                                    effect="solid"
                                                    className="!rounded-none w-80 text-sm"
                                                />
                                                <div className="hexagon absolute">
                                                    <div className="float-left border-solid border-r-[20px] md:border-r-[22px] border-r-secondary border-y-[30px] md:border-y-[35px] border-y-transparent"></div>
                                                    <div className="float-left w-[34px] md:w-[42px] h-[60px] md:h-[70px] bg-secondary flex items-center">
                                                        <BucketIcon className="h-6 md:h-7" />
                                                    </div>
                                                    <div className="float-left border-solid border-l-[20px] md:border-l-[22px] border-l-secondary border-y-[30px] md:border-y-[35px] border-y-transparent"></div>
                                                </div>
                                                <div className="flex pl-[60px] md:pl-[70px]">
                                                    <div className="border-solid border-[30px] md:border-[35px] border-gray h-[60px] md:h-[70px] border-l-transparent border-l-[20px] md:border-l-[22px]"></div>
                                                    <div className="w-full h-[60px] md:h-[70px] bg-gray text-center text-sm md:text-base font-bold font-segoe flex justify-center items-center pr-[50px]">
                                                        PAYMENT PENDING
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="px-10">
                  <BarChart
                      title="Bar Graph Demo"
                      labels={['STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT', 'STAT']}
                      series={[
                          { name: 'Bars group 1', data: [4000, 4000, 3000, 4000, 1000, 0, 7000, 2000, 6000] },
                          { name: 'Bars group 2', data: [3000, 2000, 3000, 6000, 4000, 6000, 3000, 7000, 3000] },
                          {
                              name: 'Bars group 3',
                              notStacked: true,
                              data: [1000, 0, 7400, 9000, 2200, 4200, 5000, 1000, 3000],
                          },
                      ]}
                  />
              </div> */}
                            {portfolio?.investmentDataList.map((item, index) => (
                                <Card
                                    key={index}
                                    name={item.fund_name}
                                    description={item.fund_category}
                                    investedNum={item.total_invested}
                                    marketValue="124,567"
                                    invested
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </PageLayout>
    );
};

export default Portfolio;
