import type { ButtonHTMLAttributes, ReactNode } from 'react';
import React from 'react';
import cx from 'classnames';
import Spinner from './Spinner';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string;
    children?: ReactNode | null;
    className?: string;
    small?: boolean;
    large?: boolean;
    variant?: 'primary' | 'secondary' | 'link';
    isLoading?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const Button = ({
    label,
    children,
    className,
    small,
    large,
    variant,
    isLoading,
    disabled,
    onClick,
    ...otherProps
}: ButtonProps) => (
    <button
        className={cx(
            'flex justify-center items-center',
            'text-black text-sm font-segoe font-bold',
            'transition-all ease duration-200',
            'px-5',
            {
                'h-btn-medium min-w-btn-medium': !small && !large,
                'h-btn-small min-w-btn-small': small,
                'h-btn-large min-w-btn-large': large,
                'bg-primary': variant === 'primary',
                'bg-secondary': variant === 'secondary',
                'hover:bg-secondary': variant === 'primary',
                'hover:bg-secondaryDark': variant === 'secondary',
                'bg-link': variant === 'link',
                'hover:bg-link': variant === 'link',
                'text-primary': variant === 'link',
                'hover:opacity-70': variant === 'link',
                'pointer-events-none cursor-none opacity-40': disabled || isLoading,
            },
            className,
        )}
        disabled={disabled}
        onClick={onClick}
        {...otherProps}
    >
        {isLoading && (
            <div className="animate-spin">
                <Spinner />
            </div>
        )}
        {!isLoading && (label || children)}
    </button>
);

Button.defaultProps = {
    label: '',
    className: '',
    children: null,
    small: false,
    large: false,
    variant: 'primary',
    onClick: () => {
        //
    },
};

export default Button;
