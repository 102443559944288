import type { DealGallery } from 'types/Market';

interface SummaryProps {
    summary: string;
}

const Summary = ({ summary }: SummaryProps) => (
    <div className="p-6 md:p-12 my-6 bg-[#F5F2EF]">
        <div className="mb-5 md:mb-10 text-sm xl:text-base text-desc break-words font-segoe">
            {summary || 'No summary'}
        </div>
    </div>
);

export default Summary;
