import { useCallback } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Button } from 'components/common';

// Assets
import ArcaLogoBlack from 'assets/images/orca_logo_black.png';

const HomeHero = () => {
    const scrollToSignUp = useCallback(() => {
        document.querySelector('.home-sign-up-form')?.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest',
        });
    }, []);

    return (
        <div className="h- w-full relative md:h-screen">
            <div className="absolute right-0 z-0 h-full w-full overflow-hidden lg:pl-80">
                <div className="h-[100%] w-full bg-contain bg-no-repeat bg-[url('assets/images/home-bg2.png')] lg:bg-left-top lg:bg-cover" />
            </div>
            <img src={ArcaLogoBlack} className="hidden absolute z-10 left-12 top-0 mt-0 h-52 lg:block" alt="" />
            <div className="relative z-10 w-full h-full p-5 pb-8 pt-16 sm:p-12 md:pt-0 bg-[url('assets/images/homeLeftDecor@2x.png')] bg-no-repeat bg-contain bg-left-bottom">
                <div className="container h-full mx-auto">
                    <div className="flex justify-center flex-col h-full w-full align-left items-start sm:w-[28rem] lg:justify-center lg:pt-28">
                        <img src={ArcaLogoBlack} className="relative mb-6 block h-28 lg:hidden" alt="" />
                        <div className="text-black font-light font-frankRuhlLibre text-xl mb-5 tracking-normal w-full md:w-7/12 lg:text-3xl lg:w-full lg:mb-10">
                            Compounding wealth in the cryptocurrency market
                        </div>
                        <div className="text-desc text-xs font-segoe tracking-normal lg:text-base">
                            Welcome to Orca Capital, where we&apos;re dedicated to staying ahead of the curve in the
                            fast-evolving world of cryptocurrency. As the financial markets undergo a meteoric shift, we
                            believe that blockchain and digital asset adoption will explode over the next decade. Our
                            team is committed to being at the forefront of this revolution, working tirelessly to become
                            a leading asset manager in the cryptocurrency markets. Join us on this exciting journey and
                            explore the boundless opportunities of this thrilling new asset class.
                        </div>
                        <div className="flex justify-center sm:justify-start w-full mt-8 lg:mt-10">
                            <Link to="/signup">
                                <Button className="uppercase text-sm w-64 xl:w-60" onClick={scrollToSignUp}>
                                    SIGN UP NOW
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeHero;
