import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import { store } from 'store';
import { selectAccountProfiles } from 'store/account/selectors';
import { selectSessionSecret } from 'store/auth/selectors';
import { coreActions } from 'store/core';

const UNKNOWN_ERROR_CODE = "UNKNOWN_ERROR_CODE";
const NETWORK_ERROR = "Network Error";
const BASEURL = process.env.REACT_APP_SUDRANIA_URL;

export const apiSudrania: AxiosInstance = axios.create({
    baseURL: BASEURL,
    headers: {
      'content-type': 'application/json',
    },
});

const InterceptorRequestCallback = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const state = store.getState();
  const sessionSecret = selectSessionSecret(state);
  const profiles = selectAccountProfiles(state);

  // Set Elements-SessionSecret on request, if it's available in state
  // and if header is not set yet
  if (
      config?.headers &&
      !config.headers['Elements-SessionSecret'] &&
      sessionSecret
    ) {
    config.headers['Elements-SessionSecret'] = `${sessionSecret} p${profiles?.[0]?.id}`;
  }

  // Do something on request
  return config;
};

const InterceptorRequestErrorCallback = (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    store.dispatch(coreActions.setError(error.response.data));
  }
  else {
    store.dispatch(coreActions.setError({
      code: UNKNOWN_ERROR_CODE,
      message: NETWORK_ERROR,
    }));
  }
  // Do something on request error
  return Promise.reject(error);
};

const InterceptorResponseCallback = (response: AxiosResponse): AxiosResponse => {
  // Do something on response
  return response;
};

const InterceptorResponseErrorCallback = (error: AxiosError): Promise<AxiosError> => {
  store.dispatch(coreActions.setError({
    code: UNKNOWN_ERROR_CODE,
    message: NETWORK_ERROR,
  }));

  // Do something on response error
  return Promise.reject(error);
};

apiSudrania.interceptors.request.use(InterceptorRequestCallback, InterceptorRequestErrorCallback);
apiSudrania.interceptors.response.use(InterceptorResponseCallback, InterceptorResponseErrorCallback);
