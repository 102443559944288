import type { InvestorClassification, InvestorType, QqIiiAEnt, QqIiiAInv, QqIiiAIra, QqIiiATrust, TrustTypeOfOwnership, TypeOfOwnershipEnt, TypeOfOwnershipInv, TypeOfOwnershipIra } from 'types/Kyc';

export const regexps = {
  name: /^[a-zA-Z\s]*$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const ERRORS = {
  DEFAULT: 'Something went wrong',
};

export const INVESTOR_TYPES: { value: InvestorType, label: string }[] = [
  { value: 'IND', label: 'Individual' },
  { value: 'IRA', label: 'IRA Account' },
  { value: 'ENT', label: 'Entity' },
  { value: 'TRUST', label: 'Trust' }
];

export const INVESTOR_CLASSIFICATIONS: { value: InvestorClassification, label: string }[] = [
  { value: 'A', label: 'Individual that is a United States person (including such individual’s trust)' },
  { value: 'B', label: 'Individual that is not a United States person (including such individual’s trust)' },
  { value: 'C', label: 'Broker-dealer' },
  { value: 'D', label: 'Insurance company' },
  { value: 'E', label: 'Investment company registered with the SEC' },
  { value: 'F', label: 'Private fund (investment company exempt from registration in either 3(c)(1) or 3(c)(7) of the Investment Company Act of 1940)' },
  { value: 'G', label: 'Non-profit' },
  { value: 'H', label: 'Pension plan (excluding governmental pension plans)' },
  { value: 'I', label: 'Banking or thrift institution (investing its proprietary assets)' },
  { value: 'J', label: 'State or municipal government entity (excluding governmental pension plans)' },
  { value: 'K', label: 'State or municipal governmental pension plan' },
  { value: 'L', label: 'Sovereign wealth fund or foreign official institution' },
  { value: 'M', label: 'Other (please specify)' },
];

export const TYPE_OF_OWNERSHIP_INV: { value: TypeOfOwnershipInv, label: string }[] = [
  { value: 'A', label: 'Individual' },
  { value: 'B', label: 'Joint Account (tenants in common)' },
  { value: 'C', label: 'Joint Account (tenants with rights of survivorship)' },
  { value: 'E', label: 'Joint Account (tenants by the entirety))' },
  { value: 'D', label: 'Other (please specify)' },
];

export const TYPE_OF_OWNERSHIP_ENT: { value: TypeOfOwnershipEnt, label: string }[] = [
  { value: 'A', label: 'Keogh Plan or Self-Directed Pension Plan' },
  { value: 'B', label: 'Corporation' },
  { value: 'C', label: 'Charitable Institution' },
  { value: 'D', label: 'Government Plan' },
  { value: 'E', label: 'Limited Partnership' },
  { value: 'F', label: 'Limited Liability Company' },
  { value: 'G', label: 'Employee Benefit Plan (as defined in ERISA)' },
  { value: 'H', label: 'Private Foundation (not a charitable foundation)' },
  { value: 'I', label: 'Not-for-Profit/Charitable Foundation' },
  { value: 'K', label: 'Banks' },
  { value: 'L', label: 'Insurance Company' },
  { value: 'M', label: 'Endowment' },
  { value: 'O', label: 'Other (please specify)' },
];

export const TYPE_OF_OWNERSHIP_IRA: { value: TypeOfOwnershipIra, label: string }[] = [
  { value: 'A', label: 'Individual/Traditional IRA' },
  { value: 'B', label: 'Roth IRA' },
  { value: 'C', label: 'SEP IRA' },
  { value: 'F', label: 'Other (please specify)' },
];

export const TRUST_TYPE_OF_OWNERSHIP: { value: TrustTypeOfOwnership, label: string }[] = [
  { value: 'A', label: 'Irrevocable Trust' },
  { value: 'B', label: 'Revocable Trust' },
];

export const QQ_III_A_INV: { value: QqIiiAInv, label: string }[] = [
  { value: 'A', label: 'A natural person whose individual net worth, or joint net worth with that person’s spouse (or spousal equivalent), at the time purchase exceeds $1,000,000. (For purposes of calculating net worth individuals should exclude the value of the primary residence and the related amount of indebtedness secured by the primary residence up to its fair market value. Indebtedness secured by the primary residence in excess of the value of the primary residence should be considered a liability and deducted from net worth. In addition, any increase in the amount of indebtedness secured by the primary residence in the 60 days prior to making the investment must be treated as a liability.)' },
  { value: 'B', label: 'A natural person who had an individual income in excess of $200,000 in each of the two most recent years' },
  { value: 'C', label: 'A natural person who had a joint income with that person’s spouse (or spousal equivalent) in excess of $300,000 in each of those years and has a reasonable expectation of reaching the same income level in the current year.' },
  { value: 'D', label: 'None of the above apply.' },
  { value: 'G', label: 'A natural person holding in good standing one or more of the following professional certifications or designations or other credentials: (i) Licensed General Securities Representative (Series 7); (ii) Licensed Private Securities Offerings Representative (Series 82); (iii) Licensed Investment Adviser Representative (Series 65); or (iv) any other professional certification or designation or other credential from an accredited educational institution that the SEC may, from time to time, designate as qualifying an individual for accredited investor status.' },
  { value: 'H', label: 'A natural person who is deemed to be a “knowledgeable employee” of the Fund, as such term is defined in Rule 3c-5(a)(4) of the Investment Company Act.' },
  { value: 'I', label: 'A director, executive officer, or general partner of the issuer of the securities being offered or sold, or any director, executive officer, or general partner of a general partner of that issuer.' },
]

export const QQ_III_A_IRA: { value: QqIiiAIra, label: string }[] = [
  { value: 'C', label: 'A natural person whose individual net worth, or joint net worth with that person’s spouse (or spousal equivalent), at the time purchase exceeds $1,000,000. (For purposes of calculating net worth individuals should exclude the value of the primary residence and the related amount of indebtedness secured by the primary residence up to its fair market value. Indebtedness secured by the primary residence in excess of the value of the primary residence should be considered a liability and deducted from net worth. In addition, any increase in the amount of indebtedness secured by the primary residence in the 60 days prior to making the investment must be treated as a liability.)' },
  { value: 'D', label: 'A natural person who had an individual income in excess of $200,000 in each of the two most recent years.' },
  { value: 'E', label: 'A natural person (not an Entity) who has joint income with his or his spouse in excess of $300,000 in each of preceding two calendar years and has a reasonable expectation of reaching the same income level in the Current Year.' },
  { value: 'F', label: 'Individual is an employee, officer or agent of, or in any way affiliate with, the Manager or the Fund.' },
  { value: 'G', label: 'Existing securityholder from Rule 506(b) offering before September 23, 2013.' },
  { value: 'H', label: 'None of the above apply.' },
  { value: 'I', label: 'Under Rule 3c-5, Knowledgeable Employees include employees of a fund who have participated in the investment activities of such fund (other than in a clerical, secretarial, or administrative capacity) for at least one year, or are currently participating in the investment activities of the fund and have at least one year of similar experience at another company' },
  { value: 'J', label: 'Owner Is Director, Executive Officer or General Partner Of The Fund Or General Partner' },
  { value: 'K', label: 'A natural person holding in good standing one or more of the following professional certifications or designations or other credentials: (i) Licensed General Securities Representative (Series 7); (ii) Licensed Private Securities Offerings Representative (Series 82); (iii) Licensed Investment Adviser Representative (Series 65); or (iv) any other professional certification or designation or other credential from an accredited educational institution that the SEC may, from time to time, designate as qualifying an individual for accredited investor status.' },
];

export const QQ_III_A_ENT: { value: QqIiiAEnt, label: string }[] = [
  { value: 'A', label: 'An organization described in section 501(c)(3) of the Code, corporation, Massachusetts or similar business trust, partnership, or limited liability company, not formed for the specific purpose of acquiring the securities offered, with total assets in excess of $5,000,000.' },
  { value: 'D', label: 'An employee benefit plan within the meaning of ERISA if the investment decision is made by a plan fiduciary, as defined in section 3(21) of such act, which is either a bank, savings and loan association, insurance company, or registered investment adviser, or if the employee benefit plan has total assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons that are accredited investors.' },
  { value: 'E', label: 'Other ERISA plan in excess of $5 million' },
  { value: 'F', label: 'A plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its political subdivisions, for the benefit of its employees, if such plan has total assets in excess of $5,000,000.' },
  { value: 'H', label: 'An entity in which all of the equity owners are accredited investors.' },
  { value: 'K', label: 'None of the above apply.' },
  { value: 'L', label: 'A bank as defined in section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in section 3(a)(5)(A) of the Securities Act whether acting in its individual or fiduciary capacity.' },
  { value: 'M', label: 'A broker or dealer registered pursuant to section 15 of the Securities Exchange Act.' },
  { value: 'N', label: 'An insurance company as defined in section 2(a)(13) of the Securities Act.' },
  { value: 'O', label: 'An investment company registered under the Investment Company Act or a business development company as defined in section 2(a)(48) of that act.' },
  { value: 'P', label: 'Business Development Company.' },
  { value: 'Q', label: 'A private business development company as defined in section 202(a)(22) of the Investment Advisers Act.' },
  { value: 'R', label: 'A Small Business Investment Company licensed by the U.S. Small Business Administration under section 301(c) or (d) of the Small Business Investment Act of 1958.' },
  { value: 'S', label: 'An entity, of a type not listed in paragraphs (i) to (xiv), not formed for the specific purpose of acquiring the securities offered, owning “investments” (as defined below) in excess of $5,000,000.' },
  { value: 'T', label: 'A “family office”, as defined in Rule 202(a)(11)(G)-1 under the Investment Advisers Act: (a) with assets under management in excess of $5,000,000, (b) that is not formed for the specific purpose of acquiring the securities offered, and (c) whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment.' },
  { value: 'U', label: 'Director, executive officer or general partner of the fund or general partner.' },
  { value: 'V', label: 'An investment adviser registered pursuant to section 203 of the Investment Advisers Act or registered pursuant to the laws of a state.' },
  { value: 'W', label: 'An investment adviser relying on the exemption from registering with the SEC under section 203(l) or (m) of the Investment Advisers Act.' },
  { value: 'X', label: 'Investment company with assets more than $5 million managed by bank, insurance company or RIA.' },
  { value: 'Y', label: 'A Rural Business Investment Company as defined in section 384A of the Consolidated Farm and Rural Development Act.' },
  { value: 'Z', label: 'A “family client”, as defined in Rule 202(a)(11)(G)-1 under the Investment Advisers Act, of a family office meeting the requirements in paragraph (xvi) above and whose prospective investment in the issuer is directed by such family office pursuant to paragraph (xvi)(c) above.' },
  { value: 'AA', label: 'The Investor hereby certifies that it is an accredited investor because it has total assets, in excess of $5,000,000 and was not formed or “recapitalized” for specific purpose of acquiring the securities offered. “Recapitalized” for the purposes of this section shall include new investments made in the Investor solely for the purpose of its acquisition of the Interest and not made pursuant to a prior financial commitment.' },
];

export const QQ_III_A_TRUST: { value: QqIiiATrust, label: string }[] = [
  { value: 'C', label: 'None of the above apply' },
  { value: 'D', label: 'A trust with total assets in excess of $5,000,000, Not formed for the specific purpose of acquiring the Securities, whose purchase is directed by a “sophisticated” person.' },
  { value: 'E', label: 'An organization described in Section 501(c)(3) of the Internal Revenue Code, corporation, Massachusetts or similar business trust, or partnership, Not formed for the specific purpose of acquiring the Securities, with total assets in excess of $5,000,000.' },
  { value: 'F', label: 'An organization in which all of the equity owners are Accredited Investors.' },
  { value: 'G', label: 'subscribing for the purchase of the securities being offered on behalf of a trust account or accounts.' },
];

export const ALLOWED_KYC_DOCUMENT_FORMATS_REGEX = /([a-zA-Z0-9\s_\\.\-():])+(.jpeg|.jpg|.png|.pdf|.gif|.doc|.docx)$/;

export const GOVERNMENT_ID_FILE_TYPE = '001';
export const PASSPORT_FILE_TYPE = '012';
export const DRIVER_LICENSE_FILE_TYPE = '013';
export const OTHER_FILE_TYPE = 'OTH';
