import { DropZone, Select } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { setKycStep, uploadInvestorDocument } from 'store/kyc/actions';
import { selectKycInvestorDetails, selectKycStep } from 'store/kyc/selectors';
import {
    ALLOWED_KYC_DOCUMENT_FORMATS_REGEX,
    DRIVER_LICENSE_FILE_TYPE,
    GOVERNMENT_ID_FILE_TYPE,
    OTHER_FILE_TYPE,
    PASSPORT_FILE_TYPE,
} from 'utils';
import StepsPagination from '../StepsPagination';

const documentTypes = [
    { value: GOVERNMENT_ID_FILE_TYPE, label: 'Government ID' },
    { value: PASSPORT_FILE_TYPE, label: 'Passport' },
    { value: DRIVER_LICENSE_FILE_TYPE, label: 'Driver License' },
    { value: OTHER_FILE_TYPE, label: 'Other document' },
];

const Step5 = () => {
    const dispatch = useAppDispatch();

    const investorDetails = useAppSelector(selectKycInvestorDetails);
    const step = useAppSelector(selectKycStep);

    const [selectedDocumentType, setSelectedDocumentType] = useState<string>();
    const [file, setFile] = useState<File>();

    const error = useMemo(() => {
        if (!file) return '';
        if (!ALLOWED_KYC_DOCUMENT_FORMATS_REGEX.test(file?.name || '')) {
            return 'Invalid file format. Allowed formats: jpeg, jpg, png, pdf, gif, doc, docx';
        }
        return '';
    }, [file]);

    const previewUrl = useMemo(() => {
        if (file && file.type.includes('image')) {
            return URL.createObjectURL(file);
        }
        return '';
    }, [file]);

    const fileName = useMemo(() => {
        if (file) {
            return file.name;
        }
        return '';
    }, [file]);

    const submit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (selectedDocumentType && file) {
                dispatch(
                    uploadInvestorDocument({
                        investor_id: investorDetails?.investor_sr_no || '',
                        filetype: selectedDocumentType,
                        upload_type: 'investor',
                        file,
                    })
                );
            }
        },
        [file, selectedDocumentType]
    );

    const goBack = useCallback(() => {
        dispatch(setKycStep(step - 1));
    }, [step]);

    return (
        <form onSubmit={submit}>
            <div className="p-5 md:p-0">
                <DropZone
                    previewUrl={previewUrl}
                    fileName={fileName}
                    options={{
                        onDrop: (files: File[]) => setFile(files[0]),
                    }}
                />
            </div>
            {error && <div className="text-red-400 text-lg text-center mt-7">{error}</div>}
            <div className="mt-10 w-72 mx-auto">
                <Select
                    label="Document Type"
                    options={documentTypes}
                    dropdownClassName="w-full h-auto"
                    selectedDefault={selectedDocumentType}
                    onSelect={(item) => setSelectedDocumentType(item.value)}
                />
            </div>
            <StepsPagination goBack={goBack} disabled={!!error || !selectedDocumentType} />
        </form>
    );
};

export default Step5;
