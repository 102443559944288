import type { AccreditedInvestorRepresentation } from 'types/Kyc';
import * as Yup from 'yup';

const accreditedInvestorRepresentationSchema: Yup.SchemaOf<AccreditedInvestorRepresentation> = Yup.object().shape(
  {
    // qq_iii_a_inv: Yup.mixed().oneOf(['A', 'B', 'C', 'D', 'G', 'H', 'I']),
    qq_iii_a_inv: Yup.array(),
    qq_iii_a_ira: Yup.array(),
    qq_iii_a_ent: Yup.array(),
    qq_iii_a_trust: Yup.array(),
  },
);

export default accreditedInvestorRepresentationSchema;
