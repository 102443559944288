import { useEffect } from 'react';
import { useLocation, type Location } from 'react-router';

const useOnLocationChange = (handleLocationChange: (location: Location) => void) => {
  const location = useLocation();

  useEffect(() => handleLocationChange(location), [location, handleLocationChange]);
};

export default useOnLocationChange;
